import React, { useCallback } from 'react';
import { IssueCommonErrorBoundary } from '@atlassian/jira-issue-commmon-error-boundary/src/ui/index.tsx';
import type { AnalyticsAttributes } from '@atlassian/jira-product-analytics-bridge/src/types.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { JiraIssueCreateErrorBoundaryProps } from './types.tsx';

export const JiraIssueCreateErrorBoundary = (props: JiraIssueCreateErrorBoundaryProps) => {
	const { children, attributes, ...otherProps } = props;
	const getAttributes = useCallback(
		(error: Error): AnalyticsAttributes => {
			// TODO: add additional attributes in that we need for issue create.
			const attributesPassed = typeof attributes === 'function' ? attributes(error) : attributes;
			return {
				...attributesPassed,
			};
		},
		[attributes],
	);
	if (fg('jira-issue-create-error-boundary-enabled')) {
		return (
			<IssueCommonErrorBoundary {...otherProps} attributes={getAttributes}>
				{children}
			</IssueCommonErrorBoundary>
		);
	}
	return <JSErrorBoundary {...otherProps}>{children}</JSErrorBoundary>;
};

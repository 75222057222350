import React, { useEffect } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { RecaptchaTermsAndPolicy, useInvisibleRecaptcha } from '@atlassian/google-recaptcha';
import { useEnvironment } from '@atlassian/jira-tenant-context-controller/src/components/environment/index.tsx';
import { useFlagsService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import messages from '../messages.tsx';
import {
	PUBLIC_FORM_RECAPTCHA_SITE_KEY_PROD,
	PUBLIC_FORM_RECAPTCHA_SITE_KEY_STAGING,
	PUBLIC_FORM_ANALYTIC_SOURCE,
	PUBLIC_FORM_RECAPTCHA_ACTION_NAME,
} from './constants.tsx';

export type PublicFormRecaptchaResult = {
	// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
	getToken: (() => Promise<string | void>) | null;
	loadStatus: 'fail' | 'success' | undefined;
};

type PublicFormRecaptchaProps = {
	onChange: (result: PublicFormRecaptchaResult) => void;
};

export const PublicFormRecaptcha = ({ onChange }: PublicFormRecaptchaProps) => {
	const environment = useEnvironment();
	const { showFlag } = useFlagsService();
	const { getRecaptchaToken, recaptchaLoadErrorReason, isRecaptchaLoaded } = useInvisibleRecaptcha({
		siteKey:
			environment === 'prod'
				? PUBLIC_FORM_RECAPTCHA_SITE_KEY_PROD
				: PUBLIC_FORM_RECAPTCHA_SITE_KEY_STAGING,
		displayBadge: false,
		analyticsChannel: 'jira',
		analyticsSource: PUBLIC_FORM_ANALYTIC_SOURCE,
	});

	useEffect(() => {
		// the google recaptcha package fails to load
		if (!isRecaptchaLoaded && recaptchaLoadErrorReason) {
			showFlag({
				messageId:
					'business-form.ui.form-submission.fill-in-view.public-form-recaptcha.show-flag.error',
				messageType: 'transactional',
				type: 'error',
				title: messages.recaptchaErrorFlagTitle,
				description: messages.recaptchaErrorFlagContent,
				isAutoDismiss: true,
			});

			onChange({
				loadStatus: 'fail',
				getToken: null,
			});
		}

		// the google recaptcha package loads successfully
		if (isRecaptchaLoaded && !recaptchaLoadErrorReason) {
			onChange({
				loadStatus: 'success',
				getToken: () =>
					getRecaptchaToken(PUBLIC_FORM_RECAPTCHA_ACTION_NAME).catch(() => {
						// recaptcha token fails to generate
						showFlag({
							messageId:
								'business-form.ui.form-submission.fill-in-view.public-form-recaptcha.show-flag.error.1',
							messageType: 'transactional',
							type: 'error',
							title: messages.recaptchaErrorFlagTitle,
							description: messages.recaptchaErrorFlagContent,
							isAutoDismiss: true,
						});
					}),
			});
		}
	}, [onChange, isRecaptchaLoaded, getRecaptchaToken, recaptchaLoadErrorReason, showFlag]);

	return (
		<Box
			xcss={RecaptchaFooterStyles}
			testId="business-form.ui.form-submission.fill-in-view.public-form-recaptcha.public-form-recaptcha"
		>
			<RecaptchaTermsAndPolicy />
		</Box>
	);
};

const RecaptchaFooterStyles = xcss({
	color: 'color.text.subtlest',
	font: token('font.body.small'),
});

import { fg } from '@atlaskit/platform-feature-flags';
import { Step } from 'prosemirror-transform';
// Using this avoids infinite recursion, as referencing Step.fromJSON inside Step.fromJSON
// will refer to the reassigned version, not the original version
const originalFromJSON = Step.fromJSON;
Step.fromJSON = (schema, jsonStep) => {
  const stepImplementation = originalFromJSON(schema, jsonStep);

  // See https://switcheroo.atlassian.com/ui/gates/4e7e1e8c-9126-4dba-a2a3-1c2b86f81c36/key/platform_editor_metadata_steps
  if (!fg('platform_editor_metadata_step')) {
    return stepImplementation;
  }
  return new Proxy(stepImplementation, {
    get(target, prop, receiver) {
      if (prop === 'toJSON') {
        const toJSONfunc = Reflect.get(target, prop, receiver);
        // @ts-expect-error Metadata may or may not exist at this stage, depending on step type,
        // but also additions outside of the type system that others may have done.
        const classMetadata = target.metadata;
        return new Proxy(toJSONfunc, {
          apply(target, thisArg, argArray) {
            const originalResult = Reflect.apply(target, thisArg, argArray);
            const metadata = {
              ...jsonStep.metadata,
              ...classMetadata,
              ...originalResult.metadata
            };
            return {
              ...originalResult,
              ...(Object.keys(metadata).length === 0 ? {} : {
                metadata
              })
            };
          }
        });
      }
      return Reflect.get(target, prop, receiver);
    }
  });
};
export { Step };
import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	formSignUpToJira: {
		id: 'business-form.common.empty-views.success-view.cta-button.form-sign-up-to-jira',
		defaultMessage: 'Sign up to Jira',
		description: 'A button to prompt an unauthenticated user to sign up to Jira.',
	},
	formViewRequest: {
		id: 'business-form.common.empty-views.success-view.cta-button.form-view-request',
		defaultMessage: 'View request',
		description:
			'A button that redirects the user to the issue created by the form they just filled out. This button only appears to authenticated users.',
	},
	formSubmitAnotherForm: {
		id: 'business-form.common.empty-views.success-view.cta-button.form-submit-another-form',
		defaultMessage: 'Submit another form',
		description: 'A button to prompt users to re-submit another form',
	},
});

import type { MediaContext } from '@atlassian/jira-issue-media-provider/src/common/types.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { type Action, createHook, createStore } from '@atlassian/react-sweet-state';
import type { UploadContext, Status } from '../../../services/media-upload-permissions/types.tsx';

export type StoreType = {
	mediaUploadPermissions: UploadContext | null;
	mediaUploadPermissionStatus: Status;
};

export type MediaPermissionsProps = StoreType & {
	mediaContext: MediaContext;
};

const actions = {
	updateStore:
		(newData: StoreType): Action<StoreType> =>
		({ setState }) => {
			setState(newData);
		},
};

type Actions = typeof actions;

const MediaPermissionsStore = createStore<StoreType, Actions>({
	initialState: {
		mediaUploadPermissions: null,
		mediaUploadPermissionStatus: 'NOT_STARTED',
	},
	actions,
	name: 'JWMMediaPermissions',
});

export const useMediaPermissionsStore = createHook(MediaPermissionsStore);

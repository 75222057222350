import { Plugin } from '@atlaskit/editor-prosemirror/state';
import type { ReadonlyTransaction, Transaction } from '@atlaskit/editor-prosemirror/state';
import type { EditorView } from '@atlaskit/editor-prosemirror/view';

import { handlePaste } from '../utils';
import { drawCellSelection } from '../utils/draw-cell-selection';
import type { ReportFixedTable } from '../utils/fix-tables';
import { fixTables } from '../utils/fix-tables';
import { normalizeSelection } from '../utils/normalize-selection';

import { handleKeyDown, handleMouseDown, handleTripleClick } from './input';
import { tableEditingKey } from './plugin-key';

// :: () → Plugin
//
// Creates a [plugin](http://prosemirror.net/docs/ref/#state.Plugin)
// that, when added to an editor, enables cell-selection, handles
// cell-based copy/paste, and makes sure tables stay well-formed (each
// row has the same width, and cells don't overlap).
//
// You should probably put this plugin near the end of your array of
// plugins, since it handles mouse and arrow key events in tables
// rather broadly, and other plugins, like the gap cursor or the
// column-width dragging plugin, might want to get a turn first to
// perform more specific behavior.

type PluginState = number | null;

export function tableEditing({
	allowTableNodeSelection = false,
	dragAndDropEnabled = false,
	reportFixedTable,
}: {
	allowTableNodeSelection?: boolean;
	dragAndDropEnabled?: boolean;
	reportFixedTable?: ReportFixedTable;
} = {}): Plugin<PluginState> {
	return new Plugin({
		key: tableEditingKey,

		// This piece of state is used to remember when a mouse-drag
		// cell-selection is happening, so that it can continue even as
		// transactions (which might move its anchor cell) come in.
		state: {
			init() {
				return null;
			},
			apply(unsafeTr: Transaction | ReadonlyTransaction, cur: PluginState) {
				const tr = unsafeTr as unknown as ReadonlyTransaction;
				const set = tr.getMeta(tableEditingKey);
				if (set != null) {
					return set === -1 ? null : set;
				}
				if (cur == null || !tr.docChanged) {
					return cur;
				}
				const { deleted, pos } = tr.mapping.mapResult(cur);
				return deleted ? null : pos;
			},
		},

		props: {
			decorations: drawCellSelection,

			handleDOMEvents: {
				mousedown: (view: EditorView, event: Event) => {
					handleMouseDown(view, event, dragAndDropEnabled);
				},
			},

			createSelectionBetween(view) {
				if (tableEditingKey.getState(view.state) != null) {
					return view.state.selection;
				}

				return null;
			},

			handleTripleClick,

			handleKeyDown,

			handlePaste,
		},

		appendTransaction(_, oldState, state) {
			return normalizeSelection(
				state,
				fixTables(state, oldState, reportFixedTable),
				allowTableNodeSelection,
			);
		},
	});
}

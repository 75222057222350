import invert from 'lodash/invert';
import type { IntlFormatV2 } from '@atlassian/jira-intl/src/v2/types.tsx';
import {
	type Color,
	type ServerColor,
	type ClassicEpicColor,
	type ColorSchema,
	type PaletteColor,
	PURPLE,
	Colors,
	ServerColors,
	ClassicEpicColors,
	AgileColors,
	type AgileColor,
	type AnyColor,
} from '@atlassian/jira-issue-epic-color-types/src/types.tsx';
import {
	BASE_COLOR_SCHEMA,
	MAP_COLORS_TO_VALUE,
	MAP_FROM_SERVER_COLOR,
	MAP_FROM_CLIENT_SERVER_COLOR,
	MAP_FROM_SERVER_CLASSIC_COLOR,
	MAP_FROM_CLIENT_CLASSIC_COLOR,
	MAP_FROM_AGILE_COLOR,
	MAP_FROM_CLIENT_AGILE_COLOR,
	DEFAULT_EPIC_COLOR,
} from './constants.tsx';
import messages from './messages.tsx';

export const asColor = (color: AnyColor | string | null | undefined): Color => {
	if (color == null) {
		return Colors.PURPLE;
	}

	if (color in Colors) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return color as Color;
	}

	if (color in MAP_FROM_SERVER_COLOR) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return MAP_FROM_SERVER_COLOR[color as ServerColor];
	}

	if (color in MAP_FROM_AGILE_COLOR) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return MAP_FROM_AGILE_COLOR[color as AgileColor];
	}

	if (color in MAP_FROM_SERVER_CLASSIC_COLOR) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return MAP_FROM_SERVER_CLASSIC_COLOR[color as ClassicEpicColor];
	}

	return Colors.PURPLE;
};

export const asServerColor = (color: AnyColor | string | null): ServerColor => {
	if (color == null) {
		return ServerColors.SERVER_PURPLE;
	}

	if (color in ServerColors) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return color as ServerColor;
	}

	if (color in MAP_FROM_CLIENT_SERVER_COLOR) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return MAP_FROM_CLIENT_SERVER_COLOR[color as Color];
	}

	if (
		color in MAP_FROM_AGILE_COLOR &&
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		MAP_FROM_AGILE_COLOR[color as AgileColor] in MAP_FROM_CLIENT_SERVER_COLOR
	) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return MAP_FROM_CLIENT_SERVER_COLOR[MAP_FROM_AGILE_COLOR[color as AgileColor]];
	}

	if (
		color in MAP_FROM_SERVER_CLASSIC_COLOR &&
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		MAP_FROM_SERVER_CLASSIC_COLOR[color as ClassicEpicColor] in MAP_FROM_CLIENT_SERVER_COLOR
	) {
		return MAP_FROM_CLIENT_SERVER_COLOR[
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			MAP_FROM_SERVER_CLASSIC_COLOR[color as ClassicEpicColor]
		];
	}

	return ServerColors.SERVER_PURPLE;
};

export const asClassicEpicColor = (color: AnyColor | string | null): ClassicEpicColor => {
	if (color === null) {
		return MAP_FROM_CLIENT_CLASSIC_COLOR[Colors.PURPLE];
	}

	if (color in ClassicEpicColors) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return color as ClassicEpicColor;
	}

	if (color in MAP_FROM_CLIENT_CLASSIC_COLOR) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return MAP_FROM_CLIENT_CLASSIC_COLOR[color as Color];
	}

	if (
		color in MAP_FROM_SERVER_COLOR &&
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		MAP_FROM_SERVER_COLOR[color as ServerColor] in MAP_FROM_CLIENT_CLASSIC_COLOR
	) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return MAP_FROM_CLIENT_CLASSIC_COLOR[MAP_FROM_SERVER_COLOR[color as ServerColor]];
	}

	if (
		color in MAP_FROM_AGILE_COLOR &&
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		MAP_FROM_AGILE_COLOR[color as AgileColor] in MAP_FROM_CLIENT_CLASSIC_COLOR
	) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return MAP_FROM_CLIENT_CLASSIC_COLOR[MAP_FROM_AGILE_COLOR[color as AgileColor]];
	}

	// i.e: ghx-label-7
	return MAP_FROM_CLIENT_CLASSIC_COLOR[Colors.PURPLE];
};

export const asAgileColor = (color: AnyColor | string | null): AgileColor => {
	if (color === null) {
		return MAP_FROM_CLIENT_AGILE_COLOR[Colors.PURPLE];
	}

	if (color in AgileColors) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return color as AgileColor;
	}

	if (color in MAP_FROM_CLIENT_AGILE_COLOR) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return MAP_FROM_CLIENT_AGILE_COLOR[color as Color];
	}

	if (
		color in MAP_FROM_SERVER_COLOR &&
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		MAP_FROM_SERVER_COLOR[color as ServerColor] in MAP_FROM_CLIENT_AGILE_COLOR
	) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return MAP_FROM_CLIENT_AGILE_COLOR[MAP_FROM_SERVER_COLOR[color as ServerColor]];
	}

	if (
		color in MAP_FROM_SERVER_CLASSIC_COLOR &&
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		MAP_FROM_SERVER_CLASSIC_COLOR[color as ClassicEpicColor] in MAP_FROM_CLIENT_AGILE_COLOR
	) {
		return MAP_FROM_CLIENT_AGILE_COLOR[
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			MAP_FROM_SERVER_CLASSIC_COLOR[color as ClassicEpicColor]
		];
	}

	// i.e: color_7
	return MAP_FROM_CLIENT_AGILE_COLOR[Colors.PURPLE];
};

export const transformColorToTheme = <ResultTheme, ColorDefinition extends Color>(
	transformation: (colorSchema: ColorSchema) => ResultTheme,
	colorSchemaExtension?: Record<ColorDefinition, ColorSchema>,
): Record<ColorDefinition | Color, ResultTheme> => {
	const newColorSchema: Record<ColorDefinition | Color, ColorSchema> = {
		...BASE_COLOR_SCHEMA,
		...colorSchemaExtension,
	};

	const extendedColors: Array<ColorDefinition | Color> = (
		colorSchemaExtension
			? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				(Object.keys(colorSchemaExtension) as (ColorDefinition | Color)[])
			: []
	) // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		.concat(Object.keys(Colors) as Color[]);

	return extendedColors.reduce<Record<ColorDefinition | Color, ResultTheme>>(
		(obj, color) =>
			Object.assign(obj, {
				[color]: transformation(newColorSchema[color]),
			}),
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		{} as Record<ColorDefinition | Color, ResultTheme>,
	);
};

export const getValueFromColor = (color: Color): string =>
	MAP_COLORS_TO_VALUE[color] || MAP_COLORS_TO_VALUE[PURPLE];

export const getColorFromValue = (value: string): Color =>
	// NOTE: lodash invert coerce the output to Dictionary<string> instead of using a polymorphic type
	// @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'Color'.
	invert(MAP_COLORS_TO_VALUE)[value] || PURPLE;

export const toFrontendColor = (colorStr?: AnyColor | string | null): Color =>
	colorStr !== '' &&
	colorStr !== undefined &&
	colorStr !== null &&
	colorStr in MAP_FROM_SERVER_COLOR
		? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			MAP_FROM_SERVER_COLOR[colorStr as ServerColor]
		: PURPLE;

export const toBackendColor = (color: Color): ServerColor => MAP_FROM_CLIENT_SERVER_COLOR[color];

export const classicToFrontendColor = (colorStr?: AnyColor | string | null): Color =>
	colorStr !== '' &&
	colorStr !== undefined &&
	colorStr !== null &&
	colorStr in MAP_FROM_SERVER_CLASSIC_COLOR
		? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			MAP_FROM_SERVER_CLASSIC_COLOR[colorStr as ClassicEpicColor]
		: PURPLE;

export const classicToBackendColor = (color: Color): ClassicEpicColor =>
	MAP_FROM_CLIENT_CLASSIC_COLOR[color];

/**
 * Return default color palette
 */
const paletteColorValues = transformColorToTheme<string, Color>(
	(colorSchema: ColorSchema) => colorSchema.primary,
);

export const getDefaultColorPalette = (
	formatMessage: IntlFormatV2['formatMessage'],
): PaletteColor[] => [
	{
		label: formatMessage(messages.purpleLabel),
		value: paletteColorValues[Colors.PURPLE],
	},
	{
		label: formatMessage(messages.blueLabel),
		value: paletteColorValues[Colors.BLUE],
	},
	{
		label: formatMessage(messages.greenLabel),
		value: paletteColorValues[Colors.GREEN],
	},
	{
		label: formatMessage(messages.tealLabel),
		value: paletteColorValues[Colors.TEAL],
	},
	{
		label: formatMessage(messages.yellowLabel),
		value: paletteColorValues[Colors.YELLOW],
	},
	{
		label: formatMessage(messages.orangeLabel),
		value: paletteColorValues[Colors.ORANGE],
	},
	{
		label: formatMessage(messages.greyLabel),
		value: paletteColorValues[Colors.GREY],
	},
	{
		label: formatMessage(messages.darkPurpleLabel),
		value: paletteColorValues[Colors.DARK_PURPLE],
	},
	{
		label: formatMessage(messages.darkBlueLabel),
		value: paletteColorValues[Colors.DARK_BLUE],
	},
	{
		label: formatMessage(messages.darkGreenLabel),
		value: paletteColorValues[Colors.DARK_GREEN],
	},
	{
		label: formatMessage(messages.darkTealLabel),
		value: paletteColorValues[Colors.DARK_TEAL],
	},
	{
		label: formatMessage(messages.darkYellowLabel),
		value: paletteColorValues[Colors.DARK_YELLOW],
	},
	{
		label: formatMessage(messages.darkOrangeLabel),
		value: paletteColorValues[Colors.DARK_ORANGE],
	},
	{
		label: formatMessage(messages.darkGreyLabel),
		value: paletteColorValues[Colors.DARK_GREY],
	},
];

export type LozengeThemes = {
	backgroundColor: string;
	color: string;
};

export const getLozengeThemes: Record<Color, LozengeThemes> = transformColorToTheme(
	(colorSchema: ColorSchema) => ({
		color: colorSchema.text,
		backgroundColor: colorSchema.textBackground,
	}),
);

// still trying again with default in case a color was passed but it was invalid.
export const getLozengeThemeByColor = (color: Color = DEFAULT_EPIC_COLOR): LozengeThemes =>
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	getLozengeThemes[color] ?? (getLozengeThemes[DEFAULT_EPIC_COLOR] as LozengeThemes);

export const getRandomColor = (): Color => {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const colorKeys = Object.keys(Colors) as Color[];
	return colorKeys[Math.floor(Math.random() * colorKeys.length)];
};

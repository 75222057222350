import gqlTagGira from 'graphql-tag';

export const FORM_CREATE_ISSUE_MUTATION = gqlTagGira`
mutation CreateJiraBusinessIssueByForm($input: JwmFormCreateIssueInput!) {
  createJiraBusinessIssueByForm(input: $input) {
    item {
      id
      issueId
    }
    success
    errors {
      message
      extensions {
        ... on FieldValidationMutationErrorExtension {
          statusCode
          errorType
          fieldId
        }
        ... on GenericMutationErrorExtension {
          statusCode
          errorType
        }
      }
    }
  }
}
`;

export const FORM_CREATE_ISSUE_PUBLIC_MUTATION = gqlTagGira`
mutation CreateJiraIssueByPublicForm($input: JiraCreateIssueByPublicFormInput!, $email: String!, $recaptchaToken: String!) {
    jira {
        createJiraIssueByPublicForm(input: $input, email: $email, recaptchaToken: $recaptchaToken) {
            issueKey
            success
            errors {
                message
                extensions {
                    ... on FieldValidationMutationErrorExtension {
                        statusCode
                        errorType
                        fieldId
                    }
                    ... on GenericMutationErrorExtension {
                        statusCode
                        errorType
                    }
                }
            }
        }
    }
}
`;

import { useMutation } from '@apollo/react-hooks';
import type { ApolloError } from 'apollo-client';
import {
	useExperienceStart,
	useExperienceSuccess,
	useExperienceFail,
} from '@atlassian/jira-business-experience-tracking/src/controllers/experience-tracker/index.tsx';
import { CORE_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { MutationError } from '@atlassian/jira-mutation-error/src/index.tsx';
import {
	fireTrackAnalytics,
	fireOperationalAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import {
	FORM_VALIDATION_ERROR,
	SUBMIT_FORM_EXPERIENCE,
	PACKAGE_NAME,
	SUBMIT_PUBLIC_FORM_EXPERIENCE,
} from '../../common/constants.tsx';
import { useProjectType } from '../../common/utils/project/index.tsx';
import type { JiraBusinessFormWithFieldData } from '../get-form/types.tsx';
import type { JiraBusinessFormWithFieldData as JiraBusinessPublicFormWithFieldData } from '../get-form-public/types.tsx';
import { useConditionalFieldLogicAnalyticsAttrs } from '../../common/utils/gates/conditional-field-logic-gate/index.tsx';
import { FormAccessLevelTypes } from '../../common/types.tsx';
import { usePublicFormsEnabledAnalyticsAttrs } from '../../common/utils/gates/public-forms-gate/index.tsx';
import type {
	CreateJiraBusinessIssueByForm,
	CreateJiraBusinessIssueByFormVariables,
} from './__generated_apollo__/CreateJiraBusinessIssueByForm';
import {
	REQUIRED_FIELD_MISSING_ERROR,
	FIELD_VALIDATION_FAILED,
	FORM_NOT_FOUND,
	BAD_REQUEST,
	PROJECT_NOT_FOUND,
	VIEW_PROJECT_PERMISSION_ERROR,
	INVALID_EMAIL,
} from './constants.tsx';
import { FORM_CREATE_ISSUE_MUTATION, FORM_CREATE_ISSUE_PUBLIC_MUTATION } from './gql.tsx';
import type { FormInputData } from './types.tsx';
import {
	transformFormData,
	transformErrorMessage,
	transformResult,
	transformPublicCreateResult,
} from './utils.tsx';
import type {
	CreateJiraIssueByPublicForm,
	CreateJiraIssueByPublicFormVariables,
} from './__generated_apollo__/CreateJiraIssueByPublicForm.ts';

const QUERY_NAME = 'CreateJiraBusinessIssueForm';
const CREATE_PUBLIC_QUERY_NAME = 'CreateJiraIssueByPublicForm';

const logMissing = (name: string) => {
	log.safeErrorWithoutCustomerData(
		PACKAGE_NAME,
		`"${name}" was unexpectedly null or undefined for form submission`,
	);
};

type CreateIssueAnalyticsAttributes = {
	accessLevel?: string;
	isOutsideUser?: boolean;
	formId?: string;
	isLoggedIn?: boolean;
};
/**
 * This is used for Form submission
 * A simple useCreateIssue service that does not require any optimistic update nor updating any cache
 */
// TODO: JFP-2824 | Suppressed to enable upgrade to ESLint v9 - please fix this if you can!
// eslint-disable-next-line complexity
export const useCreateIssue = (
	formWithFieldData?: JiraBusinessFormWithFieldData | JiraBusinessPublicFormWithFieldData,
	onComplete?: () => void,
	// TODO: Remove on cleanup of fix_forms_issue_create_labelling_issue
	projectTypeOld: string = CORE_PROJECT,
	analyticAttributes: CreateIssueAnalyticsAttributes = {},
) => {
	const isPublic = formWithFieldData?.accessLevel === FormAccessLevelTypes.PUBLIC;
	const emailRequired =
		formWithFieldData && 'emailRequired' in formWithFieldData
			? formWithFieldData.emailRequired
			: undefined;

	const startExperience = useExperienceStart(
		isPublic && fg('jira-forms-public-access')
			? SUBMIT_PUBLIC_FORM_EXPERIENCE
			: SUBMIT_FORM_EXPERIENCE,
	);
	const markExperienceSuccess = useExperienceSuccess(
		isPublic && fg('jira-forms-public-access')
			? SUBMIT_PUBLIC_FORM_EXPERIENCE
			: SUBMIT_FORM_EXPERIENCE,
	);
	const markExperienceFailed = useExperienceFail(
		isPublic && fg('jira-forms-public-access')
			? SUBMIT_PUBLIC_FORM_EXPERIENCE
			: SUBMIT_FORM_EXPERIENCE,
	);

	const projectType = fg('fix_forms_issue_create_labelling_issue')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useProjectType()
		: projectTypeOld;

	const experienceAnalyticsAttributes = {
		...useConditionalFieldLogicAnalyticsAttrs(),
		...usePublicFormsEnabledAnalyticsAttrs(),
	};

	const doFireErrorAnalytics = (error?: ApolloError | MutationError[]) => {
		if (fg('jira-forms-public-access')) {
			fireErrorAnalytics({
				meta: {
					id: isPublic ? CREATE_PUBLIC_QUERY_NAME : QUERY_NAME,
					packageName: 'jiraBusinessForm',
					teamName: 'jira-werewolves',
				},
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				error: Array.isArray(error) && error.length > 0 ? error[0] : (error as ApolloError),
				sendToPrivacyUnsafeSplunk: true,
			});
		} else {
			fireErrorAnalytics({
				meta: {
					id: QUERY_NAME,
					packageName: 'jiraBusinessForm',
					teamName: 'jira-werewolves',
				},
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				error: Array.isArray(error) && error.length > 0 ? error[0] : (error as ApolloError),
				sendToPrivacyUnsafeSplunk: true,
			});
		}
	};

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleEventAnalytics = (issueIdentifier: { issueKey?: string; issueId?: string }) => {
		fireTrackAnalytics(createAnalyticsEvent({}), 'issue created', {
			...issueIdentifier,
			...analyticAttributes,
		});
	};

	const [createIssue, { data, loading, error }] = useMutation<
		CreateJiraBusinessIssueByForm,
		CreateJiraBusinessIssueByFormVariables
	>(FORM_CREATE_ISSUE_MUTATION, {
		onError: (apolloError) => {
			doFireErrorAnalytics(apolloError);
			markExperienceFailed(
				'create issue service - mutation onError',
				apolloError,
				experienceAnalyticsAttributes,
			);
			log.safeErrorWithoutCustomerData(
				PACKAGE_NAME,
				'ApolloError: Failed to create issue using form',
			);
		},
		onCompleted: (responseData) => {
			fireOperationalAnalytics(createAnalyticsEvent({}), `${QUERY_NAME} succeeded`, {});
			if (responseData.createJiraBusinessIssueByForm?.errors) {
				const errors = responseData.createJiraBusinessIssueByForm.errors
					.map((e) => new MutationError(e))
					.filter(
						(e) =>
							e.message !== FORM_VALIDATION_ERROR.ATTACHMENT_ERROR &&
							e.extensions?.errorType !== REQUIRED_FIELD_MISSING_ERROR &&
							e.extensions?.errorType !== FIELD_VALIDATION_FAILED &&
							e.extensions?.errorType !== FORM_NOT_FOUND &&
							e.extensions?.errorType !== PROJECT_NOT_FOUND &&
							e.extensions?.errorType !== VIEW_PROJECT_PERMISSION_ERROR &&
							(e.extensions?.errorType !== BAD_REQUEST || e.extensions?.statusCode !== 400),
					);
				if (errors.length > 0) {
					doFireErrorAnalytics(errors);
					const mutationError = errors.find((e) => e.message);
					if (mutationError)
						markExperienceFailed(
							'Create issue service mutation failed',
							new MutationError(mutationError),
							{
								errorType: mutationError.extensions?.errorType || '',
								statusCode: mutationError.extensions?.statusCode || '',
								...experienceAnalyticsAttributes,
							},
						);
				}
				return;
			}
			if (responseData.createJiraBusinessIssueByForm?.item?.issueId) {
				if (fg('jira-forms-public-access')) {
					handleEventAnalytics({
						issueId: String(responseData.createJiraBusinessIssueByForm.item.issueId),
					});
				} else {
					fireTrackAnalytics(
						createAnalyticsEvent({}),
						'issue created',
						String(responseData.createJiraBusinessIssueByForm.item.issueId),
					);
				}
				onComplete?.();
			}
			markExperienceSuccess(experienceAnalyticsAttributes);
		},
	});

	const [
		createIssuePublic,
		{
			data: createIssuePublicData,
			loading: createIssuePublicLoading,
			error: createIssuePublicError,
		},
	] = useMutation<CreateJiraIssueByPublicForm, CreateJiraIssueByPublicFormVariables>(
		FORM_CREATE_ISSUE_PUBLIC_MUTATION,
		{
			onError: (apolloError) => {
				doFireErrorAnalytics(apolloError);
				markExperienceFailed(
					'create issue (public) service - mutation onError',
					apolloError,
					experienceAnalyticsAttributes,
				);
				log.safeErrorWithoutCustomerData(
					PACKAGE_NAME,
					'ApolloError: Failed to create issue using public form',
				);
			},
			onCompleted: (responseData) => {
				fireOperationalAnalytics(
					createAnalyticsEvent({}),
					`${CREATE_PUBLIC_QUERY_NAME} succeeded`,
					{},
				);

				const mutationErrors = responseData.jira?.createJiraIssueByPublicForm?.errors;
				if (mutationErrors && mutationErrors.length > 0) {
					const errors = mutationErrors
						.map((e) => new MutationError(e))
						.filter(
							(e) =>
								e.extensions?.errorType !== REQUIRED_FIELD_MISSING_ERROR &&
								e.extensions?.errorType !== FIELD_VALIDATION_FAILED &&
								e.extensions?.errorType !== FORM_NOT_FOUND &&
								e.extensions?.errorType !== PROJECT_NOT_FOUND &&
								e.extensions?.errorType !== INVALID_EMAIL &&
								(e.extensions?.errorType !== BAD_REQUEST || e.extensions?.statusCode !== 400),
						);

					if (errors.length > 0) {
						doFireErrorAnalytics(errors);
						const mutationError = errors.find((e) => e.message);
						if (mutationError)
							markExperienceFailed(
								'Create issue (public) service mutation failed',
								new MutationError(mutationError),
								{
									errorType: mutationError.extensions?.errorType || '',
									statusCode: mutationError.extensions?.statusCode || '',
									...experienceAnalyticsAttributes,
								},
							);
					}

					return;
				}

				handleEventAnalytics({
					issueKey: responseData.jira?.createJiraIssueByPublicForm?.issueKey ?? undefined,
				});

				if (responseData.jira?.createJiraIssueByPublicForm?.success) {
					onComplete?.();
				}

				markExperienceSuccess(experienceAnalyticsAttributes);
			},
		},
	);

	/**
	 * This function will create an issue with specified fields
	 * It will always resolve the promise even if there are any errors (apollo error, bad request)
	 * To know whether it's successful or not, use the `data` and `error` prop returned by the hook
	 */
	const create = async (formInputData: FormInputData) => {
		const projectId: number | undefined = formWithFieldData?.projectId;
		const issueTypeId: string | undefined = formWithFieldData?.issueType?.id;
		const formId: string | undefined = formWithFieldData?.formId;

		startExperience();

		if (typeof formInputData !== 'object') {
			logMissing('formInputData');
			return;
		}
		if (typeof formWithFieldData !== 'object') {
			logMissing('formWithFieldData');
			return;
		}
		if (typeof projectId !== 'number') {
			logMissing('projectId');
			return;
		}
		if (typeof issueTypeId !== 'string') {
			logMissing('issueTypeId');
			return;
		}
		if (typeof formId !== 'string') {
			logMissing('formId');
			return;
		}

		const fields = fg('jsw_forms_rollout')
			? transformFormData({
					formInputData,
					formWithFieldData,
					projectType,
				})
			: transformFormData({
					formInputData,
					formWithFieldData,
				});

		await createIssue({
			variables: {
				input: {
					formId,
					fields,
				},
			},
			// @ts-expect-error - TS2345 - Argument of type '{ variables: { input: { projectId: string; issueType: string; fields: JiraIssueFieldsInput; }; }; onError: () => void; }' is not assignable to parameter of type 'MutationFunctionOptions<CreateJiraBusinessIssueForm, CreateJiraBusinessIssueFormVariables>'.
			// onError is needed to make sure the Promise always resolve instead of throwing Promise rejected error
			onError: () => {
				log.safeErrorWithoutCustomerData(
					PACKAGE_NAME,
					'ApolloError: Failed to create issue using form',
				);
			},
		});
	};

	const createForPublicForm = async (formInputData: FormInputData, recaptchaToken: string) => {
		startExperience();

		if (typeof formWithFieldData !== 'object') {
			logMissing('formWithFieldData');
			return;
		}

		if (!formWithFieldData.uuid) {
			logMissing('uuid');
			return;
		}

		const formUuid = formWithFieldData.uuid;

		const fields = transformFormData({
			formInputData,
			formWithFieldData,
			projectType,
		});

		await createIssuePublic({
			variables: {
				input: {
					uuid: formUuid,
					fields,
				},
				// The email field in the CreateJiraIssueByPublicForm mutation is non-nullable, so we have to send an empty string.
				// In any case, it's not processed by the BE when emailRequired is set to false.
				email: emailRequired ? formInputData.email ?? null : '',
				recaptchaToken,
			},
			// @ts-expect-error - TS2345 - Argument of type '{ variables: { input: { projectId: string; issueType: string; fields: JiraIssueFieldsInput; }; }; onError: () => void; }' is not assignable to parameter of type 'MutationFunctionOptions<CreateJiraBusinessIssueForm, CreateJiraBusinessIssueFormVariables>'.
			// onError is needed to make sure the Promise always resolve instead of throwing Promise rejected error
			onError: () => {
				log.safeErrorWithoutCustomerData(
					PACKAGE_NAME,
					'ApolloError: Failed to create issue using form',
				);
			},
		});
	};

	if (isPublic) {
		const mutationBadRequestError = createIssuePublicData?.jira?.createJiraIssueByPublicForm?.errors
			?.map((e) => new MutationError(e))
			?.find((e: MutationError) => e.message);

		return {
			error:
				// if there are any errors from Apollo, surface that first
				// otherwise if there are errors from bad request, use that
				createIssuePublicError || mutationBadRequestError,
			errorMessage: mutationBadRequestError
				? transformErrorMessage(mutationBadRequestError, formWithFieldData)
				: null,
			data: transformPublicCreateResult(createIssuePublicData),
			loading: createIssuePublicLoading,
			create: createForPublicForm,
		};
	}

	// find error that has message
	const mutationBadRequestError = data?.createJiraBusinessIssueByForm?.errors
		?.map((e) => new MutationError(e))
		?.find((e: MutationError) => e.message);

	return {
		error:
			// if there are any errors from Apollo, surface that first
			// otherwise if there are errors from bad request, use that
			error || mutationBadRequestError,
		errorMessage: mutationBadRequestError
			? transformErrorMessage(mutationBadRequestError, formWithFieldData)
			: null,
		data: transformResult(data),
		loading,
		create,
	};
};

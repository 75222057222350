import { token } from '@atlaskit/tokens';
import messages from './messages.tsx';
import type { OptionColorMeta } from './types.tsx';

const GREY_LIGHTEST_META: OptionColorMeta = {
	name: 'GREY_LIGHTEST',
	backgroundColor: token('color.background.neutral'),
	textColor: token('color.text.accent.gray.bolder'),
	messageLabel: messages.greyLightest,
};

const GREY_LIGHTER_META: OptionColorMeta = {
	name: 'GREY_LIGHTER',
	backgroundColor: token('color.background.neutral.hovered'),
	textColor: token('color.text.accent.gray.bolder'),
	messageLabel: messages.greyLighter,
};

export const GREY_META: OptionColorMeta = {
	name: 'GREY',
	tagColor: 'grey',
	backgroundColor: token('color.background.neutral.pressed'),
	textColor: token('color.text.accent.gray.bolder'),
	messageLabel: messages.grey,
};

const GREY_DARKER_META: OptionColorMeta = {
	name: 'GREY_DARKER',
	tagColor: 'greyDarker',
	backgroundColor: token('color.background.neutral.bold'),
	textColor: token('color.text.inverse'),
	messageLabel: messages.greyDarker,
};

const GREY_DARKEST_META: OptionColorMeta = {
	name: 'GREY_DARKEST',
	backgroundColor: token('color.background.neutral.bold.pressed'),
	textColor: token('color.text.inverse'),
	messageLabel: messages.greyDarkest,
};

const PURPLE_LIGHTEST_META: OptionColorMeta = {
	name: 'PURPLE_LIGHTEST',
	backgroundColor: token('color.background.accent.purple.subtlest'),
	textColor: token('color.text.accent.purple.bolder'),
	messageLabel: messages.purpleLightest,
};

const PURPLE_LIGHTER_META: OptionColorMeta = {
	name: 'PURPLE_LIGHTER',
	backgroundColor: token('color.background.accent.purple.subtler'),
	textColor: token('color.text.accent.purple.bolder'),
	messageLabel: messages.purpleLighter,
};

const PURPLE_META: OptionColorMeta = {
	name: 'PURPLE',
	tagColor: 'purple',
	backgroundColor: token('color.background.accent.purple.subtle'),
	textColor: token('color.text.accent.purple.bolder'),
	messageLabel: messages.purple,
};

export const PURPLE_DARKER_META: OptionColorMeta = {
	name: 'PURPLE_DARKER',
	backgroundColor: token('color.text.accent.purple'),
	textColor: token('color.text.inverse'),
	messageLabel: messages.purpleDarker,
};

const PURPLE_DARKEST_META: OptionColorMeta = {
	name: 'PURPLE_DARKEST',
	backgroundColor: token('color.text.accent.purple.bolder'),
	textColor: token('color.text.inverse'),
	messageLabel: messages.purpleDarkest,
};

const BLUE_LIGHTEST_META: OptionColorMeta = {
	name: 'BLUE_LIGHTEST',
	backgroundColor: token('color.background.accent.blue.subtlest'),
	textColor: token('color.text.accent.blue.bolder'),
	messageLabel: messages.blueLightest,
};

const BLUE_LIGHTER_META: OptionColorMeta = {
	name: 'BLUE_LIGHTER',
	backgroundColor: token('color.background.accent.blue.subtler'),
	textColor: token('color.text.accent.blue.bolder'),
	messageLabel: messages.blueLighter,
};

const BLUE_META: OptionColorMeta = {
	name: 'BLUE',
	backgroundColor: token('color.background.accent.blue.subtle'),
	textColor: token('color.text.accent.blue.bolder'),
	messageLabel: messages.blue,
};

const BLUE_DARKER_META: OptionColorMeta = {
	name: 'BLUE_DARKER',
	backgroundColor: token('color.text.accent.blue'),
	textColor: token('color.text.inverse'),
	messageLabel: messages.blueDarker,
};

export const BLUE_DARKEST_META: OptionColorMeta = {
	name: 'BLUE_DARKEST',
	backgroundColor: token('color.text.accent.blue.bolder'),
	textColor: token('color.text.inverse'),
	messageLabel: messages.blueDarkest,
};

const TEAL_LIGHTEST_META: OptionColorMeta = {
	name: 'TEAL_LIGHTEST',
	backgroundColor: token('color.background.accent.teal.subtlest'),
	textColor: token('color.text.accent.teal.bolder'),
	messageLabel: messages.tealLightest,
};

const TEAL_LIGHTER_META: OptionColorMeta = {
	name: 'TEAL_LIGHTER',
	backgroundColor: token('color.background.accent.teal.subtler'),
	textColor: token('color.text.accent.teal.bolder'),
	messageLabel: messages.tealLighter,
};

const TEAL_META: OptionColorMeta = {
	name: 'TEAL',
	tagColor: 'teal',
	backgroundColor: token('color.background.accent.teal.subtle'),
	textColor: token('color.text.accent.teal.bolder'),
	messageLabel: messages.teal,
};

export const TEAL_DARKER_META: OptionColorMeta = {
	name: 'TEAL_DARKER',
	backgroundColor: token('color.text.accent.teal'),
	textColor: token('color.text.inverse'),
	messageLabel: messages.tealDarker,
};

const TEAL_DARKEST_META: OptionColorMeta = {
	name: 'TEAL_DARKEST',
	backgroundColor: token('color.text.accent.teal.bolder'),
	textColor: token('color.text.inverse'),
	messageLabel: messages.tealDarkest,
};

const GREEN_LIGHTEST_META: OptionColorMeta = {
	name: 'GREEN_LIGHTEST',
	backgroundColor: token('color.background.accent.green.subtlest'),
	textColor: token('color.text.accent.green.bolder'),
	messageLabel: messages.greenLightest,
};

const GREEN_LIGHTER_META: OptionColorMeta = {
	name: 'GREEN_LIGHTER',
	backgroundColor: token('color.background.accent.green.subtler'),
	textColor: token('color.text.accent.green.bolder'),
	messageLabel: messages.greenLighter,
};

const GREEN_META: OptionColorMeta = {
	name: 'GREEN',
	tagColor: 'green',
	backgroundColor: token('color.background.accent.green.subtle'),
	textColor: token('color.text.accent.green.bolder'),
	messageLabel: messages.green,
};

const GREEN_DARKER_META: OptionColorMeta = {
	name: 'GREEN_DARKER',
	backgroundColor: token('color.background.accent.green.bolder'),
	textColor: token('color.text.inverse'),
	messageLabel: messages.greenDarker,
};

const GREEN_DARKEST_META: OptionColorMeta = {
	name: 'GREEN_DARKEST',
	backgroundColor: token('color.text.accent.green.bolder'),
	textColor: token('color.text.inverse'),
	messageLabel: messages.greenDarkest,
};

const LIME_LIGHTEST_META: OptionColorMeta = {
	name: 'LIME_LIGHTEST',
	backgroundColor: token('color.background.accent.lime.subtlest'),
	textColor: token('color.text.accent.lime.bolder'),
	messageLabel: messages.limeLightest,
};

const LIME_LIGHTER_META: OptionColorMeta = {
	name: 'LIME_LIGHTER',
	backgroundColor: token('color.background.accent.lime.subtler'),
	textColor: token('color.text.accent.lime.bolder'),
	messageLabel: messages.limeLighter,
};

const LIME_META: OptionColorMeta = {
	name: 'LIME',
	backgroundColor: token('color.background.accent.lime.subtle'),
	textColor: token('color.text.accent.lime.bolder'),
	messageLabel: messages.lime,
};

const LIME_DARKER_META: OptionColorMeta = {
	name: 'LIME_DARKER',
	backgroundColor: token('color.text.accent.lime'),
	textColor: token('color.text.inverse'),
	messageLabel: messages.limeDarker,
};

const LIME_DARKEST_META: OptionColorMeta = {
	name: 'LIME_DARKEST',
	backgroundColor: token('color.text.accent.lime.bolder'),
	textColor: token('color.text.inverse'),
	messageLabel: messages.limeDarkest,
};

const YELLOW_LIGHTEST_META: OptionColorMeta = {
	name: 'YELLOW_LIGHTEST',
	backgroundColor: token('color.background.accent.yellow.subtlest'),
	textColor: token('color.text.accent.yellow.bolder'),
	messageLabel: messages.yellowLightest,
};

const YELLOW_LIGHTER_META: OptionColorMeta = {
	name: 'YELLOW_LIGHTER',
	backgroundColor: token('color.background.accent.yellow.subtler'),
	textColor: token('color.text.accent.yellow.bolder'),
	messageLabel: messages.yellowLighter,
};

const YELLOW_META: OptionColorMeta = {
	name: 'YELLOW',
	tagColor: 'yellow',
	backgroundColor: token('color.background.accent.yellow.subtle'),
	textColor: token('color.text.accent.yellow.bolder'),
	messageLabel: messages.yellow,
};

const YELLOW_DARKER_META: OptionColorMeta = {
	name: 'YELLOW_DARKER',
	backgroundColor: token('color.text.accent.yellow'),
	textColor: token('color.text.inverse'),
	messageLabel: messages.yellowDarker,
};

const YELLOW_DARKEST_META: OptionColorMeta = {
	name: 'YELLOW_DARKEST',
	backgroundColor: token('color.text.accent.yellow.bolder'),
	textColor: token('color.text.inverse'),
	messageLabel: messages.yellowDarkest,
};

const ORANGE_LIGHTEST_META: OptionColorMeta = {
	name: 'ORANGE_LIGHTEST',
	backgroundColor: token('color.background.accent.orange.subtlest'),
	textColor: token('color.text.accent.orange.bolder'),
	messageLabel: messages.orangeLightest,
};

const ORANGE_LIGHTER_META: OptionColorMeta = {
	name: 'ORANGE_LIGHTER',
	backgroundColor: token('color.background.accent.orange.subtler'),
	textColor: token('color.text.accent.orange.bolder'),
	messageLabel: messages.orangeLighter,
};

const ORANGE_META: OptionColorMeta = {
	name: 'ORANGE',
	tagColor: 'orange',
	backgroundColor: token('color.background.accent.orange.subtle'),
	textColor: token('color.text.accent.orange.bolder'),
	messageLabel: messages.orange,
};

const ORANGE_DARKER_META: OptionColorMeta = {
	name: 'ORANGE_DARKER',
	backgroundColor: token('color.background.accent.orange.bolder'),
	textColor: token('color.text.inverse'),
	messageLabel: messages.orangeDarker,
};

const ORANGE_DARKEST_META: OptionColorMeta = {
	name: 'ORANGE_DARKEST',
	backgroundColor: token('color.text.accent.orange.bolder'),
	textColor: token('color.text.inverse'),
	messageLabel: messages.orangeDarkest,
};

const RED_LIGHTEST_META: OptionColorMeta = {
	name: 'RED_LIGHTEST',
	backgroundColor: token('color.background.accent.red.subtlest'),
	textColor: token('color.text.accent.red.bolder'),
	messageLabel: messages.redLightest,
};

const RED_LIGHTER_META: OptionColorMeta = {
	name: 'RED_LIGHTER',
	backgroundColor: token('color.background.accent.red.subtler'),
	textColor: token('color.text.accent.red.bolder'),
	messageLabel: messages.redLighter,
};

const RED_META: OptionColorMeta = {
	name: 'RED',
	tagColor: 'red',
	backgroundColor: token('color.background.accent.red.subtle'),
	textColor: token('color.text.accent.red.bolder'),
	messageLabel: messages.red,
};

const RED_DARKER_META: OptionColorMeta = {
	name: 'RED_DARKER',
	backgroundColor: token('color.background.accent.red.bolder'),
	textColor: token('color.text.inverse'),
	messageLabel: messages.redDarker,
};

const RED_DARKEST_META: OptionColorMeta = {
	name: 'RED_DARKEST',
	backgroundColor: token('color.text.accent.red.bolder'),
	textColor: token('color.text.inverse'),
	messageLabel: messages.redDarkest,
};

const MAGENTA_LIGHTEST_META: OptionColorMeta = {
	name: 'MAGENTA_LIGHTEST',
	backgroundColor: token('color.background.accent.magenta.subtlest'),
	textColor: token('color.text.accent.magenta.bolder'),
	messageLabel: messages.magentaLightest,
};

const MAGENTA_LIGHTER_META: OptionColorMeta = {
	name: 'MAGENTA_LIGHTER',
	backgroundColor: token('color.background.accent.magenta.subtler'),
	textColor: token('color.text.accent.magenta.bolder'),
	messageLabel: messages.magentaLighter,
};

const MAGENTA_META: OptionColorMeta = {
	name: 'MAGENTA',
	tagColor: 'magenta',
	backgroundColor: token('color.background.accent.magenta.subtle.hovered'),
	textColor: token('color.text.accent.magenta.bolder'),
	messageLabel: messages.magenta,
};

const MAGENTA_DARKER_META: OptionColorMeta = {
	name: 'MAGENTA_DARKER',
	backgroundColor: token('color.text.accent.magenta'),
	textColor: token('color.text.inverse'),
	messageLabel: messages.magentaDarker,
};

const MAGENTA_DARKEST_META: OptionColorMeta = {
	name: 'MAGENTA_DARKEST',
	backgroundColor: token('color.text.accent.magenta.bolder'),
	textColor: token('color.text.inverse'),
	messageLabel: messages.magentaDarkest,
};

export const CHECK_MARK_COLOR_DARK = token('color.text.inverse');
export const CHECK_MARK_COLOR_LIGHT = token('color.text');

export const OPTION_COLOR_THEMES: OptionColorMeta[] = [
	GREY_LIGHTEST_META,
	PURPLE_LIGHTEST_META,
	BLUE_LIGHTEST_META,
	TEAL_LIGHTEST_META,
	GREEN_LIGHTEST_META,
	LIME_LIGHTEST_META,
	YELLOW_LIGHTEST_META,
	ORANGE_LIGHTEST_META,
	RED_LIGHTEST_META,
	MAGENTA_LIGHTEST_META,
	GREY_LIGHTER_META,
	PURPLE_LIGHTER_META,
	BLUE_LIGHTER_META,
	TEAL_LIGHTER_META,
	GREEN_LIGHTER_META,
	LIME_LIGHTER_META,
	YELLOW_LIGHTER_META,
	ORANGE_LIGHTER_META,
	RED_LIGHTER_META,
	MAGENTA_LIGHTER_META,
	GREY_META,
	PURPLE_META,
	BLUE_META,
	TEAL_META,
	GREEN_META,
	LIME_META,
	YELLOW_META,
	ORANGE_META,
	RED_META,
	MAGENTA_META,
	GREY_DARKER_META,
	PURPLE_DARKER_META,
	BLUE_DARKER_META,
	TEAL_DARKER_META,
	GREEN_DARKER_META,
	LIME_DARKER_META,
	YELLOW_DARKER_META,
	ORANGE_DARKER_META,
	RED_DARKER_META,
	MAGENTA_DARKER_META,
	GREY_DARKEST_META,
	PURPLE_DARKEST_META,
	BLUE_DARKEST_META,
	TEAL_DARKEST_META,
	GREEN_DARKEST_META,
	LIME_DARKEST_META,
	YELLOW_DARKEST_META,
	ORANGE_DARKEST_META,
	RED_DARKEST_META,
	MAGENTA_DARKEST_META,
];
export const OPTION_COLOR_THEMES_COLORFUL_SINGLE_SELECT: OptionColorMeta[] = [
	PURPLE_META,
	YELLOW_META,
	GREEN_META,
	TEAL_META,
	RED_META,
	ORANGE_META,
	MAGENTA_META,
	GREY_DARKER_META,
	GREY_META,
];
const OPTION_COLOR_THEMES_COLORFUL_SINGLE_SELECT_MAP: Record<string, OptionColorMeta> = {};

OPTION_COLOR_THEMES_COLORFUL_SINGLE_SELECT.forEach((option) => {
	OPTION_COLOR_THEMES_COLORFUL_SINGLE_SELECT_MAP[option.name] = option;
});
export const OPTION_COLOR_THEMES_MAP: Record<string, OptionColorMeta> = {};
OPTION_COLOR_THEMES.forEach((option) => {
	OPTION_COLOR_THEMES_MAP[option.name] = option;
});

import type { UserOption } from '@atlassian/jira-base-user-picker/src/types.tsx';
import type { ForgeCustomFieldValue } from '@atlassian/jira-forge-ui-types/src/common/types/contexts/custom-field.tsx';
import type { CustomFieldExtension } from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import {
	mapDataToLabelOptions,
	mapLabelOptionsToData,
} from '@atlassian/jira-issue-field-labels/src/common/utils.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { getExtensionIdFromLegacyFieldType, isForgeLegacyFieldType } from '../../utils/index.tsx';
import { transformJSONStringToObject } from './transform-to-object/index.tsx';

export const transformValue = (
	value: ForgeCustomFieldValue,
	extension: CustomFieldExtension,
): ForgeCustomFieldValue => {
	const {
		properties: { type, collection },
	} = extension;

	switch (type) {
		case 'string':
			if (collection === 'list' && Array.isArray(value)) {
				// @ts-expect-error - TS2322 - Type 'Option[]' is not assignable to type 'ForgeCustomFieldValue'. | TS2345 - Argument of type 'string[] | User[] | Group[]' is not assignable to parameter of type 'LabelsValue'.
				return mapDataToLabelOptions(value);
			}
			return value;
		case 'object':
			if (fg('custom_field_null_object_transform')) {
				if (value != null) {
					return JSON.stringify(value);
				}
				return value;
			}
			return JSON.stringify(value);
		case 'user':
			if (value != null) {
				// @ts-expect-error - TS2345 - Argument of type 'string | number | string[] | User | Group | { [key: string]: unknown; } | User[] | Group[]' is not assignable to parameter of type 'JiraUser | JiraUser[]'.
				return transformDefaultUserValues(value);
			}
			return value;
		default:
			return value;
	}
};

export const transformValueToExtensionData = (
	value: ForgeCustomFieldValue,
	extension?: CustomFieldExtension | null,
): ForgeCustomFieldValue => {
	const type = extension?.properties?.type;
	const collection = extension?.properties?.collection;

	switch (type) {
		case 'string':
			if (collection === 'list') {
				// @ts-expect-error - TS2345 - Argument of type 'ForgeCustomFieldValue' is not assignable to parameter of type 'Option[]'.
				return mapLabelOptionsToData(value);
			}
			return value;
		case 'object':
			return transformJSONStringToObject(value);
		case 'user':
			if (value === null) {
				return value;
			}
			// @ts-expect-error - TS2345 - Argument of type 'ForgeCustomFieldValue' is not assignable to parameter of type 'UserOption | UserOption[]'.
			return mapUserOptionsToAccountIds(value);
		default:
			return value;
	}
};

export const getExtension = (fieldType: string, modules: CustomFieldExtension[]) =>
	modules.find((field: CustomFieldExtension) =>
		isForgeLegacyFieldType(fieldType)
			? field.id === getExtensionIdFromLegacyFieldType(fieldType)
			: field.id === fieldType,
	);

export const mapUserOptionsToAccountIds = (
	userOption: UserOption | UserOption[],
):
	| {
			accountId: string;
	  }
	| {
			accountId: string;
	  }[] => {
	if (Array.isArray(userOption)) {
		return userOption.map<{
			accountId: string;
		}>((option) => ({
			accountId: option.id,
		}));
	}
	return {
		accountId: userOption.id,
	};
};

export const transformJiraUserToUserOption = (userData: JiraUser): UserOption => ({
	id: userData.accountId || '',
	name: userData.name || '',
	avatarUrl: userData.picture || '',
});

export type JiraUser = {
	picture?: string;
	name?: string;
	accountId: string | undefined | null;
};

export const transformDefaultUserValues = (
	defaultValue: JiraUser | JiraUser[],
): UserOption | UserOption[] => {
	if (Array.isArray(defaultValue)) {
		return defaultValue.map(transformJiraUserToUserOption);
	}
	return transformJiraUserToUserOption(defaultValue);
};

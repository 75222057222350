import { fg } from '@atlassian/jira-feature-gating';
import { SOFTWARE_CMP_PREFIX, SOFTWARE_TMP_PREFIX, BUSINESS_PREFIX } from '../../constants.tsx';
import { FORM_LABEL, getFormLabelPrefix, getUniqueFormLabel } from '../field-config/index.tsx';

export const getPrefix = (
	projectType: string,
	isSimplified: boolean,
): '/jira/software' | '/jira/software/c' | '/jira/core' => {
	if (projectType === 'software') {
		if (isSimplified) {
			return SOFTWARE_TMP_PREFIX;
		}
		return SOFTWARE_CMP_PREFIX;
	}
	return BUSINESS_PREFIX;
};

export const getFormUrl = (
	projectType: string,
	isSimplified: boolean,
	projectKey: string,
): string => {
	return `${getPrefix(projectType, isSimplified)}/projects/${projectKey}/form`;
};

export const getFormBuilderUrl = (
	projectType: string,
	isSimplified: boolean,
	projectKey: string,
	formId: string,
): string => {
	return `${getPrefix(projectType, isSimplified)}/projects/${projectKey}/form/${formId}/builder`;
};

export const getFormSubmitUrl = (
	projectType: string,
	isSimplified: boolean,
	projectKey: string,
	formId: string,
	isPublic?: boolean,
): string => {
	if (isPublic && fg('jira-forms-public-access')) {
		return `${getPrefix(projectType, isSimplified)}/form/${formId}`;
	}
	return `${getPrefix(projectType, isSimplified)}/projects/${projectKey}/form/${formId}`;
};

export const getUpdateFieldConfigLink = (
	projectType: string,
	isSimplified: boolean,
	isTeamManaged: boolean,
	projectKey: string,
	issueTypeId: string | undefined,
) => {
	const prefix = getPrefix(projectType, isSimplified);

	if (isTeamManaged) {
		return projectKey && issueTypeId
			? `${prefix}/projects/${projectKey}/settings/issuetypes/${issueTypeId}`
			: undefined;
	}

	return projectKey ? `/plugins/servlet/project-config/${projectKey}/fields` : undefined;
};

export const getListViewFormFilteredUrl = (
	projectType: string,
	isSimplified: boolean,
	projectKey: string,
	formId: string,
) =>
	`${getPrefix(projectType, isSimplified)}/projects/${projectKey}/list?filter=labels%3D"${getUniqueFormLabel(fg('jsw_forms_rollout') ? getFormLabelPrefix(projectType) : FORM_LABEL, formId)}"`;

import React from 'react';
import {
	useAnalyticsEvents,
	fireOperationalAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { fg } from '@atlassian/jira-feature-gating';
import { useProject_DEPRECATED_DO_NOT_USE } from '@atlassian/jira-router-resources-business-project-details/src/index.tsx';
import TextField from '@atlassian/jira-issue-create-commons/src/ui/text-field/index.tsx';
import type { JiraBusinessFormWithFieldDataField } from '../../../../services/get-form/types.tsx';
import { useFormSubmission } from '../../../../controllers/form-submission/index.tsx';
import { getFieldTypeConfig } from '../../../../common/utils/field-config/index.tsx';
import { useUploadPermissions } from '../../../../services/media-upload-permissions/index.tsx';
import { useMediaPermissions } from '../../../../controllers/media-upload-permissions/index.tsx';
import { getFormAttachmentUploadUrl } from '../../../../services/media-upload-permissions/utils.tsx';
import Condition from '../condition/index.tsx';

type Props = {
	fields: JiraBusinessFormWithFieldDataField[];
	showEmailField?: boolean;
	setUploading: (uploading: boolean) => void;
};

export const Fields = ({ fields, showEmailField, setUploading }: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { data: projectData } = useProject_DEPRECATED_DO_NOT_USE();
	const { data: formSubmissionData, formId } = useFormSubmission();
	const { formWithFieldData, fieldMetaData } = formSubmissionData ?? {};
	const { projectId, accessLevel, issueType } = formWithFieldData ?? {};
	const { uploadPermissions } = useUploadPermissions(true);
	const { mediaUploadPermissions } = useMediaPermissions();

	const renderedFields = fields.map((f) => {
		// If the field no longer exists, do not render it.
		if (!fieldMetaData || !fieldMetaData[f.fieldId]) {
			return null;
		}

		const fieldConfig = getFieldTypeConfig(f.type, projectData?.type);

		if (!fieldConfig) {
			fireOperationalAnalytics(createAnalyticsEvent({}), 'field missing', {
				type: f.type,
			});
			return null;
		}

		const {
			autoCompleteUrl,
			allowedValues = [],
			defaultFieldValue,
			schema,
		} = fieldMetaData[f.fieldId];
		/**
		 * autoCompleteUrls contains origin/host.
		 * In storybook, we want these to point to our proxy.
		 */
		let completeUrl = autoCompleteUrl;
		if (Boolean(process.env.STORYBOOK_JIRA_URL) && typeof autoCompleteUrl === 'string') {
			const url = new URL(autoCompleteUrl);
			completeUrl = `${url.pathname}${url.search}`;
		}

		const isFieldRequired = f.isRequired || !!f.isRequiredByForm;
		const fieldDescription = f.description;

		const field = (
			<fieldConfig.Component
				key={f.fieldId}
				fieldName={typeof f.alias === 'string' && f.alias.length > 0 ? f.alias : f.name}
				fieldId={f.fieldId}
				isRequired={isFieldRequired}
				description={fieldDescription}
				autoCompleteUrl={completeUrl}
				allowedValues={allowedValues}
				mediaUploadContexts={{
					uploadContext: uploadPermissions,
					attachmentUploadContext: mediaUploadPermissions,
				}}
				projectId={String(projectId)}
				issueTypeId={issueType?.id}
				projectKey={projectData?.key}
				onInProgressChange={setUploading}
				defaultValue={defaultFieldValue}
				attachmentCustomUploadURL={getFormAttachmentUploadUrl(formId)}
				canUserViewProject={Boolean(projectData?.permissions?.viewIssues)}
				schemaConfiguration={schema?.configuration}
				renderer={fg('forms_multiline_text_wiki_renderer') ? f.renderer : undefined}
				accessLevel={accessLevel}
				isFieldResponsive
			/>
		);

		return f.parentCondition && fg('jira_forms_conditional_field_logic_gate_') ? (
			<Condition key={`${f.parentCondition.id}:${f.fieldId}`} condition={f.parentCondition}>
				{field}
			</Condition>
		) : (
			field
		);
	});

	if (showEmailField)
		renderedFields.unshift(<TextField key="email" fieldId="email" fieldName="Email" isRequired />);

	return renderedFields;
};

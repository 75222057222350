/**
 * @fileoverview This file was moved from `@atlassian/jira-issue-epic-color-types`
 * so that these types do not require atlaskit dependencies.
 *
 * Color constants
 */

export const PURPLE = 'PURPLE' as const;
export const DARK_PURPLE = 'DARK_PURPLE' as const;
export const BLUE = 'BLUE' as const;
export const DARK_BLUE = 'DARK_BLUE' as const;
export const GREEN = 'GREEN' as const;
export const DARK_GREEN = 'DARK_GREEN' as const;
export const TEAL = 'TEAL' as const;
export const DARK_TEAL = 'DARK_TEAL' as const;
export const YELLOW = 'YELLOW' as const;
export const DARK_YELLOW = 'DARK_YELLOW' as const;
export const ORANGE = 'ORANGE' as const;
export const DARK_ORANGE = 'DARK_ORANGE' as const;
export const GREY = 'GREY' as const;
export const DARK_GREY = 'DARK_GREY' as const;

export const COLORS = [
	PURPLE,
	DARK_PURPLE,
	BLUE,
	DARK_BLUE,
	GREEN,
	DARK_GREEN,
	TEAL,
	DARK_TEAL,
	YELLOW,
	DARK_YELLOW,
	ORANGE,
	DARK_ORANGE,
	GREY,
	DARK_GREY,
] as const;

export const SERVER_PURPLE = 'purple' as const;
export const SERVER_DARK_PURPLE = 'dark_purple' as const;
export const SERVER_BLUE = 'blue' as const;
export const SERVER_DARK_BLUE = 'dark_blue' as const;
export const SERVER_GREEN = 'green' as const;
export const SERVER_DARK_GREEN = 'dark_green' as const;
export const SERVER_TEAL = 'teal' as const;
export const SERVER_DARK_TEAL = 'dark_teal' as const;
export const SERVER_YELLOW = 'yellow' as const;
export const SERVER_DARK_YELLOW = 'dark_yellow' as const;
export const SERVER_ORANGE = 'orange' as const;
export const SERVER_DARK_ORANGE = 'dark_orange' as const;
export const SERVER_GREY = 'grey' as const;
export const SERVER_DARK_GREY = 'dark_grey' as const;

export const GHX_LABEL_1 = 'ghx-label-1' as const;
export const GHX_LABEL_2 = 'ghx-label-2' as const;
export const GHX_LABEL_3 = 'ghx-label-3' as const;
export const GHX_LABEL_4 = 'ghx-label-4' as const;
export const GHX_LABEL_5 = 'ghx-label-5' as const;
export const GHX_LABEL_6 = 'ghx-label-6' as const;
export const GHX_LABEL_7 = 'ghx-label-7' as const;
export const GHX_LABEL_8 = 'ghx-label-8' as const;
export const GHX_LABEL_9 = 'ghx-label-9' as const;
export const GHX_LABEL_10 = 'ghx-label-10' as const;
export const GHX_LABEL_11 = 'ghx-label-11' as const;
export const GHX_LABEL_12 = 'ghx-label-12' as const;
export const GHX_LABEL_13 = 'ghx-label-13' as const;
export const GHX_LABEL_14 = 'ghx-label-14' as const;

export const AGILE_COLOR_1 = 'color_1' as const;
export const AGILE_COLOR_2 = 'color_2' as const;
export const AGILE_COLOR_3 = 'color_3' as const;
export const AGILE_COLOR_4 = 'color_4' as const;
export const AGILE_COLOR_5 = 'color_5' as const;
export const AGILE_COLOR_6 = 'color_6' as const;
export const AGILE_COLOR_7 = 'color_7' as const;
export const AGILE_COLOR_8 = 'color_8' as const;
export const AGILE_COLOR_9 = 'color_9' as const;
export const AGILE_COLOR_10 = 'color_10' as const;
export const AGILE_COLOR_11 = 'color_11' as const;
export const AGILE_COLOR_12 = 'color_12' as const;
export const AGILE_COLOR_13 = 'color_13' as const;
export const AGILE_COLOR_14 = 'color_14' as const;

/**
 * Color types
 */

// Also known as "Client Color"
export type Color = (typeof COLORS)[number];

export type ServerColor =
	| typeof SERVER_PURPLE
	| typeof SERVER_DARK_PURPLE
	| typeof SERVER_BLUE
	| typeof SERVER_DARK_BLUE
	| typeof SERVER_GREEN
	| typeof SERVER_DARK_GREEN
	| typeof SERVER_TEAL
	| typeof SERVER_DARK_TEAL
	| typeof SERVER_YELLOW
	| typeof SERVER_DARK_YELLOW
	| typeof SERVER_ORANGE
	| typeof SERVER_DARK_ORANGE
	| typeof SERVER_GREY
	| typeof SERVER_DARK_GREY;

export type ClassicEpicColor =
	| typeof GHX_LABEL_1
	| typeof GHX_LABEL_2
	| typeof GHX_LABEL_3
	| typeof GHX_LABEL_4
	| typeof GHX_LABEL_5
	| typeof GHX_LABEL_6
	| typeof GHX_LABEL_7
	| typeof GHX_LABEL_8
	| typeof GHX_LABEL_9
	| typeof GHX_LABEL_10
	| typeof GHX_LABEL_11
	| typeof GHX_LABEL_12
	| typeof GHX_LABEL_13
	| typeof GHX_LABEL_14;

export type AgileColor =
	| typeof AGILE_COLOR_1
	| typeof AGILE_COLOR_2
	| typeof AGILE_COLOR_3
	| typeof AGILE_COLOR_4
	| typeof AGILE_COLOR_5
	| typeof AGILE_COLOR_6
	| typeof AGILE_COLOR_7
	| typeof AGILE_COLOR_8
	| typeof AGILE_COLOR_9
	| typeof AGILE_COLOR_10
	| typeof AGILE_COLOR_11
	| typeof AGILE_COLOR_12
	| typeof AGILE_COLOR_13
	| typeof AGILE_COLOR_14;

// Union of all our color types
export type AnyColor = Color | ServerColor | ClassicEpicColor | AgileColor;

export type ColorSchema = {
	primary: string;
	secondary: string;
	text: string;
	textBackground: string;
	border: string;
	line: string;
	primaryGradient: string;
	hasContrastSecondaryGradient: boolean; // contrast between secondary color and gradient
};

export type PaletteColor = {
	label: string;
	value: string;
};

/**
 * Color collections
 */

export const Colors: Record<Color, Color> = {
	PURPLE,
	DARK_PURPLE,
	BLUE,
	DARK_BLUE,
	GREEN,
	DARK_GREEN,
	TEAL,
	DARK_TEAL,
	YELLOW,
	DARK_YELLOW,
	ORANGE,
	DARK_ORANGE,
	GREY,
	DARK_GREY,
} as const;

export const ServerColors: Record<string, ServerColor> = {
	SERVER_PURPLE,
	SERVER_DARK_PURPLE,
	SERVER_BLUE,
	SERVER_DARK_BLUE,
	SERVER_GREEN,
	SERVER_DARK_GREEN,
	SERVER_TEAL,
	SERVER_DARK_TEAL,
	SERVER_YELLOW,
	SERVER_DARK_YELLOW,
	SERVER_ORANGE,
	SERVER_DARK_ORANGE,
	SERVER_GREY,
	SERVER_DARK_GREY,
} as const;

export const ClassicEpicColors: Record<string, ClassicEpicColor> = {
	GHX_LABEL_1,
	GHX_LABEL_2,
	GHX_LABEL_3,
	GHX_LABEL_4,
	GHX_LABEL_5,
	GHX_LABEL_6,
	GHX_LABEL_7,
	GHX_LABEL_8,
	GHX_LABEL_9,
	GHX_LABEL_10,
	GHX_LABEL_11,
	GHX_LABEL_12,
	GHX_LABEL_13,
	GHX_LABEL_14,
} as const;

export const AgileColors: Record<string, AgileColor> = {
	AGILE_COLOR_1,
	AGILE_COLOR_2,
	AGILE_COLOR_3,
	AGILE_COLOR_4,
	AGILE_COLOR_5,
	AGILE_COLOR_6,
	AGILE_COLOR_7,
	AGILE_COLOR_8,
	AGILE_COLOR_9,
	AGILE_COLOR_10,
	AGILE_COLOR_11,
	AGILE_COLOR_12,
	AGILE_COLOR_13,
	AGILE_COLOR_14,
} as const;

import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	footer: {
		id: 'business-form.common.form-background.powered-by-jira-footer.footer',
		defaultMessage: 'Powered by <link>Jira</link>',
		description:
			'Form submission footer message with a Jira logo linked to a Jira product description page',
	},
});

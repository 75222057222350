import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	submissionErrorFlag: {
		id: 'business-form.form-submission.fill-in-view.submission-error-flag',
		defaultMessage: "Something's gone wrong",
		description: 'Error title for form submission error flag',
	},
	submissionAttachmentErrorContent: {
		id: 'business-form.form-submission.fill-in-view.submission-attachment-error-content',
		defaultMessage:
			'Attachment upload failed. Make sure the file format and size are correct, and check your internet connection before trying again.',
		description: 'Error message for form submission attachment error',
	},
	recaptchaErrorFlagTitle: {
		id: 'business-form.form-submission.fill-in-view.recaptcha-error-flag-title',
		defaultMessage: 'reCAPTCHA token attempt failed',
		description: 'Error title for reCAPTCHA attempt failure',
	},
	recaptchaErrorFlagContent: {
		id: 'business-form.form-submission.fill-in-view.recaptcha-error-flag-content',
		defaultMessage: 'Please refresh page and try again',
		description: 'Error message for reCAPTCHA verification failure',
	},
});

import React from 'react';
import { basePlugin } from '@atlaskit/editor-plugins/base';
import { blockTypePlugin } from '@atlaskit/editor-plugins/block-type';
import { ComposableEditor } from '@atlaskit/editor-core/composable-editor';
import { typeAheadPlugin } from '@atlaskit/editor-plugins/type-ahead';
import { betterTypeHistoryPlugin } from '@atlaskit/editor-plugins/better-type-history';
import { clearMarksOnEmptyDocPlugin } from '@atlaskit/editor-plugins/clear-marks-on-empty-doc';
import { clipboardPlugin } from '@atlaskit/editor-plugins/clipboard';
import { usePreset } from '@atlaskit/editor-core/use-preset';
import { analyticsPlugin } from '@atlaskit/editor-plugins/analytics';
import { compositionPlugin } from '@atlaskit/editor-plugins/composition';
import { contentInsertionPlugin } from '@atlaskit/editor-plugins/content-insertion';
import { contextIdentifierPlugin } from '@atlaskit/editor-plugins/context-identifier';
import { copyButtonPlugin } from '@atlaskit/editor-plugins/copy-button';
import { codeBlockPlugin } from '@atlaskit/editor-plugins/code-block';
import { decorationsPlugin } from '@atlaskit/editor-plugins/decorations';
import { editorDisabledPlugin } from '@atlaskit/editor-plugins/editor-disabled';
import { floatingToolbarPlugin } from '@atlaskit/editor-plugins/floating-toolbar';
import { focusPlugin } from '@atlaskit/editor-plugins/focus';
import { historyPlugin } from '@atlaskit/editor-plugins/history';
import { insertBlockPlugin } from '@atlaskit/editor-plugins/insert-block';
import { pastePlugin } from '@atlaskit/editor-plugins/paste';
import { placeholderPlugin } from '@atlaskit/editor-plugins/placeholder';
import { primaryToolbarPlugin } from '@atlaskit/editor-plugins/primary-toolbar';
import { quickInsertPlugin } from '@atlaskit/editor-plugins/quick-insert';
import { selectionPlugin } from '@atlaskit/editor-plugins/selection';
import { submitEditorPlugin } from '@atlaskit/editor-plugins/submit-editor';
import { tablesPlugin } from '@atlaskit/editor-plugins/table';
import { textFormattingPlugin } from '@atlaskit/editor-plugins/text-formatting';
import { undoRedoPlugin } from '@atlaskit/editor-plugins/undo-redo';
import { unsupportedContentPlugin } from '@atlaskit/editor-plugins/unsupported-content';
import { widthPlugin } from '@atlaskit/editor-plugins/width';
import type { DocNode } from '@atlaskit/adf-schema';

type Props = {
	defaultValue: DocNode | undefined;
	onChange: (value: DocNode) => void;
};

export const PublicDescriptionEdit = ({ defaultValue, onChange }: Props) => {
	const { editorApi, preset } = usePreset((builder) =>
		builder
			.add([analyticsPlugin, {}])
			.add(betterTypeHistoryPlugin)
			.add([pastePlugin, { isFullPage: false }])
			.add(clipboardPlugin)
			.add(focusPlugin)
			.add(compositionPlugin)
			.add(contextIdentifierPlugin)
			.add(basePlugin)
			.add(decorationsPlugin)
			.add(typeAheadPlugin)
			.add(historyPlugin)
			.add(primaryToolbarPlugin)
			.add(undoRedoPlugin)
			.add(blockTypePlugin)
			.add(clearMarksOnEmptyDocPlugin)
			.add(textFormattingPlugin)
			.add(widthPlugin)
			.add(placeholderPlugin)
			.add(unsupportedContentPlugin)
			.add(editorDisabledPlugin)
			.add(submitEditorPlugin)
			.add(copyButtonPlugin)
			.add(floatingToolbarPlugin)
			.add(selectionPlugin)
			.add(contentInsertionPlugin)
			.add(codeBlockPlugin)
			.add([quickInsertPlugin, { disableDefaultItems: true, headless: false }])
			.add([
				insertBlockPlugin,
				{
					allowTables: true,
					allowExpand: false,
					horizontalRuleEnabled: true,
					showElementBrowserLink: false,
					appearance: 'full-page',
				},
			])
			.add([
				tablesPlugin,
				{
					tableOptions: {
						advanced: true,
					},
				},
			]),
	);

	return (
		<ComposableEditor
			defaultValue={defaultValue}
			onChange={() =>
				// Casting to the stricter DocNode type
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				editorApi?.core?.actions.requestDocument((newADF) => onChange(newADF as DocNode))
			}
			preset={preset}
		/>
	);
};

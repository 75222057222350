import getMeta from '@atlassian/jira-get-meta';
import { isFedRamp } from '@atlassian/atl-context';
import type { GetTenantAttributesReturnType } from './types.tsx';

export const getTenantAttributes: () => GetTenantAttributesReturnType = () => {
	const shard = getMeta('ajs-shard');
	const region = getMeta('ajs-region');

	return {
		shard,
		region,
		isFedRamp: isFedRamp(),
	};
};

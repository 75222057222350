import queryString from 'query-string';

import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';

// TODO : Add a prefix to identify each app and also make sure you update the same in test
const localStorageProvider = createLocalStorageProvider('');

// TODO: Move src/packages/issue/issue-view/src/common/service/issue/issue-view-mode.js to a platform package once we consume the extracted sprint field in issue view - https://jdog.jira-dev.com/browse/BENTO-9971

type ViewMode = 'planning' | 'planning.nodetail' | 'detail' | '';

// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
const getViewMode = (): ViewMode => queryString.parse(window.location.search).view || '';

// Includes check is useful here in case LegacyJS loads first and/or Sidebar closed
export const isBacklogSidebar = (): boolean => getViewMode().includes('planning');

// Helper functions to get the checkbox value in sprint edit component from local storage
export const EDIT_SPRINT_CHECKBOX_CACHE_KEY = 'edit_sprint_cache_value';

export const getCheckboxValueFromLocalStorage = (key: string): boolean => {
	let value;
	if (fg('jfp-vulcan-browser-storage-migration')) {
		value = localStorageProvider.get(key);
	} else if (typeof window !== 'undefined') {
		value = window.localStorage?.getItem(key);
	}
	return value ? JSON.parse(value) : true;
};

export const setCheckBoxValueInLocalStorage = (key: string, value: boolean) => {
	try {
		if (fg('jfp-vulcan-browser-storage-migration')) {
			localStorageProvider.set(key, value);
		} else if (typeof window !== 'undefined') {
			// @ts-expect-error - TS2345 - Argument of type 'boolean' is not assignable to parameter of type 'string'.
			window.localStorage.setItem(key, value);
		}
	} catch {
		// do nothing
	}
};

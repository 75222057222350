import { useEffect } from 'react';

import { di } from 'react-magnetic-di';

import { useIssueAdjustmentsActions } from '../../controllers/issue-adjustments/main';

export const ErrorHandler = (): null => {
	di(useIssueAdjustmentsActions);
	const { setError, resetError } = useIssueAdjustmentsActions();

	useEffect(() => {
		setError({
			errorCode: 'ERROR_BOUNDARY_HANDLER_ERROR_CODE',
		});

		return () => resetError();
	}, [setError, resetError]);

	return null;
};

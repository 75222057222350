import {
	CUSTOM_FIELD_MODULE,
	ENTRY_POINT_EDIT,
} from '@atlassian/jira-forge-ui-constants/src/constants.tsx';
import type { Source } from '@atlassian/jira-forge-ui-types/src/common/types/analytics.tsx';
import type { Attributes } from '@atlassian/jira-product-analytics-bridge';
import { fg } from '@atlassian/jira-feature-gating';
import {
	fireOperationalEvent,
	fireTrackEvent,
	fireUiEvent,
} from '../../common/utils/consumers/index.tsx';
import { getEventId, getEventIdOld } from '../../common/utils/index.tsx';
import { forgeModulesConcurrentLoadTime } from '../../common/utils/performance-analytics/main.tsx';
import {
	MODULE_TYPE_TRIGGER,
	MODULE_TYPE_EXTENSION,
	TRACK_ACTIONS,
	UI_ACTIONS,
	OPERATIONAL_ACTIONS,
} from '../../constants.tsx';

const moduleEventId = getEventIdOld({
	module: CUSTOM_FIELD_MODULE,
	moduleType: MODULE_TYPE_EXTENSION,
	entryPoint: ENTRY_POINT_EDIT,
});

const triggerEventId = getEventIdOld({
	module: CUSTOM_FIELD_MODULE,
	moduleType: MODULE_TYPE_TRIGGER,
	entryPoint: ENTRY_POINT_EDIT,
});

export const fireTrackEditedEvent = (source?: Source, attributes?: Attributes) =>
	fireTrackEvent(TRACK_ACTIONS.EDITED, {
		id: fg('ditto-fcf-support-new-manifest-on-frontend')
			? getEventId({
					module: CUSTOM_FIELD_MODULE,
					moduleType: MODULE_TYPE_EXTENSION,
					entryPoint: ENTRY_POINT_EDIT,
				})
			: moduleEventId,
		source,
		attributes,
	});

export const fireUiCanceledEvent = (source?: Source, attributes?: Attributes) =>
	fireUiEvent(UI_ACTIONS.CANCELED, {
		id: fg('ditto-fcf-support-new-manifest-on-frontend')
			? getEventId({
					module: CUSTOM_FIELD_MODULE,
					moduleType: MODULE_TYPE_EXTENSION,
					entryPoint: ENTRY_POINT_EDIT,
				})
			: moduleEventId,
		source,
		attributes,
	});

export const fireOperationalEditFailedEvent = (source?: Source, attributes?: Attributes) =>
	fireOperationalEvent(OPERATIONAL_ACTIONS.EDIT_FAILED, {
		id: fg('ditto-fcf-support-new-manifest-on-frontend')
			? getEventId({
					module: CUSTOM_FIELD_MODULE,
					moduleType: MODULE_TYPE_EXTENSION,
					entryPoint: ENTRY_POINT_EDIT,
				})
			: moduleEventId,
		source,
		attributes,
	});

export const fireUiTriggerClickEvent = (
	extensionId: string,
	source?: Source,
	attributes?: Attributes,
) => {
	forgeModulesConcurrentLoadTime(extensionId).start({ startTime: performance.now() });

	return fireUiEvent(UI_ACTIONS.CLICKED, {
		id: fg('ditto-fcf-support-new-manifest-on-frontend')
			? getEventId({
					module: CUSTOM_FIELD_MODULE,
					moduleType: MODULE_TYPE_TRIGGER,
					entryPoint: ENTRY_POINT_EDIT,
				})
			: triggerEventId,
		source,
		attributes,
	});
};

export const fireOperationalFailedEvent = (source?: Source, attributes?: Attributes) =>
	fireOperationalEvent(OPERATIONAL_ACTIONS.FAILED, {
		id: fg('ditto-fcf-support-new-manifest-on-frontend')
			? getEventId({
					module: CUSTOM_FIELD_MODULE,
					moduleType: MODULE_TYPE_EXTENSION,
					entryPoint: ENTRY_POINT_EDIT,
				})
			: moduleEventId,
		source,
		attributes,
	});

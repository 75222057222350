import invert from 'lodash/invert';
import type { TagColor } from '@atlaskit/tag';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import {
	type Color,
	type ServerColor,
	type ClassicEpicColor,
	type AgileColor,
	type ColorSchema,
	PURPLE,
	BLUE,
	GREY,
	GREEN,
	YELLOW,
	TEAL,
	ORANGE,
	DARK_GREEN,
	DARK_ORANGE,
	DARK_YELLOW,
	DARK_TEAL,
	DARK_BLUE,
	DARK_GREY,
	DARK_PURPLE,
	SERVER_PURPLE,
	SERVER_DARK_PURPLE,
	SERVER_BLUE,
	SERVER_DARK_BLUE,
	SERVER_GREEN,
	SERVER_DARK_GREEN,
	SERVER_TEAL,
	SERVER_DARK_TEAL,
	SERVER_YELLOW,
	SERVER_DARK_YELLOW,
	SERVER_ORANGE,
	SERVER_DARK_ORANGE,
	SERVER_GREY,
	SERVER_DARK_GREY,
	GHX_LABEL_1,
	GHX_LABEL_2,
	GHX_LABEL_3,
	GHX_LABEL_4,
	GHX_LABEL_5,
	GHX_LABEL_6,
	GHX_LABEL_7,
	GHX_LABEL_8,
	GHX_LABEL_9,
	GHX_LABEL_10,
	GHX_LABEL_11,
	GHX_LABEL_12,
	GHX_LABEL_13,
	GHX_LABEL_14,
	AGILE_COLOR_1,
	AGILE_COLOR_2,
	AGILE_COLOR_3,
	AGILE_COLOR_4,
	AGILE_COLOR_5,
	AGILE_COLOR_6,
	AGILE_COLOR_7,
	AGILE_COLOR_8,
	AGILE_COLOR_9,
	AGILE_COLOR_10,
	AGILE_COLOR_11,
	AGILE_COLOR_12,
	AGILE_COLOR_13,
	AGILE_COLOR_14,
} from '@atlassian/jira-issue-epic-color-types/src/types.tsx';

export const DEFAULT_EPIC_COLOR = 'PURPLE';

export const MAP_COLORS_TO_VALUE: Record<Color, string> = {
	[PURPLE]: token('color.background.accent.purple.subtle'),
	[BLUE]: token('color.background.accent.blue.subtle'),
	[GREEN]: token('color.background.accent.green.subtle'),
	[TEAL]: token('color.background.accent.teal.subtle'),
	[YELLOW]: token('color.background.accent.yellow.subtle'),
	[ORANGE]: token('color.background.accent.red.subtle'),
	[GREY]: token('color.background.accent.gray.subtle'),
	[DARK_PURPLE]: token('color.background.accent.purple.bolder'),
	[DARK_BLUE]: token('color.background.accent.blue.bolder'),
	[DARK_GREEN]: token('color.background.accent.green.bolder'),
	[DARK_TEAL]: token('color.background.accent.teal.bolder'),
	[DARK_YELLOW]: token('color.background.accent.orange.bolder'),
	[DARK_ORANGE]: token('color.background.accent.red.bolder'),
	[DARK_GREY]: token('color.background.accent.gray.bolder'),
};

export const MAP_COLORS_TO_TOKEN: Record<string, string> = {
	[colors.P200]: token('color.background.accent.purple.subtle'),
	[colors.B200]: token('color.background.accent.blue.subtle'),
	[colors.G200]: token('color.background.accent.green.subtle'),
	[colors.T200]: token('color.background.accent.teal.subtle'),
	[colors.Y200]: token('color.background.accent.yellow.subtle'),
	[colors.R200]: token('color.background.accent.red.subtle'),
	[colors.N200]: token('color.background.accent.gray.subtle'),
	[colors.P400]: token('color.background.accent.purple.bolder'),
	[colors.B400]: token('color.background.accent.blue.bolder'),
	[colors.G400]: token('color.background.accent.green.bolder'),
	[colors.T400]: token('color.background.accent.teal.bolder'),
	[colors.Y400]: token('color.background.accent.orange.bolder'),
	[colors.R400]: token('color.background.accent.red.bolder'),
	[colors.N700]: token('color.background.accent.gray.bolder'),
};

// NOTE: lodash invert coerce the output to Dictionary<string> instead of using a polymorphic type
// @ts-expect-error - TS2322 - Type 'Dictionary<string>' is not assignable to type '{ [key: string]: Color; }'.
export const MAP_VALUE_TO_COLOR: Record<string, Color> = invert(MAP_COLORS_TO_VALUE);

export const MAP_FROM_SERVER_COLOR: Record<ServerColor, Color> = {
	[SERVER_PURPLE]: PURPLE,
	[SERVER_DARK_PURPLE]: DARK_PURPLE,
	[SERVER_BLUE]: BLUE,
	[SERVER_DARK_BLUE]: DARK_BLUE,
	[SERVER_GREEN]: GREEN,
	[SERVER_DARK_GREEN]: DARK_GREEN,
	[SERVER_TEAL]: TEAL,
	[SERVER_DARK_TEAL]: DARK_TEAL,
	[SERVER_YELLOW]: YELLOW,
	[SERVER_DARK_YELLOW]: DARK_YELLOW,
	[SERVER_ORANGE]: ORANGE,
	[SERVER_DARK_ORANGE]: DARK_ORANGE,
	[SERVER_GREY]: GREY,
	[SERVER_DARK_GREY]: DARK_GREY,
};

// NOTE: lodash invert coerce the output to Dictionary<string> instead of using a polymorphic type
// @ts-expect-error - TS2322 - Type 'Dictionary<string>' is not assignable to type 'Partial<Record<string, ServerColor>>'.
export const MAP_FROM_CLIENT_SERVER_COLOR: Record<Color, ServerColor> =
	invert(MAP_FROM_SERVER_COLOR);

export const MAP_FROM_SERVER_CLASSIC_COLOR: Record<ClassicEpicColor, Color> = {
	[GHX_LABEL_1]: DARK_GREY,
	[GHX_LABEL_2]: DARK_YELLOW,
	[GHX_LABEL_3]: YELLOW,
	[GHX_LABEL_4]: DARK_BLUE,
	[GHX_LABEL_5]: DARK_TEAL,
	[GHX_LABEL_6]: GREEN,
	[GHX_LABEL_7]: PURPLE,
	[GHX_LABEL_8]: DARK_PURPLE,
	[GHX_LABEL_9]: ORANGE,
	[GHX_LABEL_10]: BLUE,
	[GHX_LABEL_11]: TEAL,
	[GHX_LABEL_12]: GREY,
	[GHX_LABEL_13]: DARK_GREEN,
	[GHX_LABEL_14]: DARK_ORANGE,
};

// NOTE: lodash invert coerce the output to Dictionary<string> instead of using a polymorphic type
// @ts-expect-error - TS2322 - Type 'Dictionary<string>' is not assignable to type 'Partial<Record<string, ClassicEpicColor>>'.
export const MAP_FROM_CLIENT_CLASSIC_COLOR: Record<Color, ClassicEpicColor> = invert(
	MAP_FROM_SERVER_CLASSIC_COLOR,
);

export const MAP_FROM_AGILE_COLOR: Record<AgileColor, Color> = {
	[AGILE_COLOR_1]: DARK_GREY,
	[AGILE_COLOR_2]: DARK_YELLOW,
	[AGILE_COLOR_3]: YELLOW,
	[AGILE_COLOR_4]: DARK_BLUE,
	[AGILE_COLOR_5]: DARK_TEAL,
	[AGILE_COLOR_6]: GREEN,
	[AGILE_COLOR_7]: PURPLE,
	[AGILE_COLOR_8]: DARK_PURPLE,
	[AGILE_COLOR_9]: ORANGE,
	[AGILE_COLOR_10]: BLUE,
	[AGILE_COLOR_11]: TEAL,
	[AGILE_COLOR_12]: GREY,
	[AGILE_COLOR_13]: DARK_GREEN,
	[AGILE_COLOR_14]: DARK_ORANGE,
};

// NOTE: lodash invert coerce the output to Dictionary<string> instead of using a polymorphic type
// @ts-expect-error - TS2322 - Type 'Dictionary<string>' is not assignable to type 'Partial<Record<string, AgileColor>>'.
export const MAP_FROM_CLIENT_AGILE_COLOR: Record<Color, AgileColor> = invert(MAP_FROM_AGILE_COLOR);

export const MAP_CLASSIC_COLOR_TO_AGILE_COLOR: Record<ClassicEpicColor, AgileColor> = {
	[GHX_LABEL_1]: AGILE_COLOR_1,
	[GHX_LABEL_2]: AGILE_COLOR_2,
	[GHX_LABEL_3]: AGILE_COLOR_3,
	[GHX_LABEL_4]: AGILE_COLOR_4,
	[GHX_LABEL_5]: AGILE_COLOR_5,
	[GHX_LABEL_6]: AGILE_COLOR_6,
	[GHX_LABEL_7]: AGILE_COLOR_7,
	[GHX_LABEL_8]: AGILE_COLOR_8,
	[GHX_LABEL_9]: AGILE_COLOR_9,
	[GHX_LABEL_10]: AGILE_COLOR_10,
	[GHX_LABEL_11]: AGILE_COLOR_11,
	[GHX_LABEL_12]: AGILE_COLOR_12,
	[GHX_LABEL_13]: AGILE_COLOR_13,
	[GHX_LABEL_14]: AGILE_COLOR_14,
};

export const MAP_COLOR_TO_AK_COLOR: Record<Color, TagColor> = {
	[DARK_GREY]: 'grey',
	[DARK_YELLOW]: 'yellow',
	[YELLOW]: 'yellowLight',
	[DARK_BLUE]: 'blue',
	[DARK_TEAL]: 'teal',
	[GREEN]: 'greenLight',
	[PURPLE]: 'purpleLight',
	[DARK_PURPLE]: 'purple',
	[ORANGE]: 'redLight',
	[BLUE]: 'blueLight',
	[TEAL]: 'tealLight',
	[GREY]: 'greyLight',
	[DARK_GREEN]: 'green',
	[DARK_ORANGE]: 'red',
};

export const MAP_AGILE_COLOR_TO_AK_COLOR: Record<AgileColor, TagColor> = {
	[AGILE_COLOR_1]: 'grey',
	[AGILE_COLOR_2]: 'yellow',
	[AGILE_COLOR_3]: 'yellowLight',
	[AGILE_COLOR_4]: 'blue',
	[AGILE_COLOR_5]: 'teal',
	[AGILE_COLOR_6]: 'greenLight',
	[AGILE_COLOR_7]: 'purpleLight',
	[AGILE_COLOR_8]: 'purple',
	[AGILE_COLOR_9]: 'redLight',
	[AGILE_COLOR_10]: 'blueLight',
	[AGILE_COLOR_11]: 'tealLight',
	[AGILE_COLOR_12]: 'greyLight',
	[AGILE_COLOR_13]: 'green',
	[AGILE_COLOR_14]: 'red',
};

export const BASE_COLOR_SCHEMA: Record<Color, ColorSchema> = {
	[PURPLE]: {
		primary: token('color.background.accent.purple.subtle'),
		secondary: token('color.background.accent.purple.bolder'),
		text: token('color.text.accent.purple'),
		textBackground: token('color.background.accent.purple.subtler'),
		border: token('color.border.accent.purple'),
		line: token('color.border.accent.purple'),
		primaryGradient: token('color.background.accent.purple.subtler'),
		hasContrastSecondaryGradient: true,
	},
	[BLUE]: {
		primary: token('color.background.accent.blue.subtle'),
		secondary: token('color.background.accent.blue.bolder'),
		text: token('color.text.accent.blue'),
		textBackground: token('color.background.accent.blue.subtler'),
		border: token('color.border.accent.blue'),
		line: token('color.border.accent.blue'),
		primaryGradient: token('color.background.accent.blue.subtler'),
		hasContrastSecondaryGradient: true,
	},
	[GREEN]: {
		primary: token('color.background.accent.green.subtle'),
		secondary: token('color.background.accent.green.bolder'),
		text: token('color.text.accent.green'),
		textBackground: token('color.background.accent.green.subtler'),
		border: token('color.border.accent.green'),
		line: token('color.border.accent.green'),
		primaryGradient: token('color.background.accent.green.subtler'),
		hasContrastSecondaryGradient: true,
	},
	[TEAL]: {
		primary: token('color.background.accent.teal.subtle'),
		secondary: token('color.background.accent.teal.bolder'),
		text: token('color.text.accent.teal'),
		textBackground: token('color.background.accent.teal.subtler'),
		border: token('color.border.accent.teal'),
		line: token('color.border.accent.teal'),
		primaryGradient: token('color.background.accent.teal.subtler'),
		hasContrastSecondaryGradient: true,
	},
	[YELLOW]: {
		primary: token('color.background.accent.yellow.subtle'),
		secondary: token('color.background.accent.yellow.bolder'),
		text: token('color.text.accent.yellow'),
		textBackground: token('color.background.accent.yellow.subtler'),
		border: token('color.border.accent.yellow'),
		line: token('color.border.accent.yellow'),
		primaryGradient: token('color.background.accent.yellow.subtler'),
		hasContrastSecondaryGradient: true,
	},
	[ORANGE]: {
		primary: token('color.background.accent.red.subtle'),
		secondary: token('color.background.accent.red.bolder'),
		text: token('color.text.accent.red'),
		textBackground: token('color.background.accent.red.subtler'),
		border: token('color.border.accent.red'),
		line: token('color.border.accent.red'),
		primaryGradient: token('color.background.accent.red.subtler'),
		hasContrastSecondaryGradient: true,
	},
	[GREY]: {
		primary: token('color.background.accent.gray.subtle'),
		secondary: token('color.background.accent.gray.bolder'),
		text: token('color.text.accent.gray'),
		textBackground: token('color.background.accent.gray.subtler'),
		border: token('color.border.accent.gray'),
		line: token('color.border.accent.gray'),
		primaryGradient: token('color.background.accent.gray.subtler'),
		hasContrastSecondaryGradient: true,
	},
	[DARK_PURPLE]: {
		primary: token('color.background.accent.purple.bolder'),
		secondary: token('color.background.accent.purple.subtle'),
		text: token('color.text.inverse'),
		textBackground: token('color.background.accent.purple.bolder'),
		border: token('color.border.accent.purple'),
		line: token('color.border.accent.purple'),
		primaryGradient: token('color.background.accent.purple.subtle'),
		hasContrastSecondaryGradient: false,
	},
	[DARK_BLUE]: {
		primary: token('color.background.accent.blue.bolder'),
		secondary: token('color.background.accent.blue.subtle'),
		text: token('color.text.inverse'),
		textBackground: token('color.background.accent.blue.bolder'),
		border: token('color.border.accent.blue'),
		line: token('color.border.accent.blue'),
		primaryGradient: token('color.background.accent.blue.subtle'),
		hasContrastSecondaryGradient: false,
	},
	[DARK_GREEN]: {
		primary: token('color.background.accent.green.bolder'),
		secondary: token('color.background.accent.green.subtle'),
		text: token('color.text.inverse'),
		textBackground: token('color.background.accent.green.bolder'),
		border: token('color.border.accent.green'),
		line: token('color.border.accent.green'),
		primaryGradient: token('color.background.accent.green.subtle'),
		hasContrastSecondaryGradient: false,
	},
	[DARK_TEAL]: {
		primary: token('color.background.accent.teal.bolder'),
		secondary: token('color.background.accent.teal.subtle'),
		text: token('color.text.inverse'),
		textBackground: token('color.background.accent.teal.bolder'),
		border: token('color.border.accent.teal'),
		line: token('color.border.accent.teal'),
		primaryGradient: token('color.background.accent.teal.subtle'),
		hasContrastSecondaryGradient: false,
	},
	[DARK_YELLOW]: {
		primary: token('color.background.accent.orange.bolder'),
		secondary: token('color.background.accent.orange.subtle'),
		text: token('color.text.inverse'),
		textBackground: token('color.background.accent.orange.bolder'),
		border: token('color.background.accent.orange.subtle'),
		line: token('color.border.accent.orange'),
		primaryGradient: token('color.background.accent.orange.subtle'),
		hasContrastSecondaryGradient: false,
	},
	[DARK_ORANGE]: {
		primary: token('color.background.accent.red.bolder'),
		secondary: token('color.background.accent.red.subtle'),
		text: token('color.text.inverse'),
		textBackground: token('color.background.accent.red.bolder'),
		border: token('color.background.accent.red.subtle'),
		line: token('color.border.accent.red'),
		primaryGradient: token('color.background.accent.red.subtle'),
		hasContrastSecondaryGradient: false,
	},
	[DARK_GREY]: {
		primary: token('color.background.accent.gray.bolder'),
		secondary: token('color.background.accent.gray.subtle'),
		text: token('color.text.inverse'),
		textBackground: token('color.background.accent.gray.bolder'),
		border: token('color.background.accent.gray.subtle'),
		line: token('color.border.accent.gray'),
		primaryGradient: token('color.background.accent.gray.subtle'),
		hasContrastSecondaryGradient: false,
	},
};

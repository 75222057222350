import React from 'react';
import Heading from '@atlaskit/heading';
import { Box, Flex, Stack, Text, xcss } from '@atlaskit/primitives';
import DoneSuccessIllustration from '@atlassian/jira-illustrations/src/ui/adg4/jira/spots/success/components/done-success/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
	SCREEN,
} from '@atlassian/jira-product-analytics-bridge';
import { messages } from './messages.tsx';
import { CTAButton } from './cta-button/CTAButton.tsx';

type Props = {
	onSubmitAnotherFormClick: () => void;
	analyticAttributes?: {
		accessLevel?: string;
		isOutsideUser?: boolean;
		isLoggedIn?: boolean;
	};
	issueKey?: string | null;
};

export const SuccessView = ({ onSubmitAnotherFormClick, analyticAttributes, issueKey }: Props) => {
	const { formatMessage } = useIntl();

	return (
		<ContextualAnalyticsData
			sourceName="businessFormSuccessView"
			sourceType={SCREEN}
			attributes={analyticAttributes}
		>
			<FireScreenAnalytics />
			<Box xcss={successWrapperStyles}>
				<Stack space="space.200">
					<Flex alignItems="center" justifyContent="center" xcss={illustrationStyles}>
						<DoneSuccessIllustration alt="" />
					</Flex>
					<Heading size="large" as="h1">
						{formatMessage(messages.successSubmissionHeading)}
					</Heading>
					<Text as="p">{formatMessage(messages.successSubmissionContent)}</Text>
					<Box xcss={buttonWrapperStyles}>
						<CTAButton
							onSubmitAnotherFormClick={onSubmitAnotherFormClick}
							issueKey={issueKey}
							analyticAttributes={analyticAttributes}
						/>
					</Box>
				</Stack>
			</Box>
		</ContextualAnalyticsData>
	);
};

const successWrapperStyles = xcss({
	marginRight: 'space.negative.200',
	marginLeft: 'space.negative.200',
	textAlign: 'center',
});

const buttonWrapperStyles = xcss({
	marginTop: 'space.100',
	marginRight: 'space.400',
	marginLeft: 'space.400',
});

const illustrationStyles = xcss({
	width: '84px',
	height: '84px',
	marginRight: 'auto',
	marginLeft: 'auto',
});

import React, { memo, useMemo } from 'react';
import { styled } from '@compiled/react';
import { Box } from '@atlaskit/primitives/compiled';
import UFOLoadHold from '@atlaskit/react-ufo/load-hold';
import { cssMap } from '@atlaskit/css';
import { token } from '@atlaskit/tokens';
import BusinessAppWrapper from '@atlassian/jira-business-app-wrapper/src/ui/app-wrapper/index.tsx';
import { BusinessDocumentTitle } from '@atlassian/jira-business-entity-common/src/utils/document-title/index.tsx';
import { ExperienceSuccess } from '@atlassian/jira-business-experience-tracking/src/controllers/experience-tracker/index.tsx';
import { PerformanceAnalytics } from '@atlassian/jira-business-performance/src/ui/index.tsx';
import { RenderStartMark } from '@atlassian/jira-business-performance/src/ui/page-load/index.tsx';
import { JWMSpaStatePageReady } from '@atlassian/jira-business-spa-state-page-ready/src/ui/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import {
	ContextualAnalyticsData,
	SCREEN,
	FireScreenAnalytics,
	fireTrackAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { useProject_DEPRECATED_DO_NOT_USE } from '@atlassian/jira-router-resources-business-project-details/src/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { useRouter } from '@atlassian/react-resource-router';
import { fg } from '@atlassian/jira-feature-gating';
import { useIsAnonymous } from '@atlassian/jira-tenant-context-controller/src/components/is-anonymous/index.tsx';
import {
	URL_PARAMS,
	VIEW_FORM_SUBMISSION_EXPERIENCE,
	VIEW_FORM_PUBLIC_SUBMISSION_EXPERIENCE,
} from '../../common/constants.tsx';
import { FormAccessLevelTypes } from '../../common/types.tsx';
import { Card } from '../../common/ui/card/index.tsx';
import {
	NoPermissionView,
	FormUnavailableView,
	IssueTypeDeletedView,
	IssueTypeNotAssociatedView,
	FormMissingSupportedRequiredFieldsView,
	FormContainsUnsupportedRequiredFieldsView,
	NoProjectAccessView,
} from '../../common/ui/empty-views/index.tsx';
import { FormBackground } from '../../common/ui/form-background/index.tsx';
import { usePublicFormsEnabledAnalyticsAttrs } from '../../common/utils/gates/public-forms-gate/index.tsx';
import { useConditionalFieldLogicAnalyticsAttrs } from '../../common/utils/gates/conditional-field-logic-gate/index.tsx';
import { isProjectScopedFieldType } from '../../common/utils/field-config/index.tsx';
import { useJWMFormsFeatures } from '../../controllers/features-context/index.tsx';
import { useFormSubmission } from '../../controllers/form-submission/index.tsx';
import type {
	FormSubmissionData,
	FormSubmissionError,
} from '../../controllers/form-submission/types.tsx';
import { MediaPermissionsPreloader } from '../../controllers/media-upload-permissions/index.tsx';
import { useRedirectToPublicFormSubmission } from '../../controllers/route-form/index.tsx';
import { PLACEHOLDER_FORM_HEIGHT } from './constants.tsx';
import { ContactAdminBanner } from './contact-admin-banner/index.tsx';
import { FillInView } from './fill-in-view/index.tsx';
import messages from './messages.tsx';

type FormContentProps = {
	formSubmissionLoading: boolean;
	formSubmissionData?: FormSubmissionData;
	formSubmissionError?: FormSubmissionError;
};

const FormContent = memo<FormContentProps>(
	// TODO remove eslint disable as part of FF FD-41661 cleanup
	// eslint-disable-next-line complexity
	({ formSubmissionLoading, formSubmissionData, formSubmissionError }: FormContentProps) => {
		const { createAnalyticsEvent } = useAnalyticsEvents();
		const { data: project } = useProject_DEPRECATED_DO_NOT_USE();
		const accessLevel = formSubmissionData?.formWithFieldData?.accessLevel;
		const canCreateIssues =
			Boolean(project?.permissions.createIssues) ||
			accessLevel === FormAccessLevelTypes.OPEN ||
			(accessLevel === FormAccessLevelTypes.PUBLIC && fg('jira-forms-public-access'));
		const hasError = formSubmissionError !== undefined;

		// if form's issue type has been deleted
		if (
			accessLevel !== FormAccessLevelTypes.PUBLIC &&
			!formSubmissionLoading &&
			formSubmissionData?.formWithFieldData &&
			!formSubmissionData?.formWithFieldData?.issueType &&
			!hasError
		) {
			return <IssueTypeDeletedView />;
		}

		// if form's issue type has been disassociated from the project
		if (
			accessLevel !== FormAccessLevelTypes.PUBLIC &&
			!formSubmissionLoading &&
			formSubmissionData?.formWithFieldData &&
			!formSubmissionData.fieldMetaData &&
			canCreateIssues &&
			formSubmissionData?.formWithFieldData?.issueType &&
			!hasError
		) {
			return (
				<IssueTypeNotAssociatedView
					issueTypeName={formSubmissionData?.formWithFieldData?.issueType?.name ?? ''}
				/>
			);
		}

		// if user has no permission to create issues
		if (!canCreateIssues) {
			return <NoPermissionView />;
		}

		// if issue cannot be created because there are missing supported required fields
		if (formSubmissionData?.formSubmissionValidationData?.hasMissingSupportedRequiredFields) {
			return <FormMissingSupportedRequiredFieldsView />;
		}

		// if issue cannot be created because there are missing unsupported required fields
		if (formSubmissionData?.formSubmissionValidationData?.hasUnsupportedRequiredFields) {
			return <FormContainsUnsupportedRequiredFieldsView />;
		}

		if (accessLevel !== FormAccessLevelTypes.PUBLIC || !fg('jira-forms-public-access')) {
			const canViewIssues = Boolean(project?.permissions.viewIssues);
			// if issue cannot be created because there are project-scoped required fields and the user does not have permission to view them
			const noPermissionToViewProjectScopedRequiredFields =
				formSubmissionData?.formSubmissionValidationData?.hasProjectScopedRequiredFields &&
				!canViewIssues;

			if (noPermissionToViewProjectScopedRequiredFields) {
				const projectScopedRequiredFields =
					formSubmissionData?.formWithFieldData?.fields
						.filter((f) => f.isRequiredByForm && isProjectScopedFieldType(f.type))
						.map((f) => f.type) ?? [];

				const analyticsEvent = createAnalyticsEvent({});
				fireTrackAnalytics(analyticsEvent, 'formUnavailable viewed', {
					error: 'noPermissionToViewProjectScopedRequiredFields',
					projectScopedRequiredFields,
				});
				return <NoProjectAccessView />;
			}
		}

		// generic screen for any other kind of error
		if (
			hasError ||
			formSubmissionData == null ||
			formSubmissionData.formWithFieldData == null ||
			formSubmissionData.fieldMetaData == null ||
			formSubmissionData?.formWithFieldData?.enabled === false ||
			formSubmissionData?.formSubmissionValidationData?.isFormSubmissionValid === false
		) {
			return <FormUnavailableView />;
		}

		return (
			<>
				<MediaPermissionsPreloader
					projectId={String(formSubmissionData.formWithFieldData.projectId)}
					formId={formSubmissionData.formWithFieldData.formId}
				/>
				<FillInView />
			</>
		);
	},
);

const styles = cssMap({
	container: {
		borderRadius: token('border.radius'),
		paddingTop: token('space.400'),
		paddingBottom: token('space.400'),
		paddingLeft: token('space.400'),
		paddingRight: token('space.400'),
		marginRight: 'auto',
		marginLeft: 'auto',
		boxShadow: token('elevation.shadow.raised'),
		maxWidth: '538px',
		width: '100%',
	},
});

export const FormSubmission = memo<{}>(() => {
	const { formatMessage } = useIntl();
	const { featureView } = useJWMFormsFeatures();

	const {
		data: formSubmissionData,
		loading: formSubmissionLoading,
		error: formSubmissionError,
	} = useFormSubmission();

	const pageHeading = formatMessage(messages.pageHeading);
	const hasError = formSubmissionError !== undefined;
	const loading = formSubmissionLoading;

	const experienceTrackerAttributes = {
		...useConditionalFieldLogicAnalyticsAttrs(),
		...usePublicFormsEnabledAnalyticsAttrs(),
	};

	return (
		<UFOSegment name="form-submission">
			<UFOLoadHold name="form-submission" hold={loading} />
			{!loading && fg('jira_move_fire_screen_analytics_to_form_submission') && (
				<FireScreenAnalytics
					attributes={{
						usedFieldsCount: formSubmissionData?.formWithFieldData?.fields?.length ?? undefined,
					}}
				/>
			)}
			<RenderStartMark view={featureView} loading={loading} />
			<BusinessDocumentTitle pageHeading={pageHeading} />
			<FormBackground
				bannerColor={formSubmissionData?.formWithFieldData?.bannerColor}
				showPoweredByJiraFooter={fg('jira-forms-public-access')}
			>
				{fg('jira-forms-public-access') ? (
					<Box xcss={styles.container} backgroundColor="elevation.surface.raised">
						{!hasError && loading ? (
							<Placeholder
								id="form-content"
								data-testid="business-form.ui.form-submission.place-holder"
							/>
						) : (
							<>
								<FormContent
									formSubmissionLoading={formSubmissionLoading}
									formSubmissionData={formSubmissionData}
									formSubmissionError={formSubmissionError}
								/>
								<ExperienceSuccess
									experience={
										formSubmissionData?.formWithFieldData?.accessLevel ===
											FormAccessLevelTypes.PUBLIC && fg('jira-forms-public-access')
											? VIEW_FORM_PUBLIC_SUBMISSION_EXPERIENCE
											: VIEW_FORM_SUBMISSION_EXPERIENCE
									}
									attributes={experienceTrackerAttributes}
								/>
							</>
						)}
					</Box>
				) : (
					<Card minHeight={PLACEHOLDER_FORM_HEIGHT}>
						{!hasError && loading ? (
							<Placeholder
								id="form-content"
								data-testid="business-form.ui.form-submission.place-holder"
							/>
						) : (
							<>
								<FormContent
									formSubmissionLoading={formSubmissionLoading}
									formSubmissionData={formSubmissionData}
									formSubmissionError={formSubmissionError}
								/>
								<ExperienceSuccess
									experience={VIEW_FORM_SUBMISSION_EXPERIENCE}
									attributes={experienceTrackerAttributes}
								/>
							</>
						)}
					</Card>
				)}
			</FormBackground>
			<PerformanceAnalytics view={featureView} loading={loading} />
			{!loading && <JWMSpaStatePageReady />}
		</UFOSegment>
	);
});

export const FormSubmissionRoot = memo<{}>(() => {
	const { data: project } = useProject_DEPRECATED_DO_NOT_USE();
	const [{ query }] = useRouter();

	const { data: formSubmissionData, formId } = useFormSubmission();

	const isAnonymous = useIsAnonymous();
	const formAnalyticAttributes = useMemo(
		() => ({
			accessLevel: formSubmissionData?.formWithFieldData?.accessLevel?.toString(),
			isOutsideUser: !project || !project?.permissions.createIssues,
			formId,
			isLoggedIn: fg('jira-forms-public-access') ? !isAnonymous : undefined,
		}),
		[formSubmissionData, project, formId, isAnonymous],
	);
	const { shouldRedirectToPublicFormSubmission, redirectToPublicFormSubmission } =
		useRedirectToPublicFormSubmission();

	if (shouldRedirectToPublicFormSubmission()) {
		redirectToPublicFormSubmission();
	}

	let shouldShowContactAdminBanner = false;
	if (
		formSubmissionData?.formWithFieldData?.accessLevel !== FormAccessLevelTypes.PUBLIC ||
		!fg('jira-forms-public-access')
	) {
		const canCreateOrModifyForm = Boolean(project?.permissions.administerProject);
		shouldShowContactAdminBanner =
			!canCreateOrModifyForm && query[URL_PARAMS.FROM] === URL_PARAMS.DIRECTORY;
	}

	const analyticsAttributes = {
		...useConditionalFieldLogicAnalyticsAttrs(),
		...usePublicFormsEnabledAnalyticsAttrs(),
		...formAnalyticAttributes,
	};

	return (
		<UFOSegment name="form-submission-root">
			<>
				<ContextualAnalyticsData
					sourceName="businessFormSubmission"
					sourceType={SCREEN}
					attributes={analyticsAttributes}
				>
					{/* Note: Public forms skips this banner, because it is missing permissions data */}
					{shouldShowContactAdminBanner && <ContactAdminBanner />}
					<BusinessAppWrapper
						shouldAddPadding={false}
						withOverflow={!fg('jira-forms-public-access')}
					>
						<FormSubmission />
					</BusinessAppWrapper>
					{!fg('jira_move_fire_screen_analytics_to_form_submission') && <FireScreenAnalytics />}
				</ContextualAnalyticsData>
			</>
		</UFOSegment>
	);
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Placeholder = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${PLACEHOLDER_FORM_HEIGHT}px`,
});

import { fireErrorAnalytics } from '@atlassian/ui-modifications-analytics';

import type { ViewType } from '../../common/types/context';
import type { ExecutionContext } from '../../common/types/execution-context';
import type { FieldType } from '../../common/types/field';
import type { BaseFieldConfiguration } from '../../types';

import { getViewConfiguration } from './store';

const isKeyOf = <T extends Record<string, unknown>>(key: keyof T, object: T): key is keyof T =>
	object[key] !== undefined;

const getConfigurationByViewType = <T extends BaseFieldConfiguration = BaseFieldConfiguration>({
	viewType,
}: {
	viewType: ViewType;
}) => {
	const viewConfiguration = getViewConfiguration<T>(viewType);

	if (!viewConfiguration) {
		throw new Error(`Unsupported view type ${viewType} in getConfigurationByViewType.`);
	}

	return viewConfiguration;
};

export function getSupportedFieldConfiguration<T extends BaseFieldConfiguration>(
	viewType: ViewType,
	fieldType: FieldType,
) {
	const fieldsConfiguration = getConfigurationByViewType<T>({
		viewType,
	}).getSupportedFieldsConfiguration();

	if (!isKeyOf(fieldType, fieldsConfiguration)) {
		fireErrorAnalytics({
			error: new Error('Unsupported field type.'),
			id: 'getSupportedFieldConfiguration',
			viewType,
			attributes: {
				fieldType,
			},
		});

		return undefined;
	}

	return fieldsConfiguration[fieldType];
}

export const getSupportedFieldTypes = (viewType: ViewType) =>
	getConfigurationByViewType({ viewType }).getSupportedFieldTypes();

export function isFieldTypeSupported(
	viewType: ViewType,
	fieldType: FieldType | undefined,
): fieldType is FieldType {
	return (
		fieldType !== undefined &&
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		(getSupportedFieldTypes(viewType) as string[]).includes(fieldType)
	);
}

export function getConsentMessage(viewType: ViewType) {
	return getConfigurationByViewType({ viewType }).getConsentMessage();
}

export function isExecutionContextSupported(context: ExecutionContext) {
	return getConfigurationByViewType({ viewType: context.viewType }).isExecutionContextSupported({
		context,
	});
}

export function getAnalytics(viewType: ViewType) {
	return getConfigurationByViewType({ viewType }).getAnalytics();
}

export function getSupportedTriggerPointsConfiguration(viewType: ViewType): Set<string> {
	return getConfigurationByViewType({ viewType }).getSupportedTriggerPointsConfiguration();
}

export const isTriggerPointSupported = (
	viewType: ViewType,
	triggerPoint: string | undefined,
): boolean =>
	triggerPoint !== undefined && getSupportedTriggerPointsConfiguration(viewType).has(triggerPoint);

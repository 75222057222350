import uniqueId from 'lodash/uniqueId';
import { di } from 'react-magnetic-di';
import type { StoreActionApi } from 'react-sweet-state';

import type { EmitChangeOn } from '../../../../common/types';
import type { FieldId, FieldType } from '../../../../common/types/field';
import { getSupportedFieldConfiguration } from '../../../view-configuration';
import type { IssueAdjustmentsState, StoreContainerProps } from '../../types';
import { calculateNextFormValue } from '../../utils/calculate-next-form-value';

export type ProcessChangePayload = {
	fieldId: FieldId;
	fieldValue: unknown;
	eventType: EmitChangeOn;
};

/**
 * Responsible for notifying UIM store about last processed change.
 */
export const processChange =
	({ fieldId, fieldValue, eventType }: Omit<ProcessChangePayload, 'fieldType'>) =>
	(
		{ setState, getState }: StoreActionApi<IssueAdjustmentsState>,
		{ viewType }: StoreContainerProps,
	) => {
		di(uniqueId);

		const { internalFormMetadata, formData } = getState();
		const fieldType: FieldType = internalFormMetadata[fieldId]?.fieldType;

		if (!fieldType) {
			return;
		}

		const emitChangeOn = getSupportedFieldConfiguration(viewType, fieldType)?.emitChangeOn;

		if (emitChangeOn !== eventType) {
			return;
		}

		const nextFormData = calculateNextFormValue({
			internalFormMetadata,
			formData,
			fieldType,
			fieldId,
			fieldValue,
			viewType,
		});

		if (nextFormData === null) {
			return;
		}

		/**
		 * setTimeout justification - we need to delay the state update to the next tick to avoid batched updates.
		 * Otherwise, UIM app may not be notified about every single change.
		 */
		setTimeout(() => {
			const changeId = uniqueId();

			setState({
				lastProcessedChange: { fieldId, changeId },
				formData: nextFormData,
			});
		}, 0);
	};

import {
	FORGE_FIELD_TYPE_PREFIX,
	PARTIAL_LEGACY_FORGE_FIELD_TYPE_PREFIX,
	LEGACY_FORGE_FIELD_TYPE_PREFIX,
} from '@atlassian/jira-forge-ui-constants/src/constants.tsx';

import {
	type CustomFieldExtension,
	type CustomFieldEditRenderContext,
	type CustomFieldViewRenderContext,
	isCustomFieldEditRenderContext,
	isCustomFieldViewRenderContext,
} from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';

/**
 * Tests if GIC `fieldType` is a Forge Custom Field.
 *
 * @param fieldType field type
 * @returns true if Forge Custom Field type
 *
 * Note: normally only Gira uses Forge legacy prefix "com.atlassian.forge:ari:cloud:ecosystem::extension/".
 * In most endpoints (incl. AGG) this should be "ari:cloud:ecosystem::extension/".
 *
 */
export const isForgeCustomField = (fieldType: string | undefined): boolean =>
	typeof fieldType === 'string' &&
	(fieldType.startsWith(LEGACY_FORGE_FIELD_TYPE_PREFIX) ||
		fieldType.startsWith(FORGE_FIELD_TYPE_PREFIX));

/**
 * Tests if GIC `fieldType` is a legacy value returned by GIRA
 * @param fieldType field type
 * @returns true if legacy value
 */
export const isForgeLegacyFieldType = (fieldType: string): boolean =>
	fieldType.startsWith(LEGACY_FORGE_FIELD_TYPE_PREFIX);

export const getExtensionIdFromLegacyFieldType = (fieldType: string): string | null =>
	fieldType.split(PARTIAL_LEGACY_FORGE_FIELD_TYPE_PREFIX)[1] || null;

export type FieldsType = {
	[key: string]: { fieldType: string };
};

export const getForgeFields = (fields: FieldsType) =>
	Object.values(fields).filter((field) => isForgeCustomField(field.fieldType));

// this function is used for blurring forge fields
export const focusFormButton = (
	formSelector: string,
	target: Document | HTMLElement = document,
) => {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const button = target.querySelector(
		`button[form="${formSelector}"][type="submit"]`,
	) as HTMLElement | null;
	if (button != null) button.focus();
};

export function isBuiltInJiraFieldRender(
	extension: CustomFieldExtension,
	entryPoint: 'edit' | 'view',
	experience: CustomFieldEditRenderContext | CustomFieldViewRenderContext,
) {
	const edit = extension.properties?.edit;
	const view = 'view' in extension.properties ? extension.properties.view : undefined;
	const entry = entryPoint === 'edit' ? edit : view;
	let supportedExperience = false;
	if (entryPoint === 'edit') {
		supportedExperience =
			edit && 'experience' in edit && edit.experience && isCustomFieldEditRenderContext(experience)
				? edit.experience.includes(experience)
				: false;
	} else {
		supportedExperience =
			view && 'experience' in view && view.experience && isCustomFieldViewRenderContext(experience)
				? view.experience.includes(experience)
				: false;
	}

	const hasRenderFunction = entry && 'function' in entry;
	const hasSupportedResource = entry && 'resource' in entry && supportedExperience;
	const render = entry && 'render' in entry ? entry.render : null;

	return entryPoint === 'view'
		? !hasRenderFunction && !(hasSupportedResource && render === 'native')
		: !hasRenderFunction && !hasSupportedResource;
}

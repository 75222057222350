import React, { type ReactNode } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Link from '@atlassian/jira-common-analytics-v2-wrapped-components/src/link.tsx';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

const LinkToSubmitFormCustomerAgreement = (props: {
	children: React.ReactNode;
	description: string;
}) => (
	<Link
		href="https://www.atlassian.com/legal/atlassian-customer-agreement#intro"
		target="_blank"
		rel="noopener noreferrer"
		aria-label={props.description}
	>
		{props.children}
	</Link>
);

const LinkToSubmitFormPrivacyPolicy = (props: {
	children: React.ReactNode;
	description: string;
}) => (
	<Link
		href="https://www.atlassian.com/legal/privacy-policy#what-this-policy-covers"
		target="_blank"
		rel="noopener noreferrer"
		aria-label={props.description}
	>
		{props.children}
	</Link>
);

export const PublicFormPrivacyPolicyFooter = () => {
	const { formatMessage } = useIntl();

	const footerContent = formatMessage(messages.publicFormPrivacyFooter, {
		a: (customerAgreement: ReactNode) => (
			<LinkToSubmitFormCustomerAgreement description={formatMessage(messages.anchorArialLabel)}>
				{customerAgreement}
			</LinkToSubmitFormCustomerAgreement>
		),
		b: (privacyPolicy: ReactNode) => (
			<LinkToSubmitFormPrivacyPolicy description={formatMessage(messages.anchorArialLabel)}>
				{privacyPolicy}
			</LinkToSubmitFormPrivacyPolicy>
		),
	});

	return (
		<Box
			xcss={privacyPolicyFooterStyle}
			data-testId="business-form.ui.form-submission.fill-in-view.public-form-privacy-policy-footer"
		>
			{footerContent}
		</Box>
	);
};

const privacyPolicyFooterStyle = xcss({
	width: '100%',
	font: token('font.body.small'),
	color: 'color.text.subtlest',
	flex: 1,
});

import type { StoreActionApi } from 'react-sweet-state';

import type { FieldType } from '../../../../common/types/field';
import type {
	FieldInternalMetadata,
	FieldMapInternalMetadata,
	FieldRenderer,
} from '../../../../common/types/field-internal-metadata';
import type { FormDataPublic } from '../../../../common/types/fields/form-field-data';
import type { IssueAdjustmentsState, StoreContainerProps } from '../../types';
import { mapFieldValueFromInternalToPublic } from '../../utils/map-field-value-from-internal-to-public';

export type FieldForInitialize = {
	fieldId: string;
	fieldType: FieldType;
	fieldName: string;
	description: string | undefined;
	isVisible: boolean;
	isReadOnly: boolean;
	isRequired: boolean;
	value: unknown;
	/**
	 * We should not rely on allowedValues.
	 * It's here only for exceptional scenario: GIC field with synchronous options.
	 */
	allowedValues?: unknown[];
};

export type InitializeFieldPayload = {
	field: FieldForInitialize;
	metadata?: {
		fieldRenderer?: FieldRenderer;
	};
};

/**
 * Initializes the field with all necessary data, also with the value.
 */
export const initializeField =
	({ field, metadata }: InitializeFieldPayload) =>
	(
		{ getState, setState }: StoreActionApi<IssueAdjustmentsState>,
		{ viewType }: StoreContainerProps,
	) => {
		const { internalFormMetadata, formData = {} } = getState();

		const fieldMetadata: FieldInternalMetadata = {
			allowedValues: field.allowedValues,
			isReadOnlyBySystem: field.isReadOnly,
			isRequiredBySystem: field.isRequired,
			fieldRenderer: metadata?.fieldRenderer,
			fieldType: field.fieldType,
		};

		const publicValue = mapFieldValueFromInternalToPublic({
			fieldMetadata,
			internalFieldValue: field.value,
			fieldType: field.fieldType,
			viewType,
		});

		setState({
			formData: {
				...formData,
				[field.fieldId]: {
					fieldId: field.fieldId,
					fieldName: field.fieldName,
					description: field.description,
					isVisible: field.isVisible,
					fieldType: field.fieldType,
					isReadOnly: field.isReadOnly,
					isRequired: field.isRequired,
					value: publicValue,
					optionsVisibility: undefined,
				},
			},
			internalFormMetadata: {
				...internalFormMetadata,
				[field.fieldId]: fieldMetadata,
			},
		});
	};

/**
 * Initializes all fields with all necessary data, also with the value.
 */
export const initializeFields =
	(fields: InitializeFieldPayload[]) =>
	({ setState }: StoreActionApi<IssueAdjustmentsState>, { viewType }: StoreContainerProps) => {
		const { formData, internalFormMetadata } = fields.reduce<{
			internalFormMetadata: FieldMapInternalMetadata;
			formData: FormDataPublic;
		}>(
			(acc, { field, metadata }) => {
				const fieldMetadata: FieldInternalMetadata = {
					allowedValues: field.allowedValues,
					isReadOnlyBySystem: field.isReadOnly,
					isRequiredBySystem: field.isRequired,
					fieldRenderer: metadata?.fieldRenderer,
					fieldType: field.fieldType,
				};

				const publicValue = mapFieldValueFromInternalToPublic({
					fieldMetadata,
					internalFieldValue: field.value,
					fieldType: field.fieldType,
					viewType,
				});

				acc.formData[field.fieldId] = {
					fieldId: field.fieldId,
					fieldName: field.fieldName,
					description: field.description,
					isVisible: field.isVisible,
					fieldType: field.fieldType,
					isReadOnly: field.isReadOnly,
					isRequired: field.isRequired,
					value: publicValue,
					optionsVisibility: undefined,
				};
				acc.internalFormMetadata[field.fieldId] = fieldMetadata;

				return acc;
			},
			{ internalFormMetadata: {}, formData: {} },
		);

		setState({
			formData,
			internalFormMetadata,
			numberOfSupportedFields: fields.length,
		});
	};

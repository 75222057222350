import { createSelector } from 'react-sweet-state';

import type { ViewType } from '../../../../common/types/context';
import type { FormDataPublic } from '../../../../common/types/fields/form-field-data';
import type { ScreenTabsDataPublic } from '../../../../common/types/screen-tabs';
import type { IssueAdjustmentsState } from '../../types';

import type { ReadyPublicDataForOnInit } from './types';

export const selectReadyPublicDataForOnInit = createSelector<
	IssueAdjustmentsState,
	{ viewType: ViewType },
	ReadyPublicDataForOnInit | null,
	Readonly<FormDataPublic | null>,
	number,
	ScreenTabsDataPublic | null,
	boolean
>(
	(state) => state.formData,
	(state) => state.numberOfSupportedFields,
	(state) => state.screenTabs,
	(state) => state.experienceDataIsLoading,
	(formData, numberOfSupportedFields, screenTabsData, experienceDataIsLoading) => {
		if (
			formData !== null &&
			screenTabsData !== null &&
			numberOfSupportedFields !== 0 &&
			experienceDataIsLoading === false &&
			Object.keys(formData).length === numberOfSupportedFields
		) {
			return {
				formData,
				screenTabsData,
			};
		}

		return null;
	},
);

import { REPORTER_TYPE, ISSUE_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
/**
 * Form metadata might include fields that are no longer supported, so
 * we need to ignore these.
 */
export const IGNORE_FIELD_IDS = [REPORTER_TYPE, ISSUE_TYPE] as const;

export const FORM_NOT_FOUND_ERROR = 'FORM_NOT_FOUND';

export const PROJECT_NOT_FOUND_ERROR = 'PROJECT_NOT_FOUND';

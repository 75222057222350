import React from 'react';
import Button, { LinkButton } from '@atlaskit/button/new';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useIntl } from '@atlassian/jira-intl';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { messages } from './messages.tsx';

const SIGN_UP_TO_JIRA_URL = 'https://www.atlassian.com/software/jira?referer=jira.com';

type Props = {
	onSubmitAnotherFormClick: () => void;
	analyticAttributes?: {
		accessLevel?: string;
		isOutsideUser?: boolean;
	};
	// Truthy only for User Type 1
	issueKey?: string | null;
};

/**
 * Three types of users may see this button,
 * 	1. A logged-in user accessing a non-Public form, i.e. Limited or Open
 * 		We will display a CTA "Submit another form"
 *  2. A logged-in user (with Issue Create permissions) accessing a Public form
 * 		We will display a CTA "View request"
 *	3. A logged-out user accessing a Public form
		We will display a CTA "Sign up to Jira"
 */
export const CTAButton = ({ onSubmitAnotherFormClick, analyticAttributes, issueKey }: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const accountId = useAccountId();

	const handleClick = (subjectId: string) => {
		fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', subjectId, {
			...analyticAttributes,
		});
	};

	const handleButtonClick = (subjectId: string) => {
		handleClick(subjectId);
		onSubmitAnotherFormClick();
	};

	if (issueKey) {
		return (
			<LinkButton
				appearance="primary"
				href={`/browse/${issueKey}`}
				onClick={() => handleClick('formViewRequest')}
			>
				{formatMessage(messages.formViewRequest)}
			</LinkButton>
		);
	}

	if (accountId) {
		return (
			<Button
				appearance="primary"
				onClick={() => handleButtonClick('submitAnotherResponseForForm')}
			>
				{formatMessage(messages.formSubmitAnotherForm)}
			</Button>
		);
	}

	return (
		<LinkButton
			appearance="primary"
			href={SIGN_UP_TO_JIRA_URL}
			onClick={() => handleClick('formSignUpToJira')}
		>
			{formatMessage(messages.formSignUpToJira)}
		</LinkButton>
	);
};

import React from 'react';
import { FormFooter } from '@atlaskit/form';
import { LoadingButton } from '@atlaskit/button';
import Button from '@atlaskit/button/new';
import { Box, xcss, Stack } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { PublicFormPrivacyPolicyFooter } from '../public-form-privacy-policy-footer/index.tsx';
import {
	PublicFormRecaptcha,
	type PublicFormRecaptchaResult,
} from '../public-form-recaptcha/index.tsx';
import { PublicFormAntiAbuseFooter } from '../public-form-anti-abuse-footer/PublicFormAntiAbuseFooter.tsx';
import messages from './messages.tsx';

type FormFooterProps = {
	isPublicForm: boolean;
	createIssueLoading: boolean;
	uploading: boolean;
	onRecaptchaChange: (result: PublicFormRecaptchaResult) => void;
};

const FormFooterOld = (props: FormFooterProps) => {
	const { createIssueLoading, uploading } = props;
	const { formatMessage } = useIntl();

	return (
		<FormFooter>
			<LoadingButton
				testId="business-form.ui.form-submission.fill-in-view.footer.loading-button"
				type="submit"
				appearance="primary"
				isLoading={createIssueLoading || uploading}
				interactionName="business-form-submission-submit"
			>
				{formatMessage(messages.submitButton)}
			</LoadingButton>
		</FormFooter>
	);
};

const FormFooterNew = (props: FormFooterProps) => {
	const { isPublicForm, createIssueLoading, uploading, onRecaptchaChange } = props;
	const { formatMessage } = useIntl();
	return (
		<Stack space="space.300">
			<Box xcss={submitButtonStyle}>
				<Button
					testId="business-form.ui.form-submission.fill-in-view.footer.loading-button"
					type="submit"
					appearance="primary"
					isLoading={createIssueLoading || uploading}
					interactionName="business-form-submission-submit"
				>
					{formatMessage(messages.submitButton)}
				</Button>
			</Box>
			{isPublicForm && (
				<Stack space="space.100">
					<PublicFormRecaptcha onChange={onRecaptchaChange} />
					<PublicFormPrivacyPolicyFooter />
					<PublicFormAntiAbuseFooter />
				</Stack>
			)}
		</Stack>
	);
};

export const Footer = componentWithFG('jira-forms-public-access', FormFooterNew, FormFooterOld);

const submitButtonStyle = xcss({
	alignSelf: 'flex-end',
	marginTop: 'space.200',
});

import React, { type ReactNode } from 'react';
import { Anchor, Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

const MailToReporter = (props: { children: React.ReactNode }) => (
	<Anchor href="mailto:abuse@atlassian.com" target="_blank">
		{props.children}
	</Anchor>
);

export const PublicFormAntiAbuseFooter = () => {
	const { formatMessage } = useIntl();

	const footerContent = formatMessage(messages.content, {
		a: (mailTo: ReactNode) => <MailToReporter>{mailTo}</MailToReporter>,
	});

	return (
		<Box
			xcss={antiAbuseFooterStyle}
			data-testId="business-form.ui.form-submission.fill-in-view.public-form-anti-abuse-footer"
		>
			{footerContent}
		</Box>
	);
};

const antiAbuseFooterStyle = xcss({
	width: '100%',
	font: token('font.body.small'),
	color: 'color.text.subtlest',
	flex: 1,
});

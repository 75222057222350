import React, { useRef } from 'react';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import { FieldContainer } from '@atlassian/jira-issue-create-commons/src/common/ui/fields/styled.tsx';
import { withFormField } from '@atlassian/jira-issue-create-commons/src/common/ui/with-form-field/index.tsx';
import { validateDescriptionField } from '@atlassian/jira-issue-create/src/common/ui/fields/description-field/utils.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { DescriptionEdit } from '../../description-edit/index.tsx';
import { FormAccessLevelTypes } from '../../../types.tsx';
import { PublicDescriptionEdit } from '../../public-description-edit/index.tsx';
import type { Props, DescriptionFieldConfig, FieldValue } from './types.tsx';

export const DescriptionField = (props: Props) => {
	const editorKey = useRef(Date.now());
	const {
		fieldProps: { value, onChange },
		mediaUploadContext,
		accessLevel,
	} = props;

	if (accessLevel === FormAccessLevelTypes.PUBLIC && fg('jira-forms-public-access')) {
		return (
			<PublicDescriptionEdit key={editorKey.current} onChange={onChange} defaultValue={value} />
		);
	}

	return (
		<FieldContainer width="100%">
			<DescriptionEdit
				key={editorKey.current}
				onChange={onChange}
				defaultValue={value}
				mediaContext={{
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore - TS2740 - Type '{}' is missing the following properties from type 'ViewContext': clientId, serviceHost, token, tokenLifespanInMs, and 3 more
					viewContext: mediaUploadContext || {},
					uploadContext: mediaUploadContext,
					userAuth: null,
				}}
			/>
		</FieldContainer>
	);
};

const DescriptionFormField = withFormField<ADF | undefined, undefined>({
	validator: validateDescriptionField,
})<DescriptionFieldConfig, FieldValue>(DescriptionField);

export default DescriptionFormField;

import React, { type ReactNode, memo } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { Box, xcss } from '@atlaskit/primitives';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { getDesignTokenBannerColor } from '../../utils/banner-color/index.tsx';
import { PoweredByJiraFooter } from './powered-by-jira-footer/PoweredByJiraFooter.tsx';

type Props = {
	shouldHaveIndependentScroll?: boolean;
	bannerColor?: string | undefined;
	header?: ReactNode;
	children: ReactNode;
	showPoweredByJiraFooter?: boolean;
};

const FormBackgroundOld = memo<Props>(({ children, bannerColor, header }: Props) => (
	<Background bannerColor={getDesignTokenBannerColor(bannerColor)}>
		{header}
		<ContentWrapper>{children}</ContentWrapper>
	</Background>
));

const FormBackgroundNew = memo<Props>(
	({ children, bannerColor, showPoweredByJiraFooter }: Props) => (
		<Box
			// eslint-disable-next-line jira/react/no-style-attribute
			style={{
				background: `linear-gradient(to bottom, ${
					getDesignTokenBannerColor(bannerColor) || token('color.background.neutral.hovered')
				} 30%, transparent 30%)`,
			}}
			xcss={backgroundStyles}
		>
			<Box xcss={contentWrapperStyles}>{children}</Box>
			{showPoweredByJiraFooter && <PoweredByJiraFooter />}
		</Box>
	),
);

export const FormBackground = componentWithFG(
	'jira-forms-public-access',
	FormBackgroundNew,
	FormBackgroundOld,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Background = styled.div<{
	bannerColor?: string;
}>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	background: ({ bannerColor }) =>
		`linear-gradient(to bottom, ${
			bannerColor || token('color.background.neutral.hovered')
		} 30%, transparent 30%)`,
	backgroundAttachment: 'fixed',
	width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentWrapper = styled.div({
	width: '560px',
	marginTop: token('space.300'),
	marginRight: 'auto',
	marginBottom: token('space.300'),
	marginLeft: 'auto',
});

const backgroundStyles = xcss({
	backgroundAttachment: 'fixed',
	width: '100%',
	display: 'flex',
	flexDirection: 'column',
	minHeight: '100vh',
});

const contentWrapperStyles = xcss({
	flex: '1',
	width: '100%',
	paddingTop: 'space.300',
	paddingBottom: 'space.300',
	paddingLeft: 'space.200',
	paddingRight: 'space.200',
});

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	content: {
		id: 'business-form.form-submission.fill-in-view.public-form-anti-abuse-footer.content',
		defaultMessage:
			'This form was created inside Atlassian. Does this form look suspicious? <a>Report</a>',
		description: 'the anti abuse content placed at the footer of public form',
	},
});

import React, { useCallback, useEffect, useRef, useState } from 'react';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import { v4 as uuid } from 'uuid';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { CreatableSelect } from '@atlaskit/select';
import type { ActionMeta } from '@atlassian/jira-common-components-picker/src/model.tsx';
import performance from '@atlassian/jira-common-performance/src/performance.tsx';
import type { SessionId } from '@atlassian/jira-issue-analytics/src/common/types.tsx';
import { memoizedHashString } from '@atlassian/jira-issue-analytics/src/utils/hash-attribute/index.tsx';
import type { LabelOption as Option } from '@atlassian/jira-issue-shared-types/src/common/types/labels-type.tsx';
import {
	fireUIAnalytics,
	fireTrackAnalytics,
	useAnalyticsEvents,
	AnalyticsEventToProps,
	type Attributes,
} from '@atlassian/jira-product-analytics-bridge';
import type { Props, SelectedOptions } from './types.tsx';
import {
	filterOptions,
	flattenOptions,
	getOptionValuesLengths,
	getSafeValue,
	getSafeOptionsForAnalytics,
} from './utils.tsx';

const ANALYTICS_PAYLOAD_TRUNCATION_SIZE = 20;

const analyticsProps = {
	onChange: 'valueChanged',
	onInputChange: 'inputChanged',
	onMenuOpen: 'menuOpened',
	onMenuClose: 'menuClosed',
} as const;

export const CreatableSelectWithAnalytics = AnalyticsEventToProps(
	'select',
	analyticsProps,
)(CreatableSelect);

const CreatableSelectWithAnalyticsView = (props: Props) => {
	const {
		fieldId,
		isLoading,
		onChange,
		onInputChange,
		options,
		value,
		isDisabled,
		sessionId: customSessionId,
		isMulti,
		inputId,
		isRequired,
	} = props;

	const sessionId = useRef<SessionId>(customSessionId);
	const sessionStartedAt = useRef(0);

	const [flatOptions, setFlatOptions] = useState<Option[]>(flattenOptions(options));
	const [queryAtSetValue, setQueryAtSetValue] = useState<string>('');
	const [savedQuery, setSavedQuery] = useState<string>('');

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const getAnalyticsAttributes = useCallback(
		(query: string): Attributes => {
			const selectedOptions = !isNil(value) ? value : [];

			let filteredOptions = filterOptions(flatOptions, query);
			const optionsLength = filteredOptions.length;

			// truncate to reduce payload size
			filteredOptions = filteredOptions.slice(0, ANALYTICS_PAYLOAD_TRUNCATION_SIZE);
			const safeFilteredOptions = getSafeOptionsForAnalytics(filteredOptions);

			return {
				inputId,
				required: isRequired,
				fieldId,
				isLoading,
				isDisabled,
				optionsLength,
				componentName: 'select',
				packageName: '@atlaskit/select',
				optionValuesSafeForAnalytics: false,
				options: safeFilteredOptions,
				optionsLengths: getOptionValuesLengths(filteredOptions),
				queryLength: query.length,
				queryHash: query === '' ? query : memoizedHashString(query),
				selectedOptions: getSafeOptionsForAnalytics(selectedOptions),
				selectedOptionsLengths: getOptionValuesLengths(selectedOptions),
				sessionId: sessionId.current,
				truncationSize: ANALYTICS_PAYLOAD_TRUNCATION_SIZE,
			};
		},
		[value, flatOptions, inputId, isRequired, fieldId, isLoading, isDisabled],
	);

	const getItemOrder = useCallback(
		(selectedItem: Option) => {
			const matchedIndexes: Array<number> = [];

			flatOptions.forEach((option, index) => {
				if (option.value === selectedItem.value) {
					matchedIndexes.push(index);
				}
			});

			return matchedIndexes.length > 0 ? matchedIndexes[0] : -1;
		},
		[flatOptions],
	);

	const fireUIAnalyticsEvent = useCallback(
		(analyticsEvent: UIAnalyticsEvent, query: string, extraAttributes?: Attributes) => {
			const attributes: Attributes = {
				...getAnalyticsAttributes(query),
				...extraAttributes,
			};

			fireUIAnalytics(analyticsEvent, attributes);
		},
		[getAnalyticsAttributes],
	);

	const fireTrackAnalyticsEvent = useCallback(
		(
			analyticsEvent: UIAnalyticsEvent,
			action: string,
			actionSubject: string,
			extraAttributes?: Attributes,
		) => {
			const attributes: Attributes = {
				...getAnalyticsAttributes(savedQuery),
				...extraAttributes,
			};

			fireTrackAnalytics(analyticsEvent, `${actionSubject} ${action}`, attributes);
		},
		[getAnalyticsAttributes, savedQuery],
	);

	const startSession = useCallback(() => {
		if (sessionId.current === undefined) {
			sessionId.current = uuid();
		}

		sessionStartedAt.current = performance.now();

		fireTrackAnalyticsEvent(
			createAnalyticsEvent({ action: 'started' }),
			'started',
			'optionSelectionSession',
		);
	}, [createAnalyticsEvent, fireTrackAnalyticsEvent, sessionId, sessionStartedAt]);

	const endSession = useCallback(
		(interactionType?: string) => {
			if (sessionId.current !== undefined) {
				const currentStartedAt = sessionStartedAt.current;
				const elapsedTime = currentStartedAt !== 0 ? performance.now() - currentStartedAt : 0;

				fireTrackAnalyticsEvent(
					createAnalyticsEvent({ action: 'ended' }),
					'ended',
					'optionSelectionSession',
					{ elapsedTime, interactionType },
				);
			}

			sessionId.current = undefined;
			sessionStartedAt.current = 0;
		},
		[createAnalyticsEvent, fireTrackAnalyticsEvent, sessionId, sessionStartedAt],
	);

	const restartSession = useCallback(
		(interactionType?: string) => {
			endSession(interactionType);
			startSession();
		},
		[endSession, startSession],
	);

	const fireInputChangedEvent = useCallback(
		(query: string, actionMeta: ActionMeta<Option>, analyticsEvent: UIAnalyticsEvent) => {
			if (actionMeta.action === 'set-value') {
				// Save query for option changed analytic
				setQueryAtSetValue(savedQuery);
			}

			setSavedQuery(query);

			analyticsEvent.update({
				action: 'changed',
				actionSubject: 'filter',
			});

			fireUIAnalyticsEvent(analyticsEvent, query);
		},
		[fireUIAnalyticsEvent, savedQuery, setSavedQuery, setQueryAtSetValue],
	);

	const fireOptionCreatedEvent = useCallback(
		(option: Option, analyticsEvent: UIAnalyticsEvent) => {
			analyticsEvent.update({
				action: 'created',
				actionSubject: 'option',
			});

			const oldSessionId = sessionId.current;

			restartSession('option created');

			fireUIAnalyticsEvent(analyticsEvent, queryAtSetValue, {
				selectedOption: getSafeValue(option.value),
				sessionId: oldSessionId,
			});
		},
		[fireUIAnalyticsEvent, queryAtSetValue, restartSession, sessionId],
	);

	const fireOptionRemovedEvent = useCallback(
		(option: Option | null | undefined, analyticsEvent: UIAnalyticsEvent) => {
			analyticsEvent.update({
				action: 'removed',
				actionSubject: 'option',
			});

			fireUIAnalyticsEvent(analyticsEvent, savedQuery, {
				removedItem: option ? getSafeValue(option.value) : undefined,
			});
		},
		[fireUIAnalyticsEvent, savedQuery],
	);

	const fireOptionSelectedEvent = useCallback(
		(option: Option, analyticsEvent: UIAnalyticsEvent) => {
			analyticsEvent.update({
				action: 'changed',
				actionSubject: 'option',
			});

			const oldSessionId = sessionId.current;

			restartSession('option selected');

			fireUIAnalyticsEvent(analyticsEvent, queryAtSetValue, {
				selectedOption: getSafeValue(option.value),
				selectedOptionLength: option.value !== null ? `${option.value}`.length : 0,
				selectedOrder: getItemOrder(option),
				sessionId: oldSessionId,
			});
		},
		[fireUIAnalyticsEvent, getItemOrder, queryAtSetValue, restartSession, sessionId],
	);

	const onChangeWithAnalytics = useCallback(
		(
			selectedOptionOrOptions: SelectedOptions,
			actionMeta: ActionMeta<Option>,
			analyticsEvent: UIAnalyticsEvent,
		) => {
			const selectedOption = Array.isArray(selectedOptionOrOptions)
				? selectedOptionOrOptions[selectedOptionOrOptions.length - 1]
				: selectedOptionOrOptions;

			switch (actionMeta.action) {
				case 'create-option':
					if (selectedOption !== null) {
						fireOptionCreatedEvent(selectedOption, analyticsEvent);
					}
					break;
				case 'remove-value':
				case 'pop-value':
				case 'deselect-option':
					fireOptionRemovedEvent(actionMeta.removedValue || actionMeta.option, analyticsEvent);
					break;
				case 'select-option':
					if (selectedOption !== null) {
						fireOptionSelectedEvent(selectedOption, analyticsEvent);
					}
					break;
				default:
					break;
			}

			const selectedValue = isMulti ? selectedOptionOrOptions || [] : selectedOptionOrOptions;

			if (onChange) {
				onChange(selectedValue, actionMeta);
			}
		},
		[fireOptionCreatedEvent, fireOptionRemovedEvent, fireOptionSelectedEvent, onChange, isMulti],
	);

	const onInputChangeWithAnalytics = useCallback(
		(query: string, actionMeta: ActionMeta<Option>, analyticsEvent: UIAnalyticsEvent) => {
			if (actionMeta.action === 'input-change' || actionMeta.action === 'set-value') {
				fireInputChangedEvent(query, actionMeta, analyticsEvent);
			}

			onInputChange(query, sessionId.current);
		},
		[fireInputChangedEvent, onInputChange, sessionId],
	);

	useEffect(() => {
		startSession();
		fireInputChangedEvent('', { action: 'fake-action' }, createAnalyticsEvent({}));

		return () => {
			endSession();
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		// since due to multiple setState calls component is rerendered
		// added this change to reduce the number of setState call will be fixed,
		// wasn't able to replicate this issue in local so adding this change in feature flag.
		// sentry link: https://sentry.prod.atl-paas.net/atlassian/jira-frontend/issues/4943577/?query=is%3Aunresolved%20logger%3AjiraIssueCreateModal.withFormField
		const flattenedOption = flattenOptions(options);
		if (!isEqual(flatOptions, flattenedOption)) {
			setFlatOptions(flattenedOption);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [options]);

	return (
		<CreatableSelectWithAnalytics
			{...props}
			onChange={onChangeWithAnalytics}
			onInputChange={onInputChangeWithAnalytics}
		/>
	);
};

export default CreatableSelectWithAnalyticsView;

import React, { type ReactNode } from 'react';
import { Anchor, Box, Flex, Text, xcss } from '@atlaskit/primitives';
import { JiraIcon } from '@atlaskit/logo';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { messages } from './messages.tsx';

const WAC_LINK =
	'https://www.atlassian.com/software/jira?utm_source=in-product-banner&utm_medium=inapp&utm_campaign=P:jira-work-management%7CO:cloudpmm-jwm%7CF:discovery%7CE:cloud%7CX:expand%7C';

export const PoweredByJiraFooter = () => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleJiraLinkClick = () => {
		fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'poweredByJwm');
	};

	return (
		<Flex justifyContent="center" xcss={footerStyles}>
			<Text color="color.text.subtle" weight="medium">
				{formatMessage(messages.footer, {
					link: (chunks: ReactNode[]) => (
						<Anchor
							href={WAC_LINK}
							target="_blank"
							onClick={handleJiraLinkClick}
							xcss={anchorStyles}
						>
							<Box xcss={iconStyles}>
								<JiraIcon size="small" appearance="brand" />
							</Box>
							{chunks}
						</Anchor>
					),
				})}
			</Text>
		</Flex>
	);
};

const footerStyles = xcss({
	width: '100%',
	marginLeft: 'auto',
	marginRight: 'auto',
	paddingBottom: 'space.500',
});

const anchorStyles = xcss({
	textDecoration: 'none',
	':hover': {
		textDecoration: 'none',
	},
});

const iconStyles = xcss({
	verticalAlign: 'middle',
	display: 'inline',
});

import type { FormEvent } from 'react';
import type {
	FieldInternalValue,
	EmitChangeOn,
} from '@atlassian/jira-issue-adjustments/src/common.tsx';
import { useIssueAdjustmentsActions } from '@atlassian/jira-issue-adjustments/src/controllers.tsx';
import { useFormApi } from '@atlassian/jira-issue-form-api/src/controllers/index.tsx';
import type { EnabledFieldValueDecoratorProps } from './types.tsx';

/**
 * It is responsible for propagting the change event to the UIM store.
 */
export const EnabledFieldValueDecorator = <
	FieldValue extends FieldInternalValue,
	FieldType extends string,
>({
	children,
	fieldId,
	fieldProps,
}: EnabledFieldValueDecoratorProps<FieldValue, FieldType>) => {
	const { onBlur, onChange } = fieldProps;
	const { getFieldValue } = useFormApi();
	const { processChange } = useIssueAdjustmentsActions();

	const processChangeByType = (eventType: EmitChangeOn) => {
		processChange({
			fieldId,
			fieldValue: getFieldValue(fieldId),
			eventType,
		});
	};

	const interceptedFieldProps = {
		...fieldProps,
		onChange: (modifiedValue: FieldValue | FormEvent<HTMLInputElement>) => {
			// Order is important to pass the current value to the UIM
			onChange(modifiedValue);
			processChangeByType('change');
		},
		onBlur: () => {
			// Order is important to pass the current value to the UIM
			onBlur();
			processChangeByType('blur');
		},
	};

	return children(interceptedFieldProps);
};

import type {
	AllowedFieldValue,
	AllowedPriorityValue,
	AllowedValue,
	CascadingAllowableValue,
} from '../../../services/field-meta/types.tsx';
// eslint-disable-next-line camelcase
import type { JiraBusinessFormWithFieldDataByFormUuid_jiraFormForPublicSubmission_fields_field } from '../../../services/get-form-public/__generated_apollo__/JiraBusinessFormWithFieldDataByFormUuid.ts';
import type { FieldNode, FieldOptionType } from '../../types/form-builder.tsx';

export const getFieldOptions = (
	field?: FieldNode['field']['field'],
): FieldOptionType[] | undefined => {
	if (!field) {
		return undefined;
	}

	switch (field.__typename) {
		case 'JiraRadioSelectField':
		case 'JiraSingleSelectField':
		case 'JiraMultipleSelectField':
		case 'JiraCheckboxesField':
			return field.fieldOptions?.edges
				?.map<FieldOptionType | undefined>((option) => {
					if (option?.node && option.node.value != null) {
						return {
							label: option.node.value,
							value: option.node.optionId,
						};
					}

					return undefined;
				})
				.filter(Boolean);
		case 'JiraPriorityField':
			return field.priorities?.edges
				?.map<FieldOptionType | undefined>((option) =>
					option?.node && option.node.name !== null
						? {
								label: option.node.name,
								value: option.node.priorityId,
								icon: option.node.iconUrl,
							}
						: undefined,
				)
				.filter(Boolean);
		default:
			return undefined;
	}
};

export const getFieldOptionsForFieldComponent = (
	// eslint-disable-next-line camelcase
	field?: JiraBusinessFormWithFieldDataByFormUuid_jiraFormForPublicSubmission_fields_field,
): AllowedFieldValue[] | undefined => {
	if (!field) {
		return undefined;
	}

	switch (field.__typename) {
		case 'JiraRadioSelectField':
		case 'JiraSingleSelectField':
		case 'JiraMultipleSelectField':
		case 'JiraCheckboxesField':
			return field.fieldOptions?.edges
				?.map<AllowedValue | undefined>((option) => {
					if (option?.node && option.node.value != null) {
						return {
							value: option.node.value,
							id: option.node.optionId,
							self: '',
						};
					}

					return undefined;
				})
				.filter(Boolean);
		case 'JiraPriorityField':
			return field.priorities?.edges
				?.map<AllowedPriorityValue | undefined>((option) =>
					option?.node && option.node.name !== null
						? {
								name: option.node.name,
								id: option.node.priorityId,
								iconUrl: option.node.iconUrl,
								self: '',
							}
						: undefined,
				)
				.filter(Boolean);
		case 'JiraCascadingSelectField':
			return field.cascadingOptions?.edges
				?.map<CascadingAllowableValue | undefined>((option) =>
					option?.node &&
					option.node.parentOptionValue != null &&
					option.node.childOptionValues != null
						? {
								parentOptionValue: {
									id: option?.node?.parentOptionValue?.optionId,
									value: option?.node?.parentOptionValue?.value ?? '',
									self: '',
									__typename: 'JiraOption',
								},
								childOptionValues: option?.node?.childOptionValues?.map((childOption) => ({
									id: childOption?.optionId ?? '',
									value: childOption?.value ?? '',
									self: '',
									__typename: 'JiraOption',
								})),
							}
						: undefined,
				)
				.filter(Boolean);
		default:
			return undefined;
	}
};

import React, { useEffect, useLayoutEffect } from 'react';
import { SkeletonCompiled } from '@atlassian/jira-common-components-skeleton/src/styled.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { asyncRichTextEditEntryPoint } from '../entrypoint.tsx';
import type { EntryPointContentProps } from './ui/edit/types.tsx';

const EMPTY_OBJECT = {} as const;

export const AsyncRichTextEditView = (props: EntryPointContentProps['props']) => {
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		asyncRichTextEditEntryPoint,
		// use stable object reference to avoid re-renders
		EMPTY_OBJECT,
	);

	if (fg('fix-create-another-bug-in-react18-concurrent-mode')) {
		// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks, react-hooks/rules-of-hooks
		useLayoutEffect(() => {
			entryPointActions.load();
		}, [entryPointActions]);
	} else {
		// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks, react-hooks/rules-of-hooks
		useEffect(() => {
			entryPointActions.load();
		}, [entryPointActions]);
	}

	return (
		<JiraEntryPointContainer
			id="jira-issue-field-rich-text-edit-async"
			packageName="jira-issue-field-rich-text"
			entryPointReferenceSubject={entryPointReferenceSubject}
			runtimeProps={props}
			fallback={<SkeletonCompiled height="150px" />}
			errorFallback="unmount"
		/>
	);
};

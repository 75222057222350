import React from 'react';
import { cssMap, styled } from '@compiled/react';
import Button from '@atlaskit/button';
import CalendarIcon from '@atlaskit/icon/core/migration/calendar';
import ChevronDownIcon from '@atlaskit/icon/utility/migration/chevron-down';
import ErrorIcon from '@atlaskit/icon/core/migration/error';
import UploadIcon from '@atlaskit/icon/core/migration/cloud-arrow-up--upload';
import UserAvatarCircleIcon from '@atlaskit/icon/core/migration/person-avatar--user-avatar-circle';
import Tooltip from '@atlaskit/tooltip';
import { Text, Inline, xcss, Flex } from '@atlaskit/primitives';
import { Flex as FlexCompiled } from '@atlaskit/primitives/compiled';
import { token } from '@atlaskit/tokens';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import messages from './messages.tsx';

export const TextFieldPlaceholder = () => {
	const { formatMessage } = useIntl();
	return <TextFieldWrapper>{formatMessage(messages.fieldPlaceholder)}</TextFieldWrapper>;
};

export const DescriptionFieldPlaceholder = () => {
	const { formatMessage } = useIntl();
	return <DescriptionWrapper>{formatMessage(messages.fieldPlaceholder)}</DescriptionWrapper>;
};

export const AttachmentFieldPlaceholder = () => {
	const { formatMessage } = useIntl();
	return (
		<AttachmentWrapper>
			<Inline space="space.100" alignBlock="center">
				<UploadIcon spacing="spacious" label="upload" />
				<Text
					as="span"
					color={fg('jira-forms-public-access') ? 'color.text.disabled' : 'color.text.subtlest'}
				>
					{formatMessage(messages.attachmentFieldPlaceholder)}
				</Text>
			</Inline>
		</AttachmentWrapper>
	);
};

export const AssigneeFieldPlaceholder = () => {
	const { formatMessage } = useIntl();
	return (
		<TextFieldWrapper>
			<Flex xcss={fieldHeight} alignItems="center">
				<Inline space="space.100" alignBlock="center">
					<UserAvatarCircleIcon spacing="spacious" label="avatar" />
					<Text
						as="span"
						color={fg('jira-forms-public-access') ? 'color.text.disabled' : 'color.text.subtlest'}
					>
						{formatMessage(messages.assigneeFieldPlaceholder)}
					</Text>
				</Inline>
			</Flex>
		</TextFieldWrapper>
	);
};

export const DropdownFieldPlaceholder = () => {
	const { formatMessage } = useIntl();
	return (
		<WithIconAfterFieldWrapper>
			<Flex xcss={fieldHeight} alignItems="center">
				<Text
					as="span"
					color={fg('jira-forms-public-access') ? 'color.text.disabled' : 'color.text.subtlest'}
				>
					{formatMessage(messages.dropdownFieldPlaceholder)}
				</Text>
			</Flex>
			<ChevronDownIcon spacing="spacious" label="openDropdown" />
		</WithIconAfterFieldWrapper>
	);
};

export const DateFieldPlaceholder = () => {
	const { formatMessage } = useIntl();
	return (
		<WithIconAfterFieldWrapper>
			<Flex xcss={fieldHeight} alignItems="center">
				<Text
					as="span"
					color={fg('jira-forms-public-access') ? 'color.text.disabled' : 'color.text.subtlest'}
				>
					{formatMessage(messages.dateFieldPlaceholder)}
				</Text>
			</Flex>
			<CalendarIcon spacing="spacious" label="calendar" />
		</WithIconAfterFieldWrapper>
	);
};

export const DateTimeFieldPlaceholder = () => {
	const { formatMessage } = useIntl();
	return (
		<SplitHalfFieldWrapper>
			<Inline alignBlock="center" xcss={fieldHeight}>
				<Text
					as="span"
					color={fg('jira-forms-public-access') ? 'color.text.disabled' : 'color.text.subtlest'}
				>
					{formatMessage(messages.dateFieldPlaceholder)}
				</Text>
			</Inline>
			<Inline alignBlock="center" xcss={fieldHeight}>
				<Text
					as="span"
					color={fg('jira-forms-public-access') ? 'color.text.disabled' : 'color.text.subtlest'}
				>
					{formatMessage(messages.timeFieldPlaceholder)}
				</Text>
			</Inline>
		</SplitHalfFieldWrapper>
	);
};

type NotSupportedFieldPlaceholderProps =
	| {
			isRequired?: false;
			onRemoveFieldClick?: never;
	  }
	| {
			isRequired: boolean;
			onRemoveFieldClick: () => void;
	  };

export const NotSupportedFieldPlaceholder = ({
	isRequired,
	onRemoveFieldClick,
}: NotSupportedFieldPlaceholderProps) => {
	const { formatMessage } = useIntl();

	return (
		<NotSupportedFieldWrapper>
			<Inline space="space.100" alignBlock="center">
				<ErrorIcon spacing="spacious" label="error" />
				<Text
					as="span"
					color={fg('jira-forms-public-access') ? 'color.text.disabled' : 'color.text.subtlest'}
				>
					{isRequired ? (
						<FormattedMessage
							{...messages.notSupportedRequiredFieldPlaceholder}
							values={{
								contactAdmin: (
									<a href="/secure/ContactAdministrators!default.jspa">
										{formatMessage(messages.contactAdmin)}
									</a>
								),
							}}
						/>
					) : (
						<FormattedMessage
							{...messages.notSupportedOptionalFieldPlaceholder}
							values={{
								clickHere: (
									<ButtonWrapper>
										<Button appearance="link" onClick={onRemoveFieldClick}>
											{formatMessage(messages.clickHere)}
										</Button>
									</ButtonWrapper>
								),
							}}
						/>
					)}
				</Text>
			</Inline>
		</NotSupportedFieldWrapper>
	);
};

const styles = cssMap({
	wrapperFlex: {
		backgroundColor: token('color.background.disabled'),
		borderRadius: token('border.radius.100'),
		paddingTop: token('space.100'),
		paddingBottom: token('space.100'),
		paddingLeft: token('space.100'),
		paddingRight: token('space.100'),
		borderWidth: token('border.width'),
		borderStyle: 'solid',
		borderColor: token('color.border.disabled'),
	},
});

type RestrictedFieldPlaceholderProps = {
	isAttachmentField?: boolean;
	onRemoveFieldClick: () => void;
};

export const RestrictedFieldPlaceholder = ({
	isAttachmentField,
	onRemoveFieldClick,
}: RestrictedFieldPlaceholderProps) => {
	const { formatMessage } = useIntl();
	return (
		<FlexCompiled
			alignItems="center"
			justifyContent="space-between"
			gap="space.300"
			xcss={styles.wrapperFlex}
		>
			<Text maxLines={2} color="color.text.disabled">
				{isAttachmentField
					? formatMessage(messages.restrictedAttachmentsFieldPlaceholder)
					: formatMessage(messages.restrictedFieldPlaceholder)}
			</Text>
			<Tooltip content="Remove field">
				<Button onClick={() => onRemoveFieldClick()}>
					{formatMessage(messages.restrictedFieldPlaceholderRemoveButton)}
				</Button>
			</Tooltip>
		</FlexCompiled>
	);
};

const fieldHeight = xcss({
	height: '2.25rem',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldBaseOld = styled.div({
	borderRadius: '4px',
	borderWidth: '1px',
	borderStyle: 'solid',
	borderColor: `${token('color.border.input')}`,
	backgroundColor: token('color.background.disabled'),
	color: token('color.text.subtlest'),
	paddingTop: 0,
	paddingRight: token('space.100'),
	paddingBottom: 0,
	paddingLeft: token('space.100'),
	font: token('font.body'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldBaseNew = styled.div({
	borderRadius: '4px',
	borderWidth: '1px',
	borderStyle: 'solid',
	borderColor: token('color.border.disabled'),
	backgroundColor: token('color.background.disabled'),
	color: token('color.text.disabled'),
	paddingTop: 0,
	paddingRight: token('space.100'),
	paddingBottom: 0,
	paddingLeft: token('space.100'),
	font: token('font.body'),
});

const FieldBase = componentWithFG('jira-forms-public-access', FieldBaseNew, FieldBaseOld);

/**
 * Expect icon in the content as the first element
 * Vertical align the icon and gives space between icon and the content
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconBeforeBase = styled.div({
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> * + *': {
		marginLeft: token('space.100'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldBaseWithIconBefore = styled(FieldBase)({
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> * + *': {
		marginLeft: token('space.100'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TextFieldWrapper = styled(FieldBaseWithIconBefore)({
	height: '36px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DescriptionWrapper = styled(FieldBase)({
	height: '56px',
	paddingTop: token('space.100'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AttachmentWrapper = styled(FieldBaseWithIconBefore)({
	height: '36px',
	backgroundColor: 'transparent',
	borderStyle: 'dashed',
	justifyContent: 'center',
});

/**
 * Expect an icon as the last child, so it will be placed in the very right
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const WithIconAfterFieldWrapper = styled(FieldBase)({
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'> :last-child': {
		marginLeft: 'auto',
	},
});

/**
 * Expect the field contains 2 item where both will take 50% of the width
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SplitHalfFieldWrapper = styled(FieldBase)({
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> *': {
		flexGrow: 1,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NotSupportedFieldWrapper = styled(IconBeforeBase)({
	font: token('font.body'),
	color: token('color.text.subtlest'),
	width: '100%',
	justifyContent: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonWrapper = styled.span({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> button': {
		font: token('font.body'),
		padding: 0,
		height: '22px',
	},
});

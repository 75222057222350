import React, { useMemo, useState, useEffect, type ReactNode } from 'react';
import client from '@atlassian/jira-apollo-gira/src/index.tsx';
import { ApolloClientProvider } from '@atlassian/jira-apollo-multiple-clients/src/main.tsx';
import {
	ExperienceFailed,
	ExperienceErrorBoundary,
} from '@atlassian/jira-business-experience-tracking/src/controllers/experience-tracker/index.tsx';
import { CORE_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { ContextualAnalyticsData, SCREEN } from '@atlassian/jira-product-analytics-bridge';
import { ThemedLazySkeleton } from '@atlassian/jira-project-theme-components/src/ui/ThemedLazySkeleton.tsx';
import { useProject_DEPRECATED_DO_NOT_USE } from '@atlassian/jira-router-resources-business-project-details/src/index.tsx';
import { VIEW_FORM_SUBMISSION_EXPERIENCE } from '../../../common/constants.tsx';
import { FormAccessLevelTypes } from '../../../common/types.tsx';
import { FormUnavailableView } from '../../../common/ui/empty-views/index.tsx';
import { useConditionalFieldLogicAnalyticsAttrs } from '../../../common/utils/gates/conditional-field-logic-gate/index.tsx';
import { FormSubmissionPreloader } from '../../../controllers/form-submission/index.tsx';
import { useFormSubmissionStore } from '../../../controllers/form-submission/store/index.tsx';
import { isFormNotFoundError, isProjectNotFoundError } from '../../../services/get-form/utils.tsx';
import { usePublicFormsEnabledAnalyticsAttrs } from '../../../common/utils/gates/public-forms-gate/index.tsx';

type Props = {
	children: ReactNode;
};

const FormAccessWrapper = ({ children }: Props) => {
	const [initialLoading, setInitialLoading] = useState(true);
	const { data: project, loading: projectLoading } = useProject_DEPRECATED_DO_NOT_USE();

	const [{ data, loading, error }] = useFormSubmissionStore();

	useEffect(() => {
		if (loading) {
			setInitialLoading(false);
		}
	}, [loading]);

	const isOpenForm = data?.accessLevel === FormAccessLevelTypes.OPEN;
	const projectId = String(data?.projectId);
	const analyticsAttributes = useMemo(
		() => ({ projectId, isOpenForm, projectType: CORE_PROJECT }),
		[projectId, isOpenForm],
	);
	const experienceTrackerAttributes = {
		...useConditionalFieldLogicAnalyticsAttrs(),
		...usePublicFormsEnabledAnalyticsAttrs(),
	};

	if (initialLoading || projectLoading || loading) {
		return <ThemedLazySkeleton />;
	}

	if (error) {
		return (
			<>
				{(!isFormNotFoundError(error) || !isProjectNotFoundError(error)) && (
					<ExperienceFailed
						experience={VIEW_FORM_SUBMISSION_EXPERIENCE}
						error={error}
						attributes={experienceTrackerAttributes}
					/>
				)}
				<FormUnavailableView />
			</>
		);
	}

	if (
		(!project || !project.permissions.viewIssues || !project.permissions.createIssues) &&
		isOpenForm
	) {
		return (
			<ContextualAnalyticsData
				containerId={projectId}
				containerType="project"
				sourceType={SCREEN}
				sourceName="businessFormSubmissionContext"
				attributes={analyticsAttributes}
			>
				{children}
			</ContextualAnalyticsData>
		);
	}

	return children;
};

export const FormSubmissionContextProvider = ({ children }: Props) => (
	<ExperienceErrorBoundary experience={VIEW_FORM_SUBMISSION_EXPERIENCE} shouldStartExperience>
		<ApolloClientProvider client={client}>
			<FormSubmissionPreloader />
			<FormAccessWrapper>{children}</FormAccessWrapper>
		</ApolloClientProvider>
	</ExperienceErrorBoundary>
);

/** @jsx jsx */
import React, { useEffect, type PropsWithChildren, type ReactNode } from 'react';
import { jsx } from '@compiled/react';
import { Box, xcss } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import { useSidebarQueryParams } from '../../../controllers/query-params/index.tsx';
import { useResizer } from '../../../controllers/resizer/index.tsx';
import { useSidebar } from '../../../controllers/sidebar/index.tsx';
import { Wrapper, SidebarContent, ResizeHandle } from './styled.tsx';

export type SidebarTheme = 'business' | 'software';

type SidebarProps = { sidebarWidth: number; theme: SidebarTheme };

const Sidebar = ({ children, sidebarWidth, theme }: PropsWithChildren<SidebarProps>) => {
	return (
		<Box
			xcss={[
				sidebarStyles,
				/* styles now for all project theming. remove || 'business' condition on clean up - jira_theming_milestone_1_fg */
				(theme === 'business' || (theme && fg('jira_theming_milestone_1_fg'))) &&
					businessSidebarStyles,
			]}
			// eslint-disable-next-line jira/react/no-style-attribute
			style={{ width: sidebarWidth }}
		>
			{children}
		</Box>
	);
};

type Props = {
	children: ReactNode;
	content?: ReactNode | null;
	theme?: SidebarTheme;
};

export const SidebarLayout = ({ children, theme = 'business' }: Props) => {
	useSidebarQueryParams();
	const [{ content, sidebarWidth }, { setSidebarContent }] = useSidebar();
	const { onMouseDown, isResizing } = useResizer();

	// reset sidebar content on view unmount
	useEffect(
		() => () => {
			setSidebarContent(null);
		},
		[setSidebarContent],
	);

	return (
		<Wrapper
			isResizing={isResizing}
			data-testid="business-app-wrapper.ui.app-wrapper.sidebar-layout.sidebar-layout-wrapper"
		>
			{children}
			{content && (
				<Sidebar sidebarWidth={sidebarWidth} theme={theme}>
					<ResizeHandle onMouseDown={onMouseDown} />
					<SidebarContent>{content}</SidebarContent>
				</Sidebar>
			)}
		</Wrapper>
	);
};

const sidebarStyles = xcss({
	backgroundColor: 'elevation.surface',
	flexGrow: 0,
	flexShrink: 0,
	position: 'relative',
	zIndex: 'card',
	maxWidth: '75%',
});

const businessSidebarStyles = xcss({
	borderTopLeftRadius: 'border.radius.200',
	borderTopRightRadius: 'border.radius.200',
	boxShadow: 'elevation.shadow.overlay',
	marginRight: 'space.300',
	marginTop: 'space.300',
});

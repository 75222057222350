export const TOGGLE_WATCHING_ERROR = 'TOGGLE_WATCHING_ERROR' as const;
export const COMMENT_ADD_ERROR_GENERIC = 'COMMENT_ADD_ERROR_GENERIC' as const;
export const COMMENT_ADD_ERROR_INVALID = 'COMMENT_ADD_ERROR_INVALID' as const;
export const COMMENT_ACTION_ERROR_INVALID_PERMISSION =
	'COMMENT_ACTION_ERROR_INVALID_PERMISSION' as const;
export const COMMENT_CONTENT_EXCEEDED_ERROR = 'COMMENT_CONTENT_EXCEEDED_ERROR' as const;

export const SAVE_COMMENT_SUCCESS_FLAG_MESSAGE = 'SAVE_COMMENT_SUCCESS_FLAG_MESSAGE' as const;

export const COMMENT_FUTURE_TIME_ERROR = 'COMMENT_FUTURE_TIME_ERROR' as const;
export const COMMENT_DELETE_ERROR = 'COMMENT_DELETE_ERROR' as const;
export const EDITOR_ERROR = 'EDITOR_ERROR' as const;
export const ATTACHMENT_ADD_ERROR = 'ATTACHMENT_ADD_ERROR' as const;
export const ATTACHMENT_ADD_EXCEED_SIZE_LIMIT_ERROR =
	'ATTACHMENT_ADD_EXCEED_SIZE_LIMIT_ERROR' as const;
export const ATTACHMENT_ADD_SUCCESS = 'ATTACHMENT_ADD_SUCCESS' as const;
export const DELETE_LINKED_ISSUE_ERROR = 'DELETE_LINKED_ISSUE_ERROR' as const;
export const REMOTE_LINK_VALIDATION_ERROR = 'REMOTE_LINK_VALIDATION_ERROR' as const;
export const ATTACHMENT_DELETE_ERROR = 'ATTACHMENT_DELETE_ERROR' as const;
export const WATCH_TOGGLE_UPDATE_ERROR = 'WATCH_TOGGLE_UPDATE_ERROR' as const;
export const FETCH_WATCHERS_ERROR = 'FETCH_WATCHERS_ERROR' as const;
export const STOP_WATCHING_SUCCESS = 'STOP_WATCHING_SUCCESS' as const;
export const START_WATCHING_SUCCESS = 'START_WATCHING_SUCCESS' as const;
export const REMOVE_WATCHER_ERROR = 'REMOVE_WATCHER_ERROR' as const;
export const ADD_WATCHER_ERROR = 'ADD_WATCHER_ERROR' as const;
export const SUBTASKS_REORDER_ERROR_GENERIC = 'SUBTASKS_REORDER_ERROR_GENERIC' as const;
export const LOG_WORK_CONFIRM_ERROR = 'LOG_WORK_CONFIRM_ERROR' as const;
export const ASSIGN_TO_ME_SUCCESS = 'ASSIGN_TO_ME_SUCCESS' as const;
export const REMOVE_FROM_ME_SUCCESS = 'REMOVE_FROM_ME_SUCCESS' as const;
export const CREATE_CHILD_ISSUE_ERROR = 'CREATE_CHILD_ISSUE_ERROR' as const;
export const LINK_CHILD_ISSUE_ERROR = 'LINK_CHILD_ISSUE_ERROR' as const;
export const CHANGE_ISSUE_TYPE_SUCCESS = 'CHANGE_ISSUE_TYPE_SUCCESS' as const;
export const CHANGE_ISSUE_TYPE_ERROR = 'CHANGE_ISSUE_TYPE_ERROR' as const;
export const DELETE_ISSUE_ERROR = 'DELETE_ISSUE_ERROR' as const;
export const ADD_WORKLOG_SUCCESS = 'ADD_WORKLOG_SUCCESS' as const;
export const ADD_WORKLOG_FAILURE = 'ADD_WORKLOG_FAILURE' as const;
export const WORKLOGS_PER_ISSUE_LIMIT_EXCEEDED = 'WORKLOGS_PER_ISSUE_LIMIT_EXCEEDED' as const;
export const UPDATE_TIME_REMAINING_SUCCESS = 'UPDATE_TIME_REMAINING_SUCCESS' as const;
export const UPDATE_TIME_REMAINING_FAILURE = 'UPDATE_TIME_REMAINING_FAILURE' as const;
export const EDIT_WORKLOG_SUCCESS = 'EDIT_WORKLOG_SUCCESS' as const;
export const EDIT_WORKLOG_FAILURE = 'EDIT_WORKLOG_FAILURE' as const;
export const DELETE_WORKLOG_SUCCESS = 'DELETE_WORKLOG_SUCCESS' as const;
export const DELETE_WORKLOG_FAILURE = 'DELETE_WORKLOG_FAILURE' as const;
export const RICH_CONTENT_FIELD_CONFIRM_ERROR = 'RICH_CONTENT_FIELD_CONFIRM_ERROR' as const;
export const RICH_CONTENT_FIELD_MAXIMUM_LENGTH_EXCEEDED_ERROR =
	'RICH_CONTENT_FIELD_MAXIMUM_LENGTH_EXCEEDED_ERROR' as const;
export const RICH_CONTENT_FIELD_REQUIRED_ERROR = 'RICH_CONTENT_FIELD_REQUIRED_ERROR' as const;
export const DELETE_CONFLUENCE_PAGE_LINK_FAILED = 'DELETE_CONFLUENCE_PAGE_LINK_FAILED' as const;
export const DELETE_WEB_LINK_FAILED = 'DELETE_WEB_LINK_FAILED' as const;
export const CLICKTHROUGH_BANNER_DISMISS_SUCCESS = 'CLICKTHROUGH_BANNER_DISMISS_SUCCESS' as const;
export const SAVE_APPROVAL_ANSWER_ERROR = 'SAVE_APPROVAL_ANSWER_ERROR' as const;
export const SAVE_APPROVAL_ANSWER_SUCCESS = 'SAVE_APPROVAL_ANSWER_SUCCESS' as const;
export const EDIT_EPIC_COLOUR_ERROR = 'EDIT_EPIC_COLOUR_ERROR' as const;
export const EDIT_ISSUE_COLOUR_ERROR = 'EDIT_ISSUE_COLOUR_ERROR' as const;
export const FETCH_JIRA_APP_LINKS_ERROR = 'FETCH_JIRA_APP_LINKS_ERROR' as const;
export const CONFLUENCE_LINKS_PER_ISSUE_LIMIT_EXCEEDED =
	'CONFLUENCE_LINKS_PER_ISSUE_LIMIT_EXCEEDED' as const;
export const COMMENTS_PER_ISSUE_LIMIT_EXCEEDED = 'COMMENTS_PER_ISSUE_LIMIT_EXCEEDED' as const;

export const COMMENTS_INTERNAL_SERVER_ERROR = 'COMMENTS_INTERNAL_SERVER_ERROR' as const;
export const ISSUE_LINKS_PER_ISSUE_LIMIT_EXCEEDED = 'ISSUE_LINKS_PER_ISSUE_LIMIT_EXCEEDED' as const;
export const ISSUE_REMOTE_LINKS_PER_ISSUE_LIMIT_EXCEEDED =
	'ISSUE_REMOTE_LINKS_PER_ISSUE_LIMIT_EXCEEDED' as const;
export const ISSUE_ATTACHMENTS_PER_ISSUE_LIMIT_EXCEEDED =
	'ISSUE_ATTACHMENTS_PER_ISSUE_LIMIT_EXCEEDED' as const;

// this is a list of the different issue limit fields that could potentially happen on a comment save
// If users are under the comment limit, but over the attachment limit and they try an attachment we will trigger
// an exceeds limit error.
export const COMMENTS_LIMIT_EXCEEDED_FIELDS_LIST = [
	COMMENTS_PER_ISSUE_LIMIT_EXCEEDED,
	ISSUE_ATTACHMENTS_PER_ISSUE_LIMIT_EXCEEDED,
];

export type FlagFailureConstant =
	| typeof COMMENT_ADD_ERROR_GENERIC
	| typeof COMMENT_ADD_ERROR_INVALID
	| typeof COMMENT_CONTENT_EXCEEDED_ERROR
	| typeof COMMENTS_INTERNAL_SERVER_ERROR
	| typeof SAVE_COMMENT_SUCCESS_FLAG_MESSAGE
	| typeof COMMENT_DELETE_ERROR
	| typeof EDITOR_ERROR
	| typeof ATTACHMENT_ADD_ERROR
	| typeof ATTACHMENT_ADD_EXCEED_SIZE_LIMIT_ERROR
	| typeof DELETE_LINKED_ISSUE_ERROR
	| typeof ATTACHMENT_DELETE_ERROR
	| typeof WATCH_TOGGLE_UPDATE_ERROR
	| typeof SUBTASKS_REORDER_ERROR_GENERIC
	| typeof LOG_WORK_CONFIRM_ERROR
	| typeof CREATE_CHILD_ISSUE_ERROR
	| typeof LINK_CHILD_ISSUE_ERROR
	| typeof CHANGE_ISSUE_TYPE_SUCCESS
	| typeof CHANGE_ISSUE_TYPE_ERROR
	| typeof DELETE_ISSUE_ERROR
	| typeof RICH_CONTENT_FIELD_CONFIRM_ERROR
	| typeof RICH_CONTENT_FIELD_MAXIMUM_LENGTH_EXCEEDED_ERROR
	| typeof RICH_CONTENT_FIELD_REQUIRED_ERROR
	| typeof FETCH_WATCHERS_ERROR
	| typeof STOP_WATCHING_SUCCESS
	| typeof START_WATCHING_SUCCESS
	| typeof REMOVE_WATCHER_ERROR
	| typeof ADD_WATCHER_ERROR
	| typeof ADD_WORKLOG_FAILURE
	| typeof WORKLOGS_PER_ISSUE_LIMIT_EXCEEDED
	| typeof UPDATE_TIME_REMAINING_FAILURE
	| typeof EDIT_WORKLOG_FAILURE
	| typeof DELETE_WORKLOG_FAILURE
	| typeof DELETE_CONFLUENCE_PAGE_LINK_FAILED
	| typeof DELETE_WEB_LINK_FAILED
	| typeof SAVE_APPROVAL_ANSWER_ERROR
	| typeof EDIT_EPIC_COLOUR_ERROR
	| typeof EDIT_ISSUE_COLOUR_ERROR
	| typeof FETCH_JIRA_APP_LINKS_ERROR
	| typeof REMOTE_LINK_VALIDATION_ERROR
	| typeof COMMENT_FUTURE_TIME_ERROR
	| typeof COMMENTS_PER_ISSUE_LIMIT_EXCEEDED
	| typeof ISSUE_LINKS_PER_ISSUE_LIMIT_EXCEEDED
	| typeof ISSUE_REMOTE_LINKS_PER_ISSUE_LIMIT_EXCEEDED
	| typeof CONFLUENCE_LINKS_PER_ISSUE_LIMIT_EXCEEDED
	| typeof ISSUE_ATTACHMENTS_PER_ISSUE_LIMIT_EXCEEDED
	| typeof COMMENT_ACTION_ERROR_INVALID_PERMISSION;

export type FlagSuccessConstant =
	| typeof ASSIGN_TO_ME_SUCCESS
	| typeof REMOVE_FROM_ME_SUCCESS
	| typeof ADD_WORKLOG_SUCCESS
	| typeof UPDATE_TIME_REMAINING_SUCCESS
	| typeof EDIT_WORKLOG_SUCCESS
	| typeof DELETE_WORKLOG_SUCCESS
	| typeof CLICKTHROUGH_BANNER_DISMISS_SUCCESS
	| typeof SAVE_APPROVAL_ANSWER_SUCCESS
	| typeof ATTACHMENT_ADD_SUCCESS;

export type FlagConstant = FlagSuccessConstant | FlagFailureConstant;

export type WatcherFlagType =
	| typeof TOGGLE_WATCHING_ERROR
	| typeof FETCH_WATCHERS_ERROR
	| typeof START_WATCHING_SUCCESS
	| typeof STOP_WATCHING_SUCCESS
	| typeof REMOVE_WATCHER_ERROR
	| typeof ADD_WATCHER_ERROR;

import React, { useCallback, useMemo } from 'react';
import { useFlagsService } from '@atlassian/jira-flags';
import {
	CONTEXT_TOKEN_TYPE_JIRA,
	CUSTOM_FIELD_MODULE,
	ENTRY_POINT_EDIT,
} from '@atlassian/jira-forge-ui-constants/src/constants.tsx';
import type { ForgeCustomFieldValue } from '@atlassian/jira-forge-ui-types/src/common/types/contexts/custom-field.tsx';
import type { LazyCustomFieldInlineEditIssueViewProps } from '@atlassian/jira-forge-ui-types/src/common/types/custom-field.tsx';
import {
	isCustomUIExtension,
	isNativeUiExtension,
} from '@atlassian/jira-forge-ui-utils-internal/src/utils/extension/index.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { Skeleton } from '../../../../../common/ui/skeleton/main.tsx';
import { StopSubmitPropagation } from '../../../../../common/ui/submit-stop-propogation-wrapper/index.tsx';
import { onCustomFieldSubmit } from '../../../../../common/utils/on-custom-field-submit/index.tsx';
import {
	LazyCustomFieldInlineEditExtension,
	LazyCustomFieldInlineEditRenderer,
} from '../../main.tsx';

export type Bridge = {
	submit: (opts: { data: ForgeCustomFieldValue }) => Promise<void>;
};

const skeletonConfig = [{ width: 100 }];

export const LazyCustomFieldInlineEditIssueView = ({
	onSave,
	onCancel,
	onConfirm,
	onRender,
	...restProps
}: LazyCustomFieldInlineEditIssueViewProps) => {
	const { extension, extensionData, extensionPayload } = restProps;
	const { showFlag } = useFlagsService();

	const loadingComponent = useMemo(() => <Skeleton skeletonConfig={skeletonConfig} />, []);

	const extensionDataValue = useMemo(() => {
		const preparedExtensionData =
			extensionData?.issue && extensionData?.project
				? extensionData
				: {
						fieldType: extensionData.fieldType,
						fieldId: extensionData.fieldId,
						fieldName: extensionData.fieldName,
						renderContext: extensionData.renderContext,
						...(fg('ditto-fcf-support-new-manifest-on-frontend') && {
							experience: extensionData.experience,
						}),
					};

		return {
			...preparedExtensionData,
			type: extension.type,
			entryPoint: ENTRY_POINT_EDIT,
			isInline: true,
		};
	}, [extension.type, extensionData]);

	const extraProps = useMemo(
		() => ({
			loadingComponent,
			module: CUSTOM_FIELD_MODULE,
			entryPoint: ENTRY_POINT_EDIT,
			extensionData: extensionDataValue,
			extensionPayload,
			contextToken: CONTEXT_TOKEN_TYPE_JIRA,
		}),
		[extensionDataValue, extensionPayload, loadingComponent],
	);

	const submit = useCallback(
		({ data: fieldValue }: { data: ForgeCustomFieldValue }): Promise<void> =>
			onCustomFieldSubmit(fieldValue, onSave, onConfirm, showFlag),
		// todo SMT-491 onConfirm, onSave (fieldId) was changing every time component tree was changed by Bento issue view.
		// root cause - Bento's connect-field is creating onConfirm function and fieldId every time. More in mentioned ticket
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[showFlag],
	);
	const close = onCancel;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const bridge: any = useMemo(() => ({ submit, close }), [submit, close]);

	if (
		isNativeUiExtension(extension, ENTRY_POINT_EDIT) ||
		isCustomUIExtension(extension, ENTRY_POINT_EDIT)
	) {
		return (
			<StopSubmitPropagation>
				<Placeholder name="custom-field-edit" fallback={null}>
					<LazyCustomFieldInlineEditExtension
						{...restProps}
						{...extraProps}
						onCancel={onCancel}
						onLoad={onRender}
						onRender={onRender}
						bridge={bridge}
					/>
				</Placeholder>
			</StopSubmitPropagation>
		);
	}

	return (
		<StopSubmitPropagation>
			<Placeholder name="custom-field-edit" fallback={null}>
				<LazyCustomFieldInlineEditRenderer
					{...restProps}
					{...extraProps}
					onRender={onRender}
					onCancel={onCancel}
					bridge={bridge}
				/>
			</Placeholder>
		</StopSubmitPropagation>
	);
};

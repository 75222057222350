import React, { useCallback } from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import type { AnalyticsAttributes } from '@atlassian/jira-product-analytics-bridge';
import { getIssueErrorAttributes } from '@atlassian/jira-issue-view-errors/src/common/utils/index.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { getTenantAttributes } from '../controllers/get-tenant-attributes/index.tsx';
import type { Props } from './types.tsx';

export const IssueCommonErrorBoundary = ({
	children,
	fallback,
	fireErrorEventWhenNetworkError,
	getErrorAttributes,
	attributes,
	...otherProps
}: Props) => {
	// TODO: understand what more common attributes can be common in all of issue domain
	// TODO: understand what is more common for issue view and create and transition.
	const accountIdLoggedInUser = useAccountId();
	const getAttributes = useCallback(
		(error: Error): AnalyticsAttributes => {
			const attributesPassed = typeof attributes === 'function' ? attributes(error) : attributes;
			const tenantAttributes = getTenantAttributes();
			const { errorAttributes } = getIssueErrorAttributes(error, accountIdLoggedInUser);

			return {
				...attributesPassed,
				...tenantAttributes,
				...errorAttributes,
			};
		},
		[attributes, accountIdLoggedInUser],
	);
	const isFallbackString = typeof fallback === 'string';

	const fallbackMethod = useCallback(
		(errorProps: { error: Error }) => {
			const { errorAttributes } = getIssueErrorAttributes(errorProps.error, accountIdLoggedInUser);

			if (
				errorAttributes.statusCode &&
				errorAttributes.statusCode >= 400 &&
				errorAttributes.statusCode < 500
			) {
				fireErrorAnalytics({
					error: errorProps.error,
					meta: {
						id: 'issue.issue-view.viewIssue',
						teamName: 'Bento',
					},
					attributes: {
						...attributes,
						...getErrorAttributes?.(errorProps.error),
						statusCode: errorAttributes.statusCode,
						traceId: errorAttributes.traceId || undefined,
					},
				});
				return !isFallbackString && fallback?.(errorProps);
			}
		},
		[accountIdLoggedInUser, attributes, fallback, getErrorAttributes, isFallbackString],
	);
	const fallbackAbstracted =
		fireErrorEventWhenNetworkError && !isFallbackString ? fallbackMethod : fallback;
	return (
		<JSErrorBoundary {...otherProps} attributes={getAttributes} fallback={fallbackAbstracted}>
			{children}
		</JSErrorBoundary>
	);
};

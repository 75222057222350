// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { IDLE_FIELD_STATE, FOCUSED_FIELD_STATE } from '../constants.tsx';
import type { InlineFieldData, InlineFieldsState } from '../types.tsx';
import { dispatchFormSubmitAndBlurElements } from './utils/dispatch-form-submit-and-blur-elements/index.tsx';

export const setFieldState =
	(data: InlineFieldData): Action<InlineFieldsState> =>
	({ setState, getState }) => {
		const currentState = getState();
		const stateObject = {
			status: data.status !== undefined ? data.status : currentState[data.fieldId]?.status,
			formRef: data.formRef !== undefined ? data.formRef : currentState[data.fieldId]?.formRef,
			retryFunction:
				data.retryFunction !== undefined
					? data.retryFunction
					: currentState[data.fieldId]?.retryFunction,
		};
		setState({ ...getState(), [data.fieldId]: stateObject });
	};

export const resetState =
	(): Action<InlineFieldsState> =>
	({ getState, setState }) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const stateToOverride: Record<string, any> = {};
		Object.getOwnPropertyNames(getState()).forEach((fieldId) => {
			stateToOverride[fieldId] = { status: IDLE_FIELD_STATE };
		});
		setState(stateToOverride);
	};

export const dispatchRetryFunction =
	(fieldId: string): Action<InlineFieldsState> =>
	async ({ getState }) => {
		const currentState = getState();
		const fieldData = currentState[fieldId];
		const retryFunction = fieldData?.retryFunction;

		retryFunction && retryFunction();
	};

export const dispatchFormSubmits =
	(): Action<InlineFieldsState> =>
	async ({ getState }) => {
		const currentState = getState();
		Object.keys(currentState).forEach((fieldId) => {
			if (currentState[fieldId]?.status === FOCUSED_FIELD_STATE) {
				const formRef = currentState[fieldId]?.formRef;
				dispatchFormSubmitAndBlurElements(formRef);
			}
		});
	};

import React from 'react';
import { styled } from '@compiled/react';
import TickIcon from '@atlaskit/icon/core/migration/check-mark--editor-done';

import { token } from '@atlaskit/tokens';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { singleLineClamp } from '@atlassian/jira-common-styles/src/mixins.tsx';
import { useIntl } from '@atlassian/jira-intl';
import type { ParentFieldOption, OldOption } from '../../../common/types.tsx';
import { getOptionColorElem } from '../../../common/utils.tsx';
import messages from './messages.tsx';

const renderOptionDescription = (option: OldOption) => (
	<OptionDescriptionWrapper>
		{option.description != null ? <Description>{option.description}</Description> : null}
	</OptionDescriptionWrapper>
);

const renderIconsKeyAndDoneLabel = (
	option: ParentFieldOption,
	doneParentLabel: string,
	iconAltMessage: string,
) => (
	<OptionColorTypeKeyWrapper>
		<IconColorKeyWrapper>
			{option?.color ? (
				<>
					{getOptionColorElem(option?.color)}
					<IconWithColor src={option.fields?.issuetype.iconUrl} alt={iconAltMessage} />
				</>
			) : (
				<Icon src={option.fields?.issuetype.iconUrl} alt={iconAltMessage} />
			)}
			<IssueKey>{option.key}</IssueKey>
		</IconColorKeyWrapper>
		{option.isDone ? (
			<DoneWrapper>
				<TickIcon
					spacing="spacious"
					color={token('color.text.success')}
					label="done-parent-issue"
				/>
				{doneParentLabel}
			</DoneWrapper>
		) : null}
	</OptionColorTypeKeyWrapper>
);

const CustomOption = ({ option }: { option: ParentFieldOption | OldOption }) => {
	const { formatMessage } = useIntl();
	// narrowing type to ParentFieldOption
	if ('fields' in option) {
		const fields = option?.fields;
		return (
			<OptionWrapper>
				<OptionTextContainer>
					<OptionLabel>
						{renderIconsKeyAndDoneLabel(
							option,
							formatMessage(messages.doneParentLabel),
							formatMessage(messages.icon, {
								issueType: fields?.issuetype.name || '',
							}),
						)}
						{option.fields.summary}
					</OptionLabel>
				</OptionTextContainer>
			</OptionWrapper>
		);
	}
	return (
		<OptionWrapper>
			{option.elemBefore != null ? option.elemBefore : null}
			<OptionTextContainer>
				<OptionLabel>{option.label}</OptionLabel>
				{renderOptionDescription(option)}
			</OptionTextContainer>
		</OptionWrapper>
	);
};

export default CustomOption;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconWithColor = styled.img({
	marginLeft: token('space.100'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxWidth: `${gridSize * 2}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Icon = styled.img({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxWidth: `${gridSize * 2}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconColorKeyWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtle'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DoneWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IssueKey = styled.span({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	maxWidth: '100%',
	marginLeft: token('space.100'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OptionColorTypeKeyWrapper = styled.div({
	font: token('font.body.UNSAFE_small'),
	display: 'flex',
	justifyContent: 'space-between',
	flexDirection: 'row',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest'),
	marginBottom: token('space.050'),
});
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OptionDescriptionWrapper = styled.div({
	font: token('font.body.UNSAFE_small'),
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	marginTop: token('space.050'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const Description = styled.span({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	maxWidth: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
const OptionLabel = styled.span(singleLineClamp, {
	flexGrow: 1,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const OptionTextContainer = styled.span({
	display: 'flex',
	flexDirection: 'column',
	marginTop: 0,
	marginRight: token('space.100'),
	marginBottom: 0,
	marginLeft: token('space.100'),
	paddingTop: token('space.025'),
	paddingRight: 0,
	paddingBottom: token('space.025'),
	paddingLeft: 0,
	overflow: 'hidden',
	flexGrow: 1,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const OptionWrapper = styled.span({
	display: 'flex',
	alignItems: 'center',
});

import gqlTagGira from 'graphql-tag';
import { FIELD_PARENT_CONDITION_FRAGMENT } from '../constants.tsx';

export const JIRA_BUSINESS_FORM_WITH_FIELD_DATA_BY_FORM_UUID = gqlTagGira`
  query JiraBusinessFormWithFieldDataByFormUuid($publicFormUuid: ID!) {
    jiraFormForPublicSubmission(publicFormUuid: $publicFormUuid) {
      formId
      uuid
      projectId
      title
      description
      bannerColor
      enabled
      emailRequired
      fields {
        fieldId
        alias
        description
        isRequired
        parentCondition {
          ...FieldParentConditionFragment
        }
        field {
          __typename
          name
          type
          ... on GenericIssueField {
            fieldConfig {
              isRequired
            }
          }
          ... on JiraIssueFieldConfiguration {
            fieldConfig {
              isRequired
            }
          }
          ... on JiraRichTextField {
            renderer
          }
          ... on JiraSingleSelectField {
            fieldOptions {
              edges {
                node {
                  value
                  optionId
                }
              }
            }
            fieldOption {
              value
              optionId
            }
            fieldConfig {
              isRequired
              __typename
            }
            __typename
          }
          ... on JiraMultipleSelectField {
            fieldOptions {
              edges {
                node {
                  value
                  optionId
                }
              }
            }
            selectedValues {
              edges {
                node {
                  id
                  selectableLabel
                  selectableUrl
                  selectableGroupKey
                }
              }
            }
            fieldConfig {
              isRequired
            }
            __typename
          }
          ... on JiraCascadingSelectField {
            cascadingOption {
              childOptionValue {
                value
                optionId
              }
            }
            cascadingOptions {
              edges {
                node {
                  parentOptionValue {
                    value
                    optionId
                  }
                  childOptionValues {
                    value
                    optionId
                  }
                }
              }
            }
            fieldConfig {
              isRequired
            }
            __typename
          }
          ... on JiraRadioSelectField {
            fieldOptions {
              edges {
                node {
                  value
                  optionId
                }
              }
            }
            selectedOption {
              value
              optionId
            }
          }
          ... on JiraPriorityField {
            priorities {
              edges {
                node {
                  name
                  priorityId
                  iconUrl
                  color
                  sequence
                }
              }
            }
          }
          ... on JiraCheckboxesField {
            fieldOptions {
              edges {
                node {
                  value
                  optionId
                }
              }
            }
          }
          ... on JiraUrlField {
            uri
          }
        }
      }
    }
  }

	${FIELD_PARENT_CONDITION_FRAGMENT}
`;

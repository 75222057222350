import type { ReactNode } from 'react';
import type { Attributes } from '@atlassian/jira-product-analytics-bridge';
import type { ForgeDoc } from '@atlassian/forge-ui-types';
import type { UiModificationsExtension as UiModificationsExtensionCore } from '@atlassian/ui-modifications-core/types';
import type { CustomUIBridge } from './bridge.tsx';
import type { ContextTokenType } from './context-token.tsx';
import type {
	AdminPageModule,
	CustomFieldModule,
	CustomFieldTypeModule,
	DashboardBackgroundScriptModule,
	DashboardGadgetModule,
	EntryPoint,
	ExtensionPointModule,
	GlobalPageModule,
	IssueActionModule,
	IssueActivityModule,
	IssueContextModule,
	IssueGlanceModule,
	IssuePanelModule,
	IssueViewBackgroundScriptModule,
	PersonalSettingsPageModule,
	ProjectPageModule,
	ProjectSettingsPageModule,
	ServiceDeskOrganizationPanelModule,
	ServiceDeskQueuePageModule,
	UiModificationsModule,
	WorkflowConditionModule,
	WorkflowPostFunctionModule,
	WorkflowValidatorModule,
	ServiceDeskAssetsImportTypeModule,
	FullPageModule,
	IssueNavigatorActionModule,
	BacklogActionModule,
	BoardActionModule,
	SprintActionModule,
	CommandModule,
} from './module.tsx';

export type ForgeUIType = 'ui-kit' | 'native-ui' | 'custom-ui';
export type ExtensionEnvironment =
	| 'DEVELOPMENT'
	| 'PRODUCTION'
	| 'STAGING'
	// eslint-disable-next-line @atlassian/relay/no-future-added-value
	| '%future added value';
export type VisibilityControlMechanism =
	| 'AppAccessRules'
	| 'DisplayConditions'
	// eslint-disable-next-line @atlassian/relay/no-future-added-value
	| '%future added value';
export type CapabilitySet = 'capabilityAdvanced' | 'capabilityStandard';

/**
 * TODO: https://ecosystem-platform.atlassian.net/browse/ARKEN-1723
 * Copy paste from relay generated types to avoid circular dependencies.
 * Common issue types are used in the relay resolver and these types are used in the common issue types.
 * Because of that we can't import relay types in here without causing a circular dependency.
 *
 * This is a temporary solution until we can remove the circular dependency.
 * At the moment everything works fine and is type safe, since on input we have relay types and on output this type.
 * But it would be way better if we just import the relay type in here.
 */
export type AggExtension = {
	readonly appVersion: string;
	readonly consentUrl: string | null | undefined;
	readonly egress: ReadonlyArray<
		| {
				readonly addresses: ReadonlyArray<string> | null | undefined;
				readonly category?: string | null | undefined;
				readonly inScopeEUD?: boolean | null | undefined;
				readonly type: string | null | undefined;
		  }
		| null
		| undefined
	>;
	readonly environmentId: string;
	readonly environmentKey: string;
	readonly environmentType: ExtensionEnvironment;
	readonly hiddenBy?: VisibilityControlMechanism | null | undefined;
	readonly id: string;
	readonly installationId: string;
	readonly resourceUploadId?: string;
	readonly license:
		| {
				readonly active: boolean;
				readonly billingPeriod: string | null | undefined;
				readonly capabilitySet: string | null | undefined;
				readonly ccpEntitlementId: string | null | undefined;
				readonly ccpEntitlementSlug: string | null | undefined;
				readonly isEvaluation: boolean | null | undefined;
				readonly subscriptionEndDate: string | null | undefined;
				readonly supportEntitlementNumber: string | null | undefined;
				readonly trialEndDate: string | null | undefined;
				readonly type: string | null | undefined;
		  }
		| null
		| undefined;
	readonly overrides?: Record<string, boolean> | null | undefined;
	readonly installationConfig?:
		| ReadonlyArray<{
				readonly key: string;
				readonly value: boolean;
		  }>
		| null
		| undefined;
	readonly properties: Record<string, unknown>;
	readonly scopes?: ReadonlyArray<string>;
	readonly userAccess?: // TODO: make required after forge_query_include_user_access FG cleanup
	| {
				readonly hasAccess: boolean;
		  }
		| null
		| undefined;
	readonly type: string;
};

type GenericExtension<TModule extends string, TProperties> = Omit<
	AggExtension,
	// Override some fields because we want to have better typing
	'type' | 'properties'
> & {
	type: TModule;
	properties: TProperties;
};

export type ViewportSizeType = 'small' | 'medium' | 'large' | 'xlarge' | 'max';

type CommonProps = {
	key?: string;
	typeId?: string;
	title: string;
	function?: string;
	resource?: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	displayConditions?: Record<any, any>;
	viewportSize?: ViewportSizeType;
	render?: 'native' | 'default';
	resourceUploadId?: string;
};

// modal is the default action type if not specified
export type ActionType = 'modal' | 'dynamic';

export type ActionsProps = {
	key: string;
	title: string;
	tooltip?: string;
	actionType?: ActionType;
	viewportSize?: ViewportSizeType;
};

type CommonActionModuleProps = CommonProps & {
	icon?: string;
	tooltip?: string;
	actionType?: ActionType;
	actions?: ActionsProps[];
};

// Backlog action
export type BacklogAction = GenericExtension<BacklogActionModule, CommonActionModuleProps>;

// Board action
export type BoardAction = GenericExtension<BoardActionModule, CommonActionModuleProps>;

// Sprint action
export type SprintAction = GenericExtension<SprintActionModule, CommonActionModuleProps>;

// Issue glance

type IssueGlanceBadgeStatus = {
	type: 'badge';
	value: {
		label: string;
	};
};

type IssueGlanceLozengeStatus = {
	type: 'lozenge';
	value: {
		type: 'default' | 'inprogress' | 'moved' | 'new' | 'removed' | 'success';
		label: string;
	};
};

type IssueGlanceIconStatus = {
	type: 'icon';
	value: {
		url: string;
		label: string;
	};
};

export type IssueGlanceStatus =
	| IssueGlanceBadgeStatus
	| IssueGlanceLozengeStatus
	| IssueGlanceIconStatus;

type IssueGlanceProps = {
	label: string;
	dynamicProperties?: {
		function: string;
	};
	icon?: string;
	status?: IssueGlanceStatus;
};
// Issue Glance & Issue Context are identical other than where they are located on the page
// Issue Glance appears as a standard glance
export type IssueGlance = GenericExtension<IssueGlanceModule, CommonProps & IssueGlanceProps>;
// Issue context appears in a separate group for each app
export type IssueContext = GenericExtension<IssueContextModule, CommonProps & IssueGlanceProps>;

// Issue action
export type IssueAction = GenericExtension<IssueActionModule, CommonProps>;

// Issue activity
export type IssueActivity = GenericExtension<IssueActivityModule, CommonProps>;

// Command Module
type CommandTarget =
	| // Opens to a pageModule like globalPage, projectPage, projectSettingsPage
	{ page: string }
	// Opens a resource in a modal or as a background task
	| { resource: string };

type CommandProps = {
	icon: string;
	keywords?: string[];
	shortcut: string;
	target: CommandTarget;
};
export type Command = GenericExtension<CommandModule, CommonProps & CommandProps>;

// Full Page
export type Page = {
	icon?: string;
	title: string;
	route: string;
};
export type Section = {
	header?: string;
	pages: Page[];
};
type SubPages =
	| {
			pages: Page[];
	  }
	| {
			sections: Section[];
	  } // eslint-disable-next-line @typescript-eslint/no-explicit-any
	| Record<any, any>;

type FullPageLayoutUiKit = 'native' | 'basic';
type FullPageLayoutCustomUi = 'native' | 'basic' | 'blank';
export type FullPageLayout = FullPageLayoutUiKit | FullPageLayoutCustomUi;

export type GenericFullPage<M extends string, P = {}> = GenericExtension<
	M,
	CommonProps &
		SubPages & {
			icon?: string;
			layout?: FullPageLayout;
		} & P
>;

export type GenericFullPageWithAccessNarrowingData<P> = {
	extensions: P[] | null;
	accessNarrowedExtensions: P[] | null;
};

export type AdminPage = GenericFullPage<
	AdminPageModule,
	{ useAsConfig?: boolean; useAsGetStarted?: boolean }
>;
export type GlobalPage = GenericFullPage<GlobalPageModule>;
export type PersonalSettingsPage = GenericFullPage<PersonalSettingsPageModule>;
export type ProjectPage = GenericFullPage<ProjectPageModule>;
export type ProjectSettingsPage = GenericFullPage<ProjectSettingsPageModule>;

export type AdminPageWithAccessNarrowing = GenericFullPageWithAccessNarrowingData<AdminPage>;
export type GlobalPageWithAccessNarrowing = GenericFullPageWithAccessNarrowingData<GlobalPage>;
export type ProjectPageWithAccessNarrowing = GenericFullPageWithAccessNarrowingData<ProjectPage>;
export type ProjectSettingsPageWithAccessNarrowing =
	GenericFullPageWithAccessNarrowingData<ProjectSettingsPage>;

// Servicedesk types
export type ServiceDeskQueuePage = GenericFullPage<ServiceDeskQueuePageModule>;
export type ServiceDeskOrganizationPanel = GenericExtension<
	ServiceDeskOrganizationPanelModule,
	CommonProps
>;
export type ServiceDeskAssetsImportType = GenericExtension<
	ServiceDeskAssetsImportTypeModule,
	CommonProps & {
		icon: string;
		description: string;
	}
>;

export type ServiceDeskQueuePageWithAccessNarrowing =
	GenericFullPageWithAccessNarrowingData<ServiceDeskQueuePage>;

export type FullPage =
	| AdminPage
	| PersonalSettingsPage
	| ProjectPage
	| ProjectSettingsPage
	| GlobalPage
	| ServiceDeskQueuePage;

export type BoardViewActions = BacklogAction | BoardAction | SprintAction;

export type ActionExtension = BoardViewActions | IssueNavigatorAction;

export type CustomAction = {
	text: string;
	key: string;
	onClick: () => Promise<void>;
};

export type IssuePanel = GenericExtension<
	IssuePanelModule,
	CommonProps & {
		icon: string;
		allowMultiple?: boolean;
		viewportSize?: ViewportSizeType;
	}
>;

export type IssueNavigatorAction = GenericExtension<
	IssueNavigatorActionModule,
	CommonActionModuleProps
>;

// Custom field
export type User = {
	accountId: string;
};
export type Group = {
	name: string;
};

export type FieldType = 'string' | 'number' | 'user' | 'group' | 'object' | 'datetime' | 'date';
export type CollectionType = 'none' | 'list' | 'set';
export type RenderType = 'default' | 'native';

type CustomFieldSchemaOld = {
	type: FieldType;
	name: string;
	description: string;
	readOnly?: boolean;
	collection?: CollectionType;
	formatter?: {
		expression: string;
	};
	validation?: {
		errorMessage: string;
		expression: string;
	};
	edit?:
		| {
				function: string;
		  }
		| {
				resource: string;
				isInline?: boolean;
				render?: RenderType;
		  };
	value?: {
		function: string;
	};
};

export type CustomFieldViewRenderContext = 'issue-view';
export type CustomFieldEditRenderContext = 'issue-view' | 'issue-create' | 'issue-transition';

export function isCustomFieldEditRenderContext(
	context: CustomFieldEditRenderContext | CustomFieldViewRenderContext,
): context is CustomFieldEditRenderContext {
	return context === 'issue-view' || context === 'issue-create' || context === 'issue-transition';
}

export function isCustomFieldViewRenderContext(
	context: CustomFieldEditRenderContext | CustomFieldViewRenderContext,
): context is CustomFieldViewRenderContext {
	return context === 'issue-view';
}
export type CustomFieldSchema = {
	type: FieldType;
	name: string;
	description: string;
	readOnly?: boolean;
	collection?: CollectionType;
	view?:
		| {
				function: string;
				value?: {
					function: string;
				};
				formatter?: {
					expression: string;
				};
		  }
		| {
				resource?: string;
				render?: RenderType;
				value?: {
					function: string;
				};
				formatter?: {
					expression: string;
				};
				experience?: Array<CustomFieldViewRenderContext>;
		  };
	edit?:
		| {
				function: string;
		  }
		| {
				validation?: {
					errorMessage: string;
					expression: string;
				};
		  }
		| {
				resource: string;
				isInline?: boolean;
				render?: RenderType;
				validation?: {
					errorMessage: string;
					expression: string;
				};
				experience?: Array<CustomFieldEditRenderContext>;
		  };
};

export type CustomFieldPropertiesOld = Omit<CommonProps, 'title'> & CustomFieldSchemaOld;
export type CustomFieldProperties = Omit<CommonProps, 'title'> & CustomFieldSchema;
export type ContextConfigPage =
	| {
			layout?: FullPageLayout;
			function: string;
	  }
	| {
			layout?: FullPageLayout;
			resource: string;
			render?: 'native';
			resolver?: {
				function: string;
			};
	  };

type CustomFieldTypePropertiesOld = Omit<CommonProps, 'title'> &
	CustomFieldSchemaOld & {
		icon?: string;
		contextConfig?: ContextConfigPage;
	};

export type CustomFieldTypeProperties = Omit<CommonProps, 'title'> &
	CustomFieldSchema & {
		icon?: string;
		contextConfig?: ContextConfigPage;
	};

type IssueAdjustmentExtensionProperties = {
	resource: string;
	resourceUploadId: string;
	title: string;
	viewportSize?: ViewportSizeType;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	displayConditions?: Record<any, any>;
	render?: 'native' | 'default';
};

export type CustomFieldLegacy = GenericExtension<CustomFieldModule, CustomFieldPropertiesOld>;
export type CustomFieldLatest = GenericExtension<CustomFieldModule, CustomFieldProperties>;
export type CustomField = CustomFieldLatest | CustomFieldLegacy;

export type CustomFieldTypeLegacy = GenericExtension<
	CustomFieldTypeModule,
	CustomFieldTypePropertiesOld
>;
export type CustomFieldTypeLatest = GenericExtension<
	CustomFieldTypeModule,
	CustomFieldTypeProperties
>;
export type CustomFieldType = CustomFieldTypeLatest | CustomFieldTypeLegacy;
export type CustomFieldExtension = CustomFieldType | CustomField;

// possibly on ditt_uim_-_use_platform_uim_core_in_jira feature gate removal
export type IssueAdjustmentExtension = GenericExtension<
	UiModificationsModule,
	IssueAdjustmentExtensionProperties
>;
export type UiModificationsExtension = UiModificationsExtensionCore;

// workflow validator

type WorkflowValidatorProperties = Omit<CommonProps, 'title'> & {
	name: string;
	description: string;
	expression?: string;
	errorMessage?: string;
	view?: {
		resource: string;
	};
	edit?: {
		resource: string;
	};
	create?: {
		resource: string;
	};
};

export type WorkflowValidator = GenericExtension<
	WorkflowValidatorModule,
	WorkflowValidatorProperties
>;

// workflow condition

type WorkflowConditionProperties = Omit<CommonProps, 'title' | 'function'> & {
	name: string;
	description: string;
	expression?: string;
	errorMessage?: string;
	view?: {
		resource: string;
	};
	edit?: {
		resource: string;
	};
	create?: {
		resource: string;
	};
};

export type WorkflowCondition = GenericExtension<
	WorkflowConditionModule,
	WorkflowConditionProperties
>;

// workflow post-function

type WorkflowPostFunctionProperties = Omit<CommonProps, 'title'> & {
	name: string;
	description: string;
	view?: {
		resource: string;
	};
	edit?: {
		resource: string;
	};
	create?: {
		resource: string;
	};
};

export type WorkflowPostFunction = GenericExtension<
	WorkflowPostFunctionModule,
	WorkflowPostFunctionProperties
>;

// Dashboard gadget
type DashboardGadgetProps = CommonProps & {
	description: string;
	thumbnail: string;
	edit?:
		| {
				function: string;
		  }
		| {
				resource: string;
		  };
};
export type DashboardGadget = GenericExtension<DashboardGadgetModule, DashboardGadgetProps>;

// Dashboard background script
export type DashboardBackgroundScript = GenericExtension<
	DashboardBackgroundScriptModule,
	{
		typeId?: string;
		resourceUploadId: string;
		resource: string;
		resolver?: {
			function: string;
		};
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		displayConditions?: Record<any, any>;
		viewportSize?: ViewportSizeType;
		render?: 'native' | 'default';
	}
>;

// Issue View background script
export type IssueViewBackgroundScript = GenericExtension<
	IssueViewBackgroundScriptModule,
	CommonProps & {
		resourceUploadId: string;
		resolver?: {
			function: string;
		};
		shouldReloadOnRefresh?: boolean;
	}
>;

export type ExtensionContextsMap = {
	jiraBacklogAction: BacklogAction;
	jiraBoardAction: BoardAction;
	jiraSprintAction: SprintAction;
	jiraIssueAction: IssueAction;
	jiraIssueGlance: IssueGlance;
	jiraIssueContext: IssueContext;
	jiraIssuePanel: IssuePanel;
	jiraIssueNavigatorAction: IssueNavigatorAction;
	jiraIssueActivity: IssueActivity;
	jiraCustomField: CustomField;
	jiraCustomFieldType: CustomFieldType;
	jiraIssueViewBackgroundScript: IssueViewBackgroundScript;
	jiraUiModifications: UiModificationsExtension;
	jiraAdminPage: AdminPage;
	jiraGlobalPage: GlobalPage;
	jiraPersonalSettingsPage: PersonalSettingsPage;
	jiraProjectPage: ProjectPage;
	jiraProjectSettingsPage: ProjectSettingsPage;
	jiraDashboardBackgroundScript: DashboardBackgroundScript;
	jiraDashboardGadget: DashboardGadget;
	jiraServiceManagementQueuePage: ServiceDeskQueuePage;
	jiraServiceManagementOrganizationPanel: ServiceDeskOrganizationPanel;
	jiraServiceManagementAssetsImportType: ServiceDeskAssetsImportType;
	jiraWorkflowValidator: WorkflowValidator;
	jiraWorkflowCondition: WorkflowCondition;
	jiraWorkflowPostFunction: WorkflowPostFunction;
	jiraCommand: Command;
};

export type ExtensionContextsMapNew = {
	'jira:backlogAction': BacklogAction;
	'jira:boardAction': BoardAction;
	'jira:sprintAction': SprintAction;
	'jira:issueAction': IssueAction;
	'jira:issueGlance': IssueGlance;
	'jira:issueContext': IssueContext;
	'jira:issuePanel': IssuePanel;
	'jira:issueNavigatorAction': IssueNavigatorAction;
	'jira:issueActivity': IssueActivity;
	'jira:customField': CustomField;
	'jira:customFieldType': CustomFieldType;
	'jira:issueViewBackgroundScript': IssueViewBackgroundScript;
	'jira:uiModifications': UiModificationsExtension;
	'jira:adminPage': AdminPage;
	'jira:globalPage': GlobalPage;
	'jira:personalSettingsPage': PersonalSettingsPage;
	'jira:projectPage': ProjectPage;
	'jira:projectSettingsPage': ProjectSettingsPage;
	'jira:dashboardBackgroundScript': DashboardBackgroundScript;
	'jira:dashboardGadget': DashboardGadget;
	'jira:workflowValidator': WorkflowValidator;
	'jira:workflowCondition': WorkflowCondition;
	'jira:workflowPostFunction': WorkflowPostFunction;
	'jiraServiceManagement:queuePage': ServiceDeskQueuePage;
	'jiraServiceManagement:organizationPanel': ServiceDeskOrganizationPanel;
	'jiraServiceManagement:assetsImportType': ServiceDeskAssetsImportType;
	'jira:command': Command;
};

export type ExtensionContextsArrayMap = {
	[Key in keyof ExtensionContextsMap]: ExtensionContextsMap[Key][];
};

export type DataClassificatedExtensionContexts = {
	extensions: ExtensionContextsArrayMap;
	accessNarrowedExtensions: ExtensionContextsArrayMap;
};

export type Extension = ExtensionContextsMap[keyof ExtensionContextsMap];

export type InitialRenderPayload =
	| {
			forgeDoc: ForgeDoc | undefined;
			appTime?: number;
			error: string | undefined;
	  }
	| undefined;

export type ExtensionNextProps<
	ModuleT extends ExtensionPointModule,
	ExtensionT extends Extension,
	ExtensionDataT,
	ExtensionPayloadT = undefined,
> = {
	module: ModuleT;
	extension: ExtensionT;
	extensionData: ExtensionDataT;
	extensionPayload?: ExtensionPayloadT;
	localId?: string;
	analyticsAttributes?: Attributes;
	entryPoint?: EntryPoint;
	loadingComponent?: ReactNode;
	basePath?: string;
	onInitialRender?: (payload: InitialRenderPayload) => void;
	onLoad?: () => void;
	bridge?: CustomUIBridge;
	contextToken?: ContextTokenType;
};

export type RequestPromiseData = Record<FullPageModule, Extension[]>;

export type ExtensionFromPointModule<T extends RequestPromiseData> = Extract<FullPage, { type: T }>;

export type ExtensionFromPointModuleWithDataClassification<T extends RequestPromiseData> = {
	extensions: ExtensionFromPointModule<T>;
	accessNarrowedExtensions: ExtensionFromPointModule<T>;
};

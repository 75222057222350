import React, { memo, useMemo } from 'react';
import { styled } from '@compiled/react';
import type { DocNode } from '@atlaskit/adf-schema';
import { defaultSchema } from '@atlaskit/adf-schema/schema-default';
import { ErrorMessage, HelperMessage } from '@atlaskit/form';
import Link from '@atlaskit/link';
import { ReactRenderer } from '@atlaskit/renderer';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useEmojiProvider } from '@atlassian/jira-emoji-provider/src/ui/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import {
	onLinkClick,
	smartLinksDefault,
} from '@atlassian/jira-linking-platform-utils/src/index.tsx';
import { convertWikiToAdf } from '@atlassian/jira-platform-convert-wiki-to-adf/src/main.tsx';
import type { Props } from './types.tsx';
import { getLearnMoreMessage, getErrorMessage } from './utils.tsx';

const FieldHelpLink = memo(({ fieldHelpTextUrl }: { fieldHelpTextUrl: string }) => {
	const learnMoreMessage = getLearnMoreMessage(fieldHelpTextUrl ?? '');
	const { formatMessage } = useIntl();
	return (
		<Link href={fieldHelpTextUrl} target="_blank">
			{formatMessage(learnMoreMessage)}
		</Link>
	);
});

const FieldMessageBehaviour = (props: Props) => {
	const {
		description = '',
		error,
		fieldName,
		fieldHelpTextUrl = '',
		isMini,
		onLinkClickEvent,
		fieldId,
	} = props;

	const { formatMessage, formatNumber } = useIntl();
	const maxHeight = 22;

	const emojiProvider = useEmojiProvider();

	const adfDocument = useMemo(() => {
		if (error) {
			return null;
		}
		return convertWikiToAdf(description);
	}, [description, error]);

	if (error !== undefined && error !== false) {
		return (
			<ErrorMessage>{getErrorMessage(error, fieldName, formatMessage, formatNumber)}</ErrorMessage>
		);
	}

	if (!adfDocument && !fieldHelpTextUrl) {
		return null;
	}

	const getRenderer = (isTruncated = false) => (
		<ReactRenderer
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			document={adfDocument as DocNode}
			schema={defaultSchema}
			dataProviders={emojiProvider}
			disableHeadingIDs
			featureFlags={{
				codeBidiWarnings: true,
				'code-bidi-warnings': true,
				// enables sending analytics event with renderer specific tti measurement
				// please do not clean up, this feature flag is meant to be rolled out permanently just for a fraction of users
				'renderer-tti-tracking': fg('renderer_tti_tracking_jira'),
				'allow-windowed-code-block': true,
			}}
			maxHeight={isTruncated ? maxHeight : undefined}
			truncated={isTruncated}
			fadeOutHeight={isTruncated ? 0 : undefined}
			eventHandlers={{
				smartCard: { onClick: onLinkClick },
				link: { onClick: onLinkClickEvent || onLinkClick },
			}}
			smartLinks={smartLinksDefault}
		/>
	);

	const getRenderedDescription = (truncatedRenderer = false) => (
		<HelperMessage>
			<Description
				data-testid="issue-create-commons.common.ui.field-message.description"
				isMini={isMini}
				{...(fieldId && {
					id: `${fieldId}-field-message`,
				})}
			>
				{adfDocument && getRenderer(truncatedRenderer)}
				{fieldHelpTextUrl && <FieldHelpLink fieldHelpTextUrl={fieldHelpTextUrl} />}
			</Description>
		</HelperMessage>
	);

	if (isMini) {
		if (adfDocument?.content[0]?.type === 'paragraph') {
			return (
				<Tooltip
					content={<TooltipContentContainer>{getRenderer()}</TooltipContentContainer>}
					position="bottom"
				>
					{getRenderedDescription(true)}
				</Tooltip>
			);
		}

		return null;
	}

	return getRenderedDescription();
};

const FieldMessage = (props: Props) => (
	<FieldMessageBehaviour key={`field-message-${props.fieldId}`} {...props} />
);

export { FieldMessage };

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TooltipContentContainer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'.ak-renderer-document': {
		color: token('color.text.inverse'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		font: token('font.heading.xxsmall'),
		marginTop: 0,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		a: {
			color: token('color.background.accent.blue.subtle'),
		},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const Description = styled.div<{ isMini?: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'.ak-renderer-document': {
		color: token('color.text.subtlest'),
		font: token('font.body.UNSAFE_small'),
		marginTop: token('space.050'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		p: (props) =>
			props.isMini && {
				whiteSpace: 'nowrap',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
			},
	},
});

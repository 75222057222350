export type BaseJiraBusinessForm = {
	formId: string;
	// Make required when cleaning up jira-forms-public-access
	uuid?: string;
	title: string;
	description: string;
	bannerColor?: string;
	// Make required when cleaning up jira-forms-public-access
	enabled?: boolean;
	// Make required when cleaning up jira-forms-public-access
	publicFormsEnabled?: boolean;
	// Make required when cleaning up jira-forms-public-access
	configurationErrors?: JiraFormConfigurationErrorTypeEnum[];
	accessLevel: FormAccessLevelTypes;
};

export const FormAccessLevelTypes = {
	LIMITED: 'limited',
	OPEN: 'open',
	PUBLIC: 'public',
} as const;

export type FormAccessLevelTypes = (typeof FormAccessLevelTypes)[keyof typeof FormAccessLevelTypes];

export type InexactBaseJiraBusinessForm = BaseJiraBusinessForm;

export type CreateFieldConfigProps = {
	mode: 'create' | 'edit';
	fieldId?: string;
} | null;

export type CardDragState =
	| {
			type: 'idle';
	  }
	| {
			type: 'dragging';
			container?: HTMLElement;
	  };

export const PUBLIC_FORM_REQUIRED_RESTRICTED_FIELD_INVALID =
	'PUBLIC_FORM_REQUIRED_RESTRICTED_FIELD_INVALID';
// suppressed to allow migration to typescript-eslint v8 - this is a new error reported with v8 and is only used as a type
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ISSUE_TYPE_INVALID = 'ISSUE_TYPE_INVALID';
export const DEFAULT_REPORTER_INVALID = 'DEFAULT_REPORTER_INVALID';

export type JiraFormConfigurationErrorTypeEnum =
	| typeof PUBLIC_FORM_REQUIRED_RESTRICTED_FIELD_INVALID
	| typeof ISSUE_TYPE_INVALID
	| typeof DEFAULT_REPORTER_INVALID;

import { blockquote as blockquoteFactory } from '../../next-schema/generated/nodeTypes';
import { blockquoteLegacy as blockquoteLegacyFactory, blockquoteWithoutNestedCodeblockOrMedia as blockquoteWithoutNestedCodeblockOrMediaFactory, blockquoteWithoutNonBodiedMacros as blockquoteWithoutNonBodiedMacrosFactory } from '../../next-schema/generated/nodeTypes';

/**
 * @name blockquote_node
 */

const nodeSpecOptions = {
  parseDOM: [{
    tag: 'blockquote'
  }],
  toDOM() {
    return ['blockquote', 0];
  }
};
export const blockquote = blockquoteLegacyFactory(nodeSpecOptions);

/**
 * @name blockquoteWithNestedCodeblockOrMedia
 * @deprecated use extentedBlockquote instead
 */
export const blockquoteWithNestedCodeblockOrMedia = blockquoteWithoutNonBodiedMacrosFactory(nodeSpecOptions);

/**
 * @name extentedBlockquote
 * @description the block quote node with nested code block, media, and extension
 */
export const extendedBlockquote = blockquoteFactory(nodeSpecOptions);

/**
 * @name blockquote_with_list_node
 * @deprecated use extentedBlockquote instead
 * TODO: remove this node in the next major version
 */
export const blockquoteWithList = blockquoteWithoutNestedCodeblockOrMediaFactory({
  parseDOM: [{
    tag: 'blockquote'
  }],
  toDOM() {
    return ['blockquote', 0];
  }
});

/**
 * @name blockquote_without_non_bodied_macros_node
 */
export const blockquoteWithoutNonBodiedMacros = blockquoteWithoutNonBodiedMacrosFactory({
  parseDOM: [{
    tag: 'blockquote'
  }],
  toDOM() {
    return ['blockquote', 0];
  }
});
import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	successSubmissionHeading: {
		id: 'business-form.common.empty-views.success-submission-heading',
		defaultMessage: "We're on it!",
		description: 'Heading message when form is successfully submitted',
	},
	successSubmissionContent: {
		id: 'business-form.common.empty-views.success-submission-content',
		defaultMessage: 'Thanks for your response.',
		description: 'Content when form is successfully submitted',
	},
});

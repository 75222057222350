import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	publicFormPrivacyFooter: {
		id: 'business-form.form-submission.fill-in-view.public-form-privacy-footer',
		defaultMessage:
			'By submitting, you agree that your use is governed by the <a>Atlassian Customer Agreement</a> and acknowledge the Atlassian <b>Privacy Policy</b>.',
		description: 'the privacy policy footer content of privacy policy footer for public form',
	},
	anchorArialLabel: {
		id: 'business-form.form-submission.fill-in-view.public-form-privacy-footer.anchor-arial-label',
		defaultMessage: 'Atlassian Submit Form Privacy Policy',
		description: 'the privacy policy anchor link aria label',
	},
});

import type { FlagConfiguration } from '@atlassian/jira-flags';
import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	title: {
		id: 'forge-ui.common.flags.custom-field.edit-failed.generic-error.title',
		defaultMessage: 'We were unable to edit this custom field. Please try again.',
		description: 'Error message shown by default, when an error occurs saving custom field data.',
	},
});

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (): FlagConfiguration => ({
	type: 'error',
	title: messages.title,
	isAutoDismiss: false,
	messageId: 'forge-ui.common.ui.flags.custom-field.edit-failed.generic-error',
	messageType: 'transactional',
});

import {
	createRouterSelector,
	useRouter,
	useRouterActions,
} from '@atlassian/react-resource-router';
import { softwareFormSubmitClassicRoute } from '@atlassian/jira-router-routes-classic-projects-routes/src/softwareFormSubmitClassicRoute.tsx';
import { classicBusinessProjectFormSubmitRoute } from '@atlassian/jira-router-routes-business-routes/src/classicBusinessProjectFormSubmitRoute.tsx';
import { softwareFormSubmitRoute } from '@atlassian/jira-router-routes-software-form-submit-routes/src/softwareFormSubmitRoute.tsx';
import { useProject_DEPRECATED_DO_NOT_USE } from '@atlassian/jira-router-resources-business-project-details/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { useFormById } from '../../services/get-form/index.tsx';
import { FormAccessLevelTypes } from '../../common/types.tsx';
import { useFormSubmission } from '../form-submission/index.tsx';
import { getFormSubmitUrl } from '../../common/utils/routing/index.tsx';

export const useRouteForm = () => {
	const [
		{
			match: {
				params: { formId },
			},
		},
	] = useRouter();

	return useFormById(formId);
};

// if form is public, current url is not uuid, and + user is logged in, redirect to uuid form submission page
export const useRedirectToPublicFormSubmission = () => {
	const { data: project } = useProject_DEPRECATED_DO_NOT_USE();
	const useRoute = createRouterSelector((state) => state.route);
	const route = useRoute();
	const { replace } = useRouterActions();
	const accountId = useAccountId();
	const { data: formSubmissionData } = useFormSubmission();

	return {
		shouldRedirectToPublicFormSubmission: () => {
			const isPublicForm =
				formSubmissionData?.formWithFieldData?.accessLevel === FormAccessLevelTypes.PUBLIC;
			const isUserLoggedIn = Boolean(accountId);
			const isFormSubmitRoute = [
				softwareFormSubmitClassicRoute.name,
				classicBusinessProjectFormSubmitRoute.name,
				softwareFormSubmitRoute.name,
			].includes(route.name);

			return isPublicForm && isUserLoggedIn && isFormSubmitRoute && fg('jira-forms-public-access');
		},
		redirectToPublicFormSubmission: () => {
			if (fg('jira-forms-public-access')) {
				const formSubmitUrl = getFormSubmitUrl(
					project?.type ?? '',
					project?.isSimplified ?? false,
					project?.key ?? '',
					formSubmissionData?.formWithFieldData?.uuid ?? '',
					true,
				);
				replace(`${formSubmitUrl}`);
			}
		},
	};
};

import { useMemo } from 'react';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';

export const isPublicFormsExperimentEnabledNoExp = () => {
	const [config] = UNSAFE_noExposureExp('jira_forms_public_access_experiment');
	return config.get('isEnabled', false);
};

export const usePublicFormsEnabledAnalyticsAttrs = (isBuilder = false) =>
	useMemo(
		() => ({
			isPublicFormsEnabled: isBuilder
				? isPublicFormsExperimentEnabledNoExp()
				: fg('jira-forms-public-access'),
			isPublicFormsExperimentEnabled: isPublicFormsExperimentEnabledNoExp(),
			isPublicFormsGateEnabled: fg('jira-forms-public-access'),
		}),
		[isBuilder],
	);
